import React, { useEffect } from 'react'
import { useElements } from '@stripe/react-stripe-js'
import { hideAndShowCopySuccess } from '../card-details.hook'

const CardDetailsCVC = ({
  issuingCard,
  ephemeralKeySecret,
  nonce,
}: {
  issuingCard: string
  ephemeralKeySecret: string
  nonce?: string
}) => {
  const elements = useElements()

  useEffect(() => {
    if (elements) {
      elements
        .create('issuingCardCvcDisplay', {
          issuingCard,
          ephemeralKeySecret,
          nonce,
          style: {
            base: {
              color: '#007AFF',
              fontWeight: '300',
            },
          },
        })
        .mount('#issuing-card-cvc-container')

      const cardCVCCopyElement = elements.create('issuingCardCopyButton', {
        toCopy: 'cvc',
      })
      cardCVCCopyElement.mount('#card-cvc-copy')

      cardCVCCopyElement.on('click', () =>
        hideAndShowCopySuccess('card-cvc-copy', 'card-cvc-copy-success'),
      )
    }
  }, [elements, issuingCard, ephemeralKeySecret, nonce])

  return (
    <div className="card-details-row">
      <div className="card-details-row-text">
        <strong>CVV</strong>
        <div id="issuing-card-cvc-container"></div>
      </div>
      <div id="card-cvc-copy"></div>
      <div id="card-cvc-copy-success"></div>
    </div>
  )
}

export default CardDetailsCVC
