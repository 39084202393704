import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Table,
  Container,
} from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { listAppBuilds } from '../../helpers/lopay_api_helper'
import ReactTimeAgo from 'react-time-ago'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import AppBuildListResponse from '../../api-dtos/support/app-builds/app-builds-api.dto.interface'

try {
  TimeAgo.addDefaultLocale(en)
} catch (e) {
  console.error(e)
}
interface PageProps {
  history: any
}

const AppBuildsPage = ({ history }: PageProps) => {
  const [appBuilds, setAppBuilds] = useState<AppBuildListResponse>({
    builds: [],
    count: 0,
    pageSize: 0,
  })

  useEffect(() => {
    loadAppBuilds()
    // eslint-disable-next-line
  }, [])

  const loadAppBuilds = async (offsetId?: string) => {
    const builds = await listAppBuilds(offsetId, 20)
    setAppBuilds(builds)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>App Builds</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={'LoPay Support'} breadcrumbItem="App Builds" />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader></CardHeader>
                <CardBody>
                  <div
                    className="table-responsive"
                    style={{
                      paddingBottom: 550,
                      marginBottom: -550,
                      msOverflowStyle: 'none',
                    }}
                  >
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Created</th>
                          <th>Platform</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {appBuilds.builds.map((appBuild) => {
                          return (
                            <tr key={appBuild.id}>
                              <th
                                onClick={() => {
                                  // setSelectedMerchant(merchant)
                                }}
                              >
                                <ReactTimeAgo
                                  date={new Date(appBuild.createdAt)}
                                  locale="en-US"
                                />
                              </th>
                              <td>{appBuild.platform}</td>
                              <td>{appBuild.status}</td>
                              <td>{appBuild.gitHubPullRequest}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default AppBuildsPage
