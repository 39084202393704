/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import MetaTags from 'react-meta-tags'
import {
  Row,
  Col,
  Alert,
  Container,
  CardBody,
  Card,
  CardHeader,
  CardTitle,
  Table,
  Modal,
} from 'reactstrap'

import { ReactComponent as XeroConnectButtonImage } from 'src/assets/images/xero/connect-white.svg'
import { ReactComponent as XeroDisconnectButtonImage } from 'src/assets/images/xero/disconnect-white.svg'

// availity-reactstrap-validation
import { AvForm } from 'availity-reactstrap-validation'

// action
import { apiError } from '../../store/actions'

//redux
import { useDispatch } from 'react-redux'

// import images
import logo from '../../assets/images/xero/logo-blue.png'
import {
  disconnectXero,
  getXeroStatus,
  getXeroAccounts,
  updatedXeroFeeAccount,
  updatedXeroClearingAccount,
  updatedXeroBrandingTheme,
  getXeroApiLogs,
  acceptXeroTerms,
} from '../../helpers/lopay_api_helper'
import { XeroApiLogDto } from 'src/api-dtos/xero/xero-api-log.dto'
import moment from 'moment'
import { Tbody, Th, Thead, Tr } from 'react-super-responsive-table'

interface PageProps {
  google: Object
  history: any
  match: {
    params: {
      merchantId: string
      apiKey: string
    }
  }
}

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`

const XeroMerchant = ({ history, match }: PageProps) => {
  const dispatch = useDispatch()

  const [error] = useState<string | null>()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingAccounts, setLoadingAccounts] = useState<boolean>(false)
  const [loadingApiLogs, setLoadingApiLogs] = useState<boolean>(false)
  const [connectionStatus, setConnectionStatus] = useState<{
    showFees: boolean
    isConnected: boolean
    tenantName?: string
  }>({ showFees: false, isConnected: false })

  const [apiLogs, setApiLogs] = useState<XeroApiLogDto[]>([])
  const [accounts, setAccounts] = useState<{
    accounts: {
      code: string
      description: string
      isLinked: boolean
    }[]
    feeAccounts: {
      code: string
      description: string
      isLinked: boolean
    }[]
    brandingThemes: {
      code: string
      description: string
      isLinked: boolean
    }[]
  }>({ accounts: [], feeAccounts: [], brandingThemes: [] })

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [agreedTermsAndConditions, setAgreedTermsAndConditions] =
    useState<boolean>(false)

  const [selectedClearingAccount, setSelectedClearingAccount] = useState<{
    code: string
    description: string
    isLinked: boolean
  }>()

  const [selectedFeeAccount, setSelectedFeeAccount] = useState<{
    code: string
    description: string
    isLinked: boolean
  }>()

  const [selectedBrandingTheme, setSelectedBrandingTheme] = useState<{
    code: string
    description: string
    isLinked: boolean
  }>()

  const [selectedApiLog, setSelectedApiLog] = useState<
    XeroApiLogDto | undefined
  >(undefined)

  console.log('MerchantId: ', match.params.merchantId)
  console.log('APIKey: ', match.params.apiKey)

  if (match.params.merchantId) {
    localStorage.setItem('merchantId', match.params.merchantId)
  }

  if (match.params.apiKey) {
    localStorage.setItem('APIKey', match.params.apiKey)
  }

  const handleValidSubmit = async () => {
    if (connectionStatus.isConnected) {
      setLoading(true)
      await disconnectXero(match.params.merchantId, match.params.apiKey)
      await getXeroStatusFromApi()
      setLoading(false)

      return
    }

    setLoading(true)
    await acceptXeroTerms(match.params.merchantId, match.params.apiKey)

    const API_URL = process.env.REACT_APP_API_HOST
    const consentUrl = `${API_URL}merchant/${match.params.merchantId}/xero/auth`
    window.location.href = consentUrl
  }

  const getXeroApiLogsFromApi = async () => {
    if (!match.params.merchantId || !match.params.apiKey) {
      return
    }

    setLoadingApiLogs(true)

    const response = await getXeroApiLogs(
      match.params.merchantId,
      match.params.apiKey,
    )

    setLoadingApiLogs(false)
    setApiLogs(response.data)
  }

  const getXeroAccountsFromApi = async () => {
    if (!match.params.merchantId || !match.params.apiKey) {
      return
    }

    setLoadingAccounts(true)

    const xeroAccounts = await getXeroAccounts(
      match.params.merchantId,
      match.params.apiKey,
    )

    setAccounts(xeroAccounts)

    const currentSelectedClearingAccount = xeroAccounts.accounts.find(
      (acct) => acct.isLinked,
    )
    setSelectedClearingAccount(currentSelectedClearingAccount)

    const currentSelectedFeeAccount = xeroAccounts.feeAccounts.find(
      (acct) => acct.isLinked,
    )
    setSelectedFeeAccount(currentSelectedFeeAccount)

    const currentSelectedBrandingTheme = xeroAccounts.brandingThemes.find(
      (acct) => acct.isLinked,
    )
    setSelectedBrandingTheme(currentSelectedBrandingTheme)

    setLoadingAccounts(false)
  }

  const updateSelectedClearingAccount = async (code: string) => {
    console.log('code:', code)

    const acct = accounts.accounts.find((a) => a.code === code)

    if (!acct) {
      return
    }

    setLoadingAccounts(true)

    try {
      await updatedXeroClearingAccount(
        match.params.merchantId,
        match.params.apiKey,
        acct.code,
      )
    } catch (e) {
      console.error(e)
    }

    setLoadingAccounts(false)
    setSelectedClearingAccount(acct)
  }

  const updateSelectedFeeAccount = async (code: string) => {
    console.log('code:', code)

    const acct = accounts.feeAccounts.find((a) => a.code === code)

    if (!acct) {
      return
    }

    setLoadingAccounts(true)

    try {
      await updatedXeroFeeAccount(
        match.params.merchantId,
        match.params.apiKey,
        acct.code,
      )
    } catch (e) {
      console.error(e)
    }

    setLoadingAccounts(false)

    setSelectedFeeAccount(acct)
  }

  const updateSelectedBrandingTheme = async (code: string) => {
    console.log('code:', code)

    const acct = accounts.brandingThemes.find((a) => a.code === code)

    if (!acct) {
      return
    }

    setLoadingAccounts(true)

    try {
      await updatedXeroBrandingTheme(
        match.params.merchantId,
        match.params.apiKey,
        acct.code,
      )
    } catch (e) {
      console.error(e)
    }

    setLoadingAccounts(false)

    setSelectedBrandingTheme(acct)
  }

  const getXeroStatusFromApi = async () => {
    if (!match.params.merchantId || !match.params.apiKey) {
      return
    }

    setLoading(true)
    const status = await getXeroStatus(
      match.params.merchantId,
      match.params.apiKey,
    )
    setConnectionStatus(status)
    setLoading(false)

    if (status.isConnected) {
      getXeroAccountsFromApi()
      getXeroApiLogsFromApi()
    }
  }

  useEffect(() => {
    dispatch(apiError(''))
    getXeroStatusFromApi()
  }, [dispatch])

  return (
    <React.Fragment>
      <MetaTags>
        <title>
          {connectionStatus.isConnected
            ? 'Connected - Xero // Lopay'
            : 'Connect Xero | Lopay'}
        </title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={6} md={6} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <img src={logo} alt="" height="100" />{' '}
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">
                          {connectionStatus.isConnected
                            ? 'Xero // Lopay'
                            : 'Connect Xero with Lopay'}
                        </h5>
                        <p className="text-muted mt-2">
                          {connectionStatus.isConnected
                            ? `Connected to ${connectionStatus.tenantName}`
                            : 'Connect your Xero account'}
                        </p>
                      </div>
                      {!loading && !connectionStatus.isConnected && (
                        <div className="text-muted mt-4">
                          <div>
                            <p>
                              We will access the following Xero data in order to
                              enable support for taking Lopay payments via your
                              Xero invoices:
                            </p>
                            <ul>
                              <li>Xero invoices</li>
                              <li>Xero invoice brand themes</li>
                              <li>Xero payment services</li>
                              <li>Xero contacts</li>
                            </ul>
                            <p>
                              We'll continue to access your data, unless you
                              revoke our access through your Xero account.
                            </p>
                            <p>
                              We'll also share records of the payments with Xero
                              to keep your data in Xero up to date. The data we
                              share with Xero will be stored in your Xero
                              organisation and subject to Xero's{' '}
                              <a href="https://www.xero.com/uk/legal/terms/">
                                terms of use
                              </a>
                              .
                            </p>
                            {connectionStatus.showFees && (
                              <p>
                                When a customer pays for an invoice via Lopay,
                                there will be an{' '}
                                <b>additional 10p + 0.29% charge</b>.
                              </p>
                            )}
                            <div className="form-check mb-3">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={agreedTermsAndConditions}
                                onChange={() =>
                                  setAgreedTermsAndConditions((prev) => !prev)
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="formCheck1"
                              >
                                I agree to Lopay's{' '}
                                <a href="https://lopay.com/terms-of-use">
                                  terms of use
                                </a>
                                .
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                      {connectionStatus.isConnected && (
                        <CenterContainer>
                          <a
                            // disabled={loading}
                            // className="btn btn-primary w-100 waves-effect waves-light"
                            // type="button"
                            href="#"
                            onClick={() => {
                              handleValidSubmit()
                            }}
                          >
                            {loading ? (
                              `Loading...`
                            ) : (
                              <XeroDisconnectButtonImage />
                            )}
                          </a>
                        </CenterContainer>
                      )}
                      <AvForm className="needs-validation custom-form mt-4 pt-2">
                        {error && error ? (
                          <Alert color="danger">{error}</Alert>
                        ) : null}
                        {error ? <Alert color="danger">{error}</Alert> : null}

                        {connectionStatus.isConnected && (
                          <>
                            <Card>
                              <CardHeader>
                                <CardTitle>Lopay clearings account</CardTitle>
                                <p className="card-title-desc">
                                  Select an account in Xero where you collect
                                  Lopay invoice payments. In order to record
                                  Lopay fees correctly, please ensure the
                                  account you select here is a Bank account.
                                </p>
                              </CardHeader>
                              <CardBody>
                                {loadingAccounts ? (
                                  <>Loading...</>
                                ) : (
                                  <div>
                                    <select
                                      className="form-select"
                                      onChange={(e) =>
                                        accounts &&
                                        updateSelectedClearingAccount(
                                          e.target.value,
                                        )
                                      }
                                      value={selectedClearingAccount?.code}
                                    >
                                      {accounts &&
                                        accounts.accounts.map((account, i) => (
                                          <option
                                            key={account.code}
                                            value={account.code}
                                          >
                                            [{account.code}]{' '}
                                            {account.description}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                )}
                              </CardBody>
                            </Card>
                            <Card className="mt-4">
                              <CardHeader>
                                <CardTitle>Lopay fees</CardTitle>
                                <p className="card-title-desc">
                                  Select an account in Xero where you collect
                                  Lopay fees.
                                </p>
                              </CardHeader>
                              <CardBody>
                                {loadingAccounts ? (
                                  <>Loading...</>
                                ) : (
                                  <div>
                                    <select
                                      className="form-select"
                                      onChange={(e) =>
                                        accounts &&
                                        updateSelectedFeeAccount(e.target.value)
                                      }
                                      value={selectedFeeAccount?.code}
                                    >
                                      {accounts &&
                                        accounts.feeAccounts.map(
                                          (account, i) => (
                                            <option
                                              key={account.code}
                                              value={account.code}
                                            >
                                              [{account.code}]{' '}
                                              {account.description}
                                            </option>
                                          ),
                                        )}
                                    </select>
                                  </div>
                                )}
                              </CardBody>
                            </Card>
                            <Card className="mt-4">
                              <CardHeader>
                                <CardTitle>
                                  Lopay payment service branding
                                </CardTitle>
                                <p className="card-title-desc">
                                  Add the invoice branding theme you would like
                                  to use for the Lopay payment service.
                                </p>
                              </CardHeader>
                              <CardBody>
                                {loadingAccounts ? (
                                  <>Loading...</>
                                ) : (
                                  <div>
                                    <select
                                      className="form-select"
                                      onChange={(e) =>
                                        accounts &&
                                        updateSelectedBrandingTheme(
                                          e.target.value,
                                        )
                                      }
                                      value={selectedBrandingTheme?.code}
                                    >
                                      {accounts &&
                                        accounts.brandingThemes.map(
                                          (account, i) => (
                                            <option
                                              key={account.code}
                                              value={account.code}
                                            >
                                              {account.description}
                                            </option>
                                          ),
                                        )}
                                    </select>
                                  </div>
                                )}
                              </CardBody>
                            </Card>
                            <Card className="mt-4">
                              <CardHeader>
                                <CardTitle>Xero API logs</CardTitle>
                                <p className="card-title-desc">
                                  See how Lopay is interacting with your Xero
                                  account via the Xero API.
                                </p>
                              </CardHeader>
                              <CardBody>
                                {loadingApiLogs ? (
                                  <>Loading...</>
                                ) : apiLogs.length === 0 ? (
                                  <>
                                    There are currently no Xero API logs to
                                    show.
                                  </>
                                ) : (
                                  <div className="table-rep-plugin">
                                    <div
                                      className="table-responsive"
                                      data-pattern="priority-columns"
                                    >
                                      <Table className="table table-striped table-bordered">
                                        <Thead>
                                          <Tr>
                                            <Th data-priority="1">Date</Th>
                                            <Th data-priority="2">Status</Th>
                                            <Th data-priority="2">Action</Th>
                                            <Th data-priority="3"></Th>
                                          </Tr>
                                        </Thead>
                                        <Tbody>
                                          {apiLogs.map((apiLog) => (
                                            <Tr key={apiLog.id}>
                                              <Th>
                                                {moment(apiLog.date)
                                                  .local()
                                                  .format('DD/MM/YY HH:mm')}
                                              </Th>
                                              <Th>{apiLog.status}</Th>
                                              <Th>
                                                {apiLog.userFriendlyApiCall}
                                              </Th>
                                              <Th>
                                                <button
                                                  type="button"
                                                  className="btn btn-soft-primary waves-effect waves-light"
                                                  onClick={() => {
                                                    setIsModalOpen(true)
                                                    setSelectedApiLog(apiLog)
                                                  }}
                                                >
                                                  <i className="bx bx-question-mark font-size-16 align-middle"></i>
                                                </button>
                                              </Th>
                                            </Tr>
                                          ))}
                                        </Tbody>
                                      </Table>
                                    </div>
                                  </div>
                                )}
                              </CardBody>
                            </Card>
                          </>
                        )}
                        {!connectionStatus.isConnected && (
                          <CenterContainer>
                            <a
                              // disabled={loading}
                              // className="btn btn-primary w-100 waves-effect waves-light"
                              // type="button"
                              href="#"
                              onClick={() => {
                                if (agreedTermsAndConditions) {
                                  handleValidSubmit()
                                }
                              }}
                            >
                              {loading ? (
                                `Loading...`
                              ) : (
                                <XeroConnectButtonImage />
                              )}
                            </a>
                          </CenterContainer>
                        )}
                      </AvForm>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Lopay . Crafted with{' '}
                        <i className="mdi mdi-heart text-danger"></i> by Lopay
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Modal
            size="md"
            isOpen={isModalOpen}
            toggle={() => {
              setSelectedApiLog(undefined)
              setIsModalOpen(false)
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">Xero API Call Details</h5>
              <button
                onClick={() => {
                  setSelectedApiLog(undefined)
                  setIsModalOpen(false)
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <strong>API Call</strong>
                <div className="text-muted">
                  {`${selectedApiLog?.apiCall.method.toUpperCase()} ${selectedApiLog?.apiCall?.url}`}
                </div>
              </div>
              <div className="mt-3">
                <strong>Status Code</strong>
                <div className="text-muted">
                  {selectedApiLog?.apiCall?.statusCode}
                </div>
              </div>
              {selectedApiLog?.apiCall.requestBody &&
                selectedApiLog?.apiCall.requestBody.startsWith('{') && (
                  <div className="mt-3">
                    <strong>Request body</strong>
                    <div className="text-muted overflow-auto">
                      <pre>
                        <code>
                          {JSON.stringify(
                            JSON.parse(selectedApiLog.apiCall?.requestBody),
                            null,
                            2,
                          )}
                        </code>
                      </pre>
                    </div>
                  </div>
                )}
              {selectedApiLog?.apiCall.responseBody &&
                selectedApiLog?.apiCall.responseBody.startsWith('{') && (
                  <div className="mt-3">
                    <strong>Response body</strong>
                    <div className="text-muted overflow-auto">
                      <pre>
                        <code>
                          {JSON.stringify(
                            JSON.parse(selectedApiLog.apiCall?.responseBody),
                            null,
                            2,
                          )}
                        </code>
                      </pre>
                    </div>
                  </div>
                )}
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default XeroMerchant
