/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import MetaTags from 'react-meta-tags'
import { Row, Col, Alert, Container } from 'reactstrap'

import { ReactComponent as XeroSignInButtonImage } from 'src/assets/images/xero/signin-xero-blue.svg'

// availity-reactstrap-validation
import { AvForm } from 'availity-reactstrap-validation'

// action
import { apiError } from '../../store/actions'

//redux
import { useDispatch } from 'react-redux'

// import images
import logo from '../../assets/images/xero/logo-blue.png'

interface PageProps {
  google: Object
  history: any
  match: {
    params: {
      merchantId: string
      apiKey: string
    }
  }
}

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`

const XeroSignup = ({ history, match }: PageProps) => {
  const dispatch = useDispatch()

  const [error] = useState<string | null>()

  const handleValidSubmit = async () => {
    const API_URL = process.env.REACT_APP_API_HOST
    const consentUrl = `${API_URL}merchant/${match.params.merchantId}/xero/auth-signup`
    window.location.href = consentUrl
  }

  useEffect(() => {
    dispatch(apiError(''))
  }, [dispatch])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Sign up to Lopay</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={6} md={6} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <img src={logo} alt="" height="100" />{' '}
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Sign Up to Lopay with Xero</h5>
                        <p className="text-muted mt-2">
                          Connect your Xero account to sign up to Lopay
                        </p>
                      </div>
                      <AvForm className="needs-validation custom-form mt-4 pt-2">
                        {error && error ? (
                          <Alert color="danger">{error}</Alert>
                        ) : null}
                        {error ? <Alert color="danger">{error}</Alert> : null}

                        <CenterContainer>
                          <a
                            href="#"
                            onClick={() => {
                              handleValidSubmit()
                            }}
                          >
                            <XeroSignInButtonImage />
                          </a>
                        </CenterContainer>
                      </AvForm>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Lopay. Crafted with{' '}
                        <i className="mdi mdi-heart text-danger"></i> by Lopay
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default XeroSignup
