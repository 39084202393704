/**
 *  interface for Login Type
 */
export enum ProfileTypes {
  FETCH_PROFILE_SUPPORT = '@@profile/FETCH_PROFILE_SUPPORT',
  FETCH_PROFILE_SUPPORT_SUCCESS = '@@profile/FETCH_PROFILE_SUPPORT_SUCCESS',

  UPDATE_EDIT_PROFILE_SUPPORT = '@@profile/UPDATE_EDIT_PROFILE_SUPPORT',
  UPDATE_PROFILE_SUPPORT_SUCCESS = '@@profile/UPDATE_PROFILE_SUPPORT_SUCCESS',
  UPDATE_PROFILE_SUPPORT_ERROR = '@@profile/UPDATE_PROFILE_SUPPORT_ERROR',
  UPDATE_RESET_PROFILE_SUPPORT_FLAG = '@@profile/UPDATE_RESET_PROFILE_SUPPORT_FLAG',
}
