import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'

import CountUp from 'react-countup'

//import Breadcrumbs
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { Card, CardBody, Col, Container, Row } from 'reactstrap'

//import MarketOverview from "./MarketOverview";
import Transactions from './Transactions'
import { getBalance, getUserProfile } from '../../helpers/lopay_api_helper'
import UserProfileResponse from '../../api-dtos/profile/user-profile-response.dto.interface'
import MerchantBalanceResponseBody from '../../api-dtos/balance/balance.dto'

const Dashboard = () => {
  const [userProfile] = useState<UserProfileResponse | null>(getUserProfile())

  const [balance, setBalance] = useState<
    MerchantBalanceResponseBody | undefined
  >(undefined)

  useEffect(() => {
    userProfile?.user.merchants[0]?.id &&
      fetchBalance(userProfile?.user.merchants[0]?.id)
    // eslint-disable-next-line
  }, [userProfile])

  const fetchBalance = async (merchantId: string) => {
    setBalance(await getBalance(merchantId))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Lopay</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Home" breadcrumbItem="Welcome to Lopay" />

          <Row>
            <Card className="card-h-100">
              <CardBody>
                <Row className="align-items-center">
                  <Col xs={12} className={'mt-2'}>
                    <span className="text-muted mb-3 lh-1 d-block text-truncate">
                      {'Your Balance'}
                    </span>
                    <h4 className="mb-3">
                      {balance ? '£' : ''}
                      <span className="counter-value">
                        {balance ? (
                          <CountUp
                            start={0}
                            end={balance.available.units / 100}
                            duration={0.5}
                          />
                        ) : (
                          <CountUp start={0} end={0.0} duration={0.5} />
                        )}
                        {''}
                      </span>
                    </h4>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
          {1 + 1 === 3 && (
            <Row>
              <Transactions />
            </Row>
          )}

          <Row></Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
