import React from 'react'

import { Switch, BrowserRouter as Router } from 'react-router-dom'

// Import Routes all
import { userRoutes, authRoutes } from './routes/allRoutes'

import { GoogleOAuthProvider } from '@react-oauth/google'

//redux
import { useSelector } from 'react-redux'

// Import all middleware
import Authmiddleware from './routes/middleware/Authmiddleware'

// layouts Format
import VerticalLayout from './components/VerticalLayout/'
import HorizontalLayout from './components/HorizontalLayout/index'
import NonAuthLayout from './components/NonAuthLayout'

// Import scss
import './assets/scss/theme.scss'
import './assets/scss/preloader.scss'

const App = () => {
  const { layoutType } = useSelector((state: any) => ({
    layoutType: state.Layout.layoutType,
  }))

  function getLayout() {
    let layoutCls: Object = VerticalLayout
    switch (layoutType) {
      case 'horizontal':
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()
  return (
    <React.Fragment>
      <GoogleOAuthProvider clientId="83340720452-j32titfq911hjhi91sl3t1v60a8cr192.apps.googleusercontent.com">
        <Router>
          <Switch>
            {authRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}

            {userRoutes.map((route: any, idx: number) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
          </Switch>
        </Router>
      </GoogleOAuthProvider>
    </React.Fragment>
  )
}

export default App
