import { supportUpdateMerchantPaymentConfigDetails } from '../../../../../helpers/lopay_api_helper'
import React, { useState } from 'react'
import { Spinner } from 'reactstrap'

type KYCStatusSelectProps = {
  disabledCharges?: boolean | null
  merchantId: string
  setMerchantKYCStatus: (status?: boolean) => void
}

/**
 * Component handling the selection input for a merchant's KYC status, sending
 * a request to the Lopay APIs upon selection.
 *
 * @param disabledCharges - Whether the merchant is able to take payments or not.
 * @param merchantId - The ID of the merchant.
 * @param setMerchantKYCStatus - A function that handles changing the merchant's
 * status on the UI.
 * @constructor
 */
const KYCStatusSelect = ({
  disabledCharges,
  merchantId,
  setMerchantKYCStatus,
}: KYCStatusSelectProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const kycChoices = [
    {
      value: 'not_reviewed',
      label: 'KYB not reviewed',
    },
    {
      value: 'approved',
      label: 'Approved',
    },
    {
      value: 'rejected',
      label: 'Rejected',
    },
  ]

  const kycValue = () => {
    switch (disabledCharges) {
      case true:
        return 'rejected'
      case false:
        return 'approved'
      default:
        return 'not_reviewed'
    }
  }

  const value = kycValue()

  return (
    <div style={{ position: 'relative' }}>
      {isLoading && (
        <Spinner
          style={{ position: 'absolute', top: '30%', left: 3, marginLeft: 8 }}
          color="primary"
          size="sm"
        />
      )}
      <select
        className="form-select"
        style={{ ...(isLoading && { paddingLeft: 34 }) }}
        value={value}
        disabled={isLoading}
        onChange={(e) => {
          console.log('e.target:', e.target.value)

          const kybDisabledCharges =
            e.target.value === 'not_reviewed'
              ? undefined
              : e.target.value === 'rejected'

          setIsLoading(true)

          supportUpdateMerchantPaymentConfigDetails(merchantId, {
            kybDisabledCharges: kybDisabledCharges ?? null,
          })
            .then(() => {
              setMerchantKYCStatus(kybDisabledCharges)
            })
            .finally(() => {
              setIsLoading(false)
            })
        }}
      >
        {kycChoices.map((option) => (
          <option value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  )
}

export default KYCStatusSelect
