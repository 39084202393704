import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Table,
  Badge,
  CardHeader,
} from 'reactstrap'
import {
  supportCardGetIsGated,
  supportGetCardAuthorizations,
  supportGetCards,
} from '../../../helpers/lopay_api_helper'
import MerchantApiDto from '../../../api-dtos/merchant/merchant.dto.interface'
import CardAuthorizationDto from './interfaces/card-authorization.dto.interface'
import moment from 'moment'
import { priceFormat } from '../../../helpers/utils'
import { supportCardUpdateIsGated } from '../../../helpers/lopay_api_helper'
import CardIssuedDto from './interfaces/card-issued.dto.interface'

const LopayCardSupportComponent = (props: { merchant: MerchantApiDto }) => {
  // Status of card issuing
  const [termsAcceptedAt, setTermsAcceptedAt] = useState<Date | undefined>(
    undefined,
  )
  const [isGatedIntoCard, setIsGatedIntoCard] = useState<boolean>(false)
  const [IsLoading, setIsLoading] = useState<boolean>(false)

  // Authorizations
  const [isLoadingAuthorizations, setIsLoadingAuthorizations] =
    useState<boolean>(false)
  const [authorizations, setAuthorizations] = useState<{
    authorizations: CardAuthorizationDto[]
  }>({
    authorizations: [],
  })

  // Cards
  const [isLoadingCards, setIsLoadingCards] = useState<boolean>(false)
  const [cards, setCards] = useState<{
    cards: CardIssuedDto[]
    firstActivationDate?: Date
  }>({
    cards: [],
  })

  const loadCards = async () => {
    setIsLoadingCards(true)
    setCards(await supportGetCards(props.merchant.id))
    setIsLoadingCards(false)
  }

  const loadAuthorizations = async () => {
    setIsLoadingAuthorizations(true)
    setAuthorizations(await supportGetCardAuthorizations(props.merchant.id))
    setIsLoadingAuthorizations(false)
  }

  useEffect(() => {
    if (props.merchant) {
      loadIsGatedIntoCard(props.merchant.id)
      loadAuthorizations()
      loadCards()
    }
    // eslint-disable-next-line
  }, [props.merchant])

  const loadIsGatedIntoCard = async (merchantId: string) => {
    setIsLoading(true)
    const result = await supportCardGetIsGated(merchantId)
    setIsGatedIntoCard(result.gated)
    setTermsAcceptedAt(result.agreedTerms)
    setIsLoading(false)
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Lopay card</title>
      </MetaTags>
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <div className="form-check mt-2">
                  <input
                    onChange={async () => {
                      setIsLoading(true)
                      await supportCardUpdateIsGated(
                        props.merchant.id,
                        !isGatedIntoCard,
                      )
                      setIsGatedIntoCard((v) => !v)
                      setIsLoading(false)
                    }}
                    checked={isGatedIntoCard}
                    className="form-check-input"
                    type="checkbox"
                    id="checkbox-is-under-review"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="checkbox-is-under-review"
                  >
                    {IsLoading && <b>[Updating...] </b>}
                    Gated into Lopay Card (Visible in app)
                  </label>
                </div>

                <div className="form-check mt-2">
                  <input
                    disabled
                    checked={Boolean(termsAcceptedAt)}
                    className="form-check-input"
                    type="checkbox"
                    id="checkbox-accepted-card-terms"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="checkbox-accepted-card-terms"
                  >
                    {IsLoading && <b>[Updating...] </b>}
                    User has {termsAcceptedAt ? '' : 'not yet '} accepted Card
                    Terms & Conditions{' '}
                    {termsAcceptedAt && (
                      <>
                        (Accepted on{' '}
                        <span className="time">
                          {moment(new Date(termsAcceptedAt)).format(
                            'MMM Do h:mm:ss a',
                          )}
                        </span>{' '}
                        )
                      </>
                    )}
                  </label>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Card>
            <CardHeader>Cards</CardHeader>

            <CardBody>
              <div className="table-responsive">
                <Table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>Date Created/Ordered</th>
                      <th>Status</th>
                      <th>Type</th>
                      <th>Expiry</th>
                      <th>Last4</th>
                      <th>Shipping Address</th>
                      <th>Shipping Tracking</th>
                    </tr>
                  </thead>
                  {isLoadingCards || !cards?.cards ? (
                    <tbody>
                      <tr>
                        <th>Loading...</th>
                      </tr>
                    </tbody>
                  ) : cards.cards.length === 0 ? (
                    <tbody>
                      <tr>
                        <th>No cards have been ordered yet</th>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {cards &&
                        cards.cards.map((card) => {
                          return (
                            <tr key={card.id}>
                              <td>
                                <span className="time">
                                  {moment(new Date(card.createdAt)).format(
                                    'MMM Do h:mm:ss a',
                                  )}
                                </span>
                              </td>
                              <th>{card.status}</th>
                              <td>{card.type}</td>
                              <td>
                                {card.expMonth}/{card.expYear}
                              </td>
                              <td>{card.last4}</td>
                              <td>
                                {card.shipping.address?.addressLine1} <br />
                                {card.shipping.address?.addressLine2} <br />
                                {card.shipping.address?.city} <br />
                                {card.shipping.address?.state} <br />
                                {card.shipping.address?.postalCode} <br />
                              </td>
                              <td>
                                {card.shipping.shippingCarrier && (
                                  <>Carrier: {card.shipping.shippingCarrier}</>
                                )}{' '}
                                <br />
                                {card.shipping.shippingService && (
                                  <>Service: {card.shipping.shippingService}</>
                                )}{' '}
                                <br />
                                {card.shipping.shippingStatus && (
                                  <>Status: {card.shipping.shippingStatus}</>
                                )}{' '}
                                <br />
                                {card.shipping.trackingNumber && (
                                  <>
                                    Tracking #: {card.shipping.trackingNumber}
                                  </>
                                )}{' '}
                                <br />
                                {card.shipping.trackingUrl && (
                                  <>Tracking URL: {card.shipping.trackingUrl}</>
                                )}{' '}
                                <br />
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  )}
                </Table>
              </div>
            </CardBody>
          </Card>
        </Row>

        <Row>
          <Card>
            <CardHeader>Recent Card Authorizations</CardHeader>

            <CardBody>
              <div className="table-responsive">
                <Table className="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Fee</th>
                      <th>Reward</th>
                      <th>Status</th>
                      <th>Merchant</th>
                      <th>Expenses</th>
                      <th>External links</th>
                    </tr>
                  </thead>
                  {isLoadingAuthorizations &&
                  !authorizations?.authorizations ? (
                    <tbody>
                      <tr>
                        <th>Loading...</th>
                      </tr>
                    </tbody>
                  ) : authorizations.authorizations.length === 0 ? (
                    <tbody>
                      <tr>
                        <th>No authorizations have been made yet</th>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody>
                      {authorizations &&
                        authorizations.authorizations.map((authorization) => {
                          return (
                            <tr key={authorization.id}>
                              <td>
                                <span className="time">
                                  {moment(
                                    new Date(authorization.createdAt),
                                  ).format('MMM Do h:mm:ss a')}
                                </span>
                              </td>
                              <th>{priceFormat(authorization.amount)}</th>
                              <td>{priceFormat(authorization.fee)}</td>
                              <td>{priceFormat(authorization.reward)}</td>
                              <td>{authorization.status}</td>
                              <td>{authorization.merchant.name}</td>
                              <td>
                                {authorization.tracking?.category?.name && (
                                  <Badge className="me-2 bg-primary">
                                    {authorization.tracking?.category?.name}
                                  </Badge>
                                )}
                                {authorization.tracking?.attachments.map(
                                  (attachment) => (
                                    <button
                                      className="btn btn-primary"
                                      type="button"
                                      onClick={() => {
                                        window.open(
                                          attachment.url,
                                          '_blank',
                                          'noreferrer',
                                        )
                                      }}
                                    >
                                      <i className="bx bx-file align-middle"></i>
                                      {attachment.originalname} (
                                      {attachment.size / 1024 / 1024}
                                      MB)
                                    </button>
                                  ),
                                )}
                              </td>
                              <td>
                                {authorization.providerId && (
                                  <button
                                    type="button"
                                    className="btn btn-light btn-sm m-1"
                                    onClick={() => {
                                      const url = `https://dashboard.stripe.com/${props.merchant.paymentProviderId}/issuing/authorizations/${authorization.providerId}`
                                      window.open(url, '_blank')?.focus()
                                    }}
                                  >
                                    <i className="fab fa-stripe font-size-16 align-middle me-2"></i>{' '}
                                    Stripe
                                  </button>
                                )}
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  )}
                </Table>
              </div>
            </CardBody>
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default LopayCardSupportComponent
