import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import {
  getLoggedInSupportUser,
  getLoggedInUser,
} from '../../helpers/lopay_api_helper'

interface AuthLayoutProps {
  component: any
  layout: any
  isAuthProtected: any
  path?: string
  exact?: boolean
  key?: number
}

const Authmiddleware = ({
  component,
  layout,
  isAuthProtected,
  path,
  exact,
  key,
  ...rest
}: AuthLayoutProps) => {
  const Layout = layout
  const Component = component

  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          isAuthProtected &&
          !(getLoggedInUser() || getLoggedInSupportUser())
        ) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          )
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}

export default Authmiddleware
