import { useMutation, useQuery, useQueryClient } from 'react-query'

import {
  disconnectQuickbooks,
  getQuickBooksSyncConfig,
  getQuickbooksStatus,
  updateQuickBooksSyncConfig,
} from './quickbooks.api'
import { UpdateQuickBooksSyncingConfig } from './quickbooks.type'

// Poll every 15 seconds
const STATUS_POLL_INTERVAL_MS = 1000 * 15

export function useQuickBooksStatus({
  merchantId,
  key,
}: {
  merchantId: string | null
  key: string | null
}) {
  return useQuery({
    refetchInterval: STATUS_POLL_INTERVAL_MS,
    queryKey: ['quickbooks-status', merchantId, key],
    queryFn: () =>
      merchantId && key
        ? getQuickbooksStatus({ merchantId, key })
        : Promise.resolve(undefined),
  })
}

export function useQuickBooksSyncConfig({
  merchantId,
  key,
}: {
  merchantId: string
  key: string
}) {
  return useQuery({
    queryKey: ['quickbooks-sync-config', merchantId, key],
    queryFn: () => getQuickBooksSyncConfig({ merchantId, key }),
  })
}

export function useDisconnectQuickBooksMutation({
  merchantId,
  key,
}: {
  merchantId: string
  key: string
}) {
  const queryClient = useQueryClient()

  return useMutation(
    () => {
      return disconnectQuickbooks({
        merchantId,
        key,
      })
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['quickbooks-status'],
        })
      },
    },
  )
}

export function useRefreshQuickBooksAccountsListMutation({
  merchantId,
  key,
}: {
  merchantId: string
  key: string
}) {
  const queryClient = useQueryClient()

  return useMutation(
    () => {
      return getQuickBooksSyncConfig({
        merchantId,
        key,
        refreshAccountsList: true,
      })
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['quickbooks-sync-config'],
        })
      },
    },
  )
}

export function useUpdateQuickBooksSyncConfigMutation({
  merchantId,
  key,
}: {
  merchantId: string
  key: string
}) {
  const queryClient = useQueryClient()

  return useMutation(
    (syncConfig: UpdateQuickBooksSyncingConfig) =>
      updateQuickBooksSyncConfig({ merchantId, key, syncConfig }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['quickbooks-sync-config'],
        })

        await queryClient.invalidateQueries({
          queryKey: ['quickbooks-status'],
        })
      },
    },
  )
}
