import React from 'react'
import { Spinner, Table } from 'reactstrap'
import moment from 'moment'
import styled from 'styled-components'

import { useRewards } from './support-merchant-rewards.hook'
import MerchantApiDto from 'src/api-dtos/merchant/merchant.dto.interface'
import { FormattedReward } from './support-merchant-rewards.type'
import { priceFormat } from 'src/helpers/utils'

const StyledCenterContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`

const StyledSpacer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`

type IComponentProps = {
  merchant: MerchantApiDto
  isActive: boolean
}

const SupportMerchantRewards: React.FC<IComponentProps> = ({
  merchant,
  isActive,
}) => {
  const result = useRewards({
    merchant,
    isActive,
  })

  const data = React.useMemo(
    () =>
      ([] as FormattedReward[])
        .concat(...(result.data?.qualified ?? []))
        .concat(...(result.data?.unclaimed ?? []))
        .concat(...(result.data?.claimed ?? [])),
    [result.data],
  )

  if (result.isLoading || !result.data) {
    return <Spinner className="ms-2" color="primary" />
  }

  if (result.isError) {
    return (
      <>Something went wrong whilst loading rewards. Please refresh the page.</>
    )
  }

  if (data.length === 0) {
    return (
      <React.Fragment>
        <div
          className="table-responsive"
          style={{
            paddingBottom: 550,
            marginBottom: -550,
            msOverflowStyle: 'none',
          }}
        >
          <StyledCenterContainer>
            The merchant has no rewards.
          </StyledCenterContainer>
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Table responsive striped className="mb-0">
        <thead>
          <tr>
            <th>Status</th>
            <th>Type</th>
            <th>Title</th>
            <th>Body</th>
            <th>Value</th>
            <th>Referral details</th>
            <th>Claimed at</th>
          </tr>
        </thead>
        <tbody>
          {(data ?? []).map((reward) => {
            return (
              <tr key={reward.id}>
                <td>{reward.status}</td>
                <td>{reward.type}</td>
                <td>{reward.title}</td>
                <td>{reward.body}</td>
                <td>{priceFormat(reward.value)}</td>
                <td>
                  {reward.referral ? (
                    <>
                      <div>Sent by {reward.referral.sender.name}</div>
                      <StyledSpacer />
                      <div>Received by {reward.referral.receiver.name}</div>
                    </>
                  ) : (
                    '---'
                  )}
                </td>
                <td>
                  {reward.claimedAt
                    ? moment(reward.claimedAt).local().format('DD-MM-YY hh:mm')
                    : '---'}
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </React.Fragment>
  )
}

export default SupportMerchantRewards
