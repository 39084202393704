import { useInfiniteQuery } from 'react-query'

import { getRewardActivity } from './support-merchant-reward-activity.api'

export function useRewardActivity({
  isActive,
  merchantId,
  pageSize,
}: {
  isActive: boolean
  merchantId: string
  pageSize?: number
}) {
  return useInfiniteQuery(
    ['rewardActivity', { merchantId, pageSize }],
    getRewardActivity({ merchantId, pageSize }),
    {
      enabled: isActive,
      getNextPageParam: (lastPage) => {
        const lastPageItem = lastPage.items[lastPage.items.length - 1]

        return lastPageItem
          ? `${lastPageItem?.id},${lastPageItem?.type}`.trim()
          : undefined
      },
    },
  )
}
