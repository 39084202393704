import { Spinner, Table } from 'reactstrap'
import React, { useEffect, useState } from 'react'
import { useMerchantAudits } from '../hooks/merchant-audits.hook'
import {
  MerchantAccountTypeDTO,
  MerchantAuditAction,
  MerchantAuditLogDto,
  MerchantScheduleAuditDTO,
  MerchantVATAuditDTO,
} from '../../../../api-dtos/merchant/merchant-audit-log.dto.interface'
import {
  getAccountTypeFriendlyName,
  getPaymentPayoutScheduleFriendlyName,
} from '../../../../helpers/utils'
import moment from 'moment'

const getFormattedAction = (action: MerchantAuditAction) => {
  switch (action) {
    case 'schedule_updated':
      return 'Schedule'
    case 'vat_registration_updated':
      return 'VAT'
    case 'account_type_updated':
      return 'Account Type'
  }
}

type AuditAccountTypeLogProps = {
  audit: MerchantAccountTypeDTO
}

const AuditAccountTypeLog = ({ audit }: AuditAccountTypeLogProps) => {
  return (
    <tr key={audit.id}>
      <td>{getFormattedAction(audit.action)}</td>
      <td>
        {getAccountTypeFriendlyName(
          audit.data.previousState.accountType,
          audit.data.previousState.config,
        )}
      </td>
      <th scope="row">
        {getAccountTypeFriendlyName(
          audit.data.newState.accountType,
          audit.data.newState.config,
        )}
      </th>
      <td>
        <span className="time">
          {moment(new Date(audit.createdAt)).format(
            'Do MMM YYYY [at] HH:mm:ss',
          )}
        </span>
      </td>
      <td>{audit.note}</td>
    </tr>
  )
}

type AuditScheduleLogProps = {
  audit: MerchantScheduleAuditDTO
}

const AuditScheduleLog = ({ audit }: AuditScheduleLogProps) => {
  return (
    <tr key={audit.id}>
      <td>{getFormattedAction(audit.action)}</td>
      <td>
        {getPaymentPayoutScheduleFriendlyName(
          audit.data.previousState.schedule,
          audit.data.previousState.config,
        )}
        {audit.data.previousState.schedule === 't2' &&
          audit.data.previousState.weeklyAnchorDay &&
          ` (${audit.data.previousState.weeklyAnchorDay})`}
      </td>
      <th scope="row">
        {getPaymentPayoutScheduleFriendlyName(
          audit.data.newState.schedule,
          audit.data.newState.config,
        )}
        {audit.data.newState.schedule === 't2' &&
          audit.data.newState.weeklyAnchorDay &&
          ` (${audit.data.newState.weeklyAnchorDay})`}
      </th>
      <td>
        <span className="time">
          {moment(new Date(audit.createdAt)).format(
            'Do MMM YYYY [at] HH:mm:ss',
          )}
        </span>
      </td>
      <td>{audit.note}</td>
    </tr>
  )
}

type AuditVATLogProps = {
  audit: MerchantVATAuditDTO
}

const AuditVATLog = ({ audit }: AuditVATLogProps) => {
  return (
    <tr key={audit.id}>
      <td>{getFormattedAction(audit.action)}</td>
      <td>
        {audit.data.previousState.isVATRegistered
          ? `Registered: ${audit.data.previousState.taxIdentifier || '-'}`
          : 'Not registered'}
      </td>
      <th scope="row">
        {audit.data.newState.isVATRegistered
          ? `Registered: ${audit.data.newState.taxIdentifier || '-'}`
          : 'Not registered'}
      </th>
      <td>
        <span className="time">
          {moment(new Date(audit.createdAt)).format(
            'Do MMM YYYY [at] HH:mm:ss',
          )}
        </span>
      </td>
      <td>{audit.note}</td>
    </tr>
  )
}

type MerchantAuditLogProps = {
  merchantId: string
}

const MerchantAuditLog = (props: MerchantAuditLogProps) => {
  const { merchantId } = props

  const { data, isLoading, isError } = useMerchantAudits(merchantId)
  const [audits, setAudits] = useState<MerchantAuditLogDto[]>()

  useEffect(() => {
    if (data) {
      setAudits(data.audits)
    }
  }, [data])

  if (isLoading) {
    return <Spinner color="primary" />
  }

  if (isError || !data) {
    return <p>Error loading. Please refresh.</p>
  }

  return (
    <div className="table-responsive">
      <Table className="table table-striped mb-0">
        <thead>
          <tr>
            <th>Log Type</th>
            <th>Previous State</th>
            <th>New State</th>
            <th>Date</th>
            <th>Note</th>
          </tr>
        </thead>
        <tbody>
          {audits &&
            audits.map((audit) => {
              switch (audit.action) {
                case 'schedule_updated':
                  return <AuditScheduleLog audit={audit} />
                case 'account_type_updated':
                  return <AuditAccountTypeLog audit={audit} />
                default:
                  return <AuditVATLog audit={audit} />
              }
            })}
        </tbody>
      </Table>
    </div>
  )
}

export default MerchantAuditLog
