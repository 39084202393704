import React from 'react'
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react'
import { connect } from 'react-redux'
import MetaTags from 'react-meta-tags'
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle } from 'reactstrap'
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'

const LoadingContainer = () => <div>Loading...</div>

interface GoogleProps {
  google: Object
  zoom: number
}

const MapsGoogle = ({ google, zoom }: GoogleProps) => {
  //const selectedPlace: any = {};

  function onMarkerClick() {
    alert('You clicked in this marker')
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Google Maps | Minia - React Admin & Dashboard Template</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs title="Maps" breadcrumbItem="Google Maps" />

          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle>Markers</CardTitle>
                  <CardSubtitle className="mb-3">
                    Example of google maps.
                  </CardSubtitle>
                  <div
                    id="gmaps-markers"
                    className="gmaps"
                    style={{ position: 'relative' }}
                  >
                    <Map
                      google={google}
                      // zoom={14}
                      style={{ width: '100%', height: '100%' }}
                    >
                      {/* <Marker
                        title={"The marker`s title will appear as a tooltip."}
                        name={"SOMA"}
                        position={{ lat: 37.778519, lng: -122.40564 }}
                      /> */}
                      {/* <Marker name={"Dolores park"} /> */}
                      {/* <InfoWindow>
                        <div>
                          <h1>{selectedPlace.name}</h1>
                        </div>
                      </InfoWindow> */}
                    </Map>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle>Overlays</CardTitle>
                  <CardSubtitle className="mb-3">
                    Example of google maps.
                  </CardSubtitle>
                  <div
                    id="gmaps-overlay"
                    className="gmaps"
                    style={{ position: 'relative' }}
                  >
                    <Map
                      google={google}
                      // zoom={14}
                      style={{ width: '100%', height: '100%' }}
                      initialCenter={{
                        lat: 40.854885,
                        lng: -88.081807,
                      }}
                    >
                      <Marker
                        onClick={() => {
                          onMarkerClick()
                        }}
                      />
                    </Map>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle>Basic</CardTitle>
                  <CardSubtitle className="mb-3">
                    Example of google maps.
                  </CardSubtitle>
                  <div
                    id="gmaps-markers"
                    className="gmaps"
                    style={{ position: 'relative' }}
                  >
                    <Map
                      google={google}
                      style={{ width: '100%', height: '100%' }}
                    ></Map>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle>Ultra Light</CardTitle>
                  <CardSubtitle className="mb-3">
                    Example of google maps.
                  </CardSubtitle>
                  <div
                    id="gmaps-overlay"
                    className="gmaps"
                    style={{ position: 'relative' }}
                  >
                    <Map
                      google={google}
                      // styles={LightData.Data}
                      style={{ width: '100%', height: '100%' }}
                    >
                      <Marker
                        onClick={() => {
                          onMarkerClick()
                        }}
                      />
                    </Map>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

// MapsGoogle.propTypes = {
//   google: PropTypes.object
// }

export default connect(
  null,
  {},
)(
  GoogleApiWrapper({
    apiKey: 'AIzaSyBC3mxVrhHJ1_kHol05VliqIgXtglDGnVM',
    LoadingContainer: LoadingContainer,
    // v: "3",
  })(MapsGoogle),
)
