import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

//i18n
import { withTranslation } from 'react-i18next'
// Redux
import { withRouter, Link } from 'react-router-dom'

//redux
import { RegisterUserResponse } from '../../../api-dtos/auth/register-user-response.dto.interface'

const ProfileMenu = (props: any) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState<boolean>(false)

  const [username, setUsername] = useState<string | undefined>()

  useEffect(() => {
    const getAuthUser =
      localStorage.getItem('authUser') ||
      localStorage.getItem('authSupportUser')

    if (getAuthUser) {
      const obj: RegisterUserResponse = JSON.parse(getAuthUser)
      setUsername(`${obj.user.firstName} ${obj.user.lastName}`)
    }
  }, [])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className="d-none d-xl-inline-block ms-2 me-1"></span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          {username || 'Profile'}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {1 + 1 === 3 && (
            <>
              <DropdownItem tag="a" href="/contacts-profile">
                {' '}
                <i className="bx bx-user font-size-16 align-middle me-1" />
                {props.t('Profile')}{' '}
              </DropdownItem>
              <DropdownItem tag="a" href="/profile">
                <span className="badge bg-success float-end">11</span>
                <i className="bx bx-wrench font-size-16 align-middle me-1" />
                {props.t('Settings')}
              </DropdownItem>
              <div className="dropdown-divider" />
            </>
          )}

          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t('Logout')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}
export default withTranslation()(withRouter(ProfileMenu))
