import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import ReactTimeAgo from 'react-time-ago'
import { Badge, Table } from 'reactstrap'
import styled from 'styled-components'

import { PaginatedWaitingLists } from '../../waiting-lists.type'

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`

type IComponentProps = {
  data: PaginatedWaitingLists | undefined
  isLoading: boolean
  isError: boolean
}

const WaitingListsTable: React.FC<IComponentProps> = ({
  data,
  isLoading,
  isError,
}) => {
  const history = useHistory()

  if (isLoading) {
    return <div>Loading ...</div>
  }

  if (isError) {
    return <div>Error loading waiting lists. Please try again.</div>
  }

  if ((data?.count ?? 0) === 0) {
    return (
      <React.Fragment>
        <div
          className="table-responsive"
          style={{
            paddingBottom: 550,
            marginBottom: -550,
            msOverflowStyle: 'none',
          }}
        >
          <CenterContainer>No results</CenterContainer>
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div
        className="table-responsive"
        style={{
          paddingBottom: 550,
          marginBottom: -550,
          msOverflowStyle: 'none',
        }}
      >
        <Table className="table table-striped mb-0">
          <thead>
            <tr>
              <th>Type</th>
              <th>Waiting list capabilities</th>
              <th>Granted access capabilities</th>
              <th>Created at</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {(data?.data ?? []).map((waitingList) => {
              const waitingListCapabilities = (
                <>
                  {waitingList.waitingListCapabilities.map((capability) => (
                    <Badge className="me-2 bg-warning">{capability}</Badge>
                  ))}
                </>
              )

              const grantedAccessCapabilities = (
                <>
                  {waitingList.grantedAccessCapabilities.map((capability) => (
                    <Badge className="me-2 bg-success">{capability}</Badge>
                  ))}
                </>
              )

              return (
                <tr key={waitingList.id}>
                  <td>{waitingList.type}</td>
                  <td>{waitingListCapabilities}</td>
                  <td>{grantedAccessCapabilities}</td>
                  <td>
                    <ReactTimeAgo date={waitingList.createdAt!} />
                  </td>
                  <td>
                    <Link
                      onClick={(e) => {
                        e.preventDefault()

                        history.push(`/waiting-lists/${waitingList.id}`)
                      }}
                      to="#"
                      className="btn btn-primary"
                    >
                      Update
                    </Link>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  )
}

export default WaitingListsTable
