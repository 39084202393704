import { useMutation, useQueryClient, useInfiniteQuery } from 'react-query'

import {
  getUserSessions,
  deactivateUserSession,
} from './support-merchant-team.api'

export function useUserSessionsQuery({
  userId,
  offsetId,
  pageSize,
}: {
  userId: string | undefined
  offsetId?: string | undefined
  pageSize: number
}) {
  return useInfiniteQuery({
    queryKey: ['userSessions', { userId, pageSize, offsetId }],
    queryFn: () =>
      userId
        ? getUserSessions({ userId, pageSize })
        : Promise.resolve(undefined),
    enabled: !!userId,
    getNextPageParam: (lastPage) =>
      lastPage ? lastPage.data[lastPage.data.length - 1]?.id : undefined,
  })
}

export function useDeactiveUserSessionMutation({ userId }: { userId: string }) {
  const queryClient = useQueryClient()

  return useMutation(
    (params: { sessionId: string }) =>
      deactivateUserSession({
        userId,
        sessionId: params.sessionId,
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['userSessions', { userId }],
        })
      },
    },
  )
}
