import { useInfiniteQuery } from 'react-query'

import { getPayoutTransactions } from './support-merchant-payouts.api'

export function usePayoutTransactions({
  merchantId,
  payoutId,
  pageSize,
}: {
  merchantId: string
  payoutId: string | undefined
  pageSize?: number
}) {
  return useInfiniteQuery(
    ['payoutTransactions', { merchantId, payoutId, pageSize }],
    getPayoutTransactions({ merchantId, payoutId: payoutId!, pageSize }),
    {
      enabled: !!payoutId,
      getNextPageParam: (lastPage) =>
        lastPage.transactions.transactions[
          lastPage.transactions.transactions.length - 1
        ]?.id,
    },
  )
}
