import styled from 'styled-components'

const TimelineList = styled.ul`
  margin-bottom: -2rem;
  margin-left: -2rem;
  list-style-type: none;
  list-style-image: none;
  list-style-position: outside;
  line-height: 24px;
`

export default TimelineList
