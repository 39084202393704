import { useQuery } from 'react-query'
import { supportGetScheduleHistory } from '../../../../helpers/lopay_api_helper'

export function useMerchantAudits(merchantId: string) {
  return useQuery({
    queryKey: ['merchantAudits'],
    queryFn: () => supportGetScheduleHistory(merchantId),
    refetchInterval: 5000,
  })
}
