import { AdditionalDocumentsRequestStatus } from '../../enums/additional-documents-request-status.enum'
import { Col, Label, Row } from 'reactstrap'
import React, { useState } from 'react'
import { useChangeAdditionDocumentsStatus } from '../../hooks/kyb-settings.hook'

type KYBAdditionalDocumentsSelectorProps = {
  merchantId: string
  additionalDocumentsRequestStatus: AdditionalDocumentsRequestStatus
  refetchFn: () => void
}

const KYBAdditionalDocumentsSelector = (
  props: KYBAdditionalDocumentsSelectorProps,
) => {
  const { merchantId, additionalDocumentsRequestStatus, refetchFn } = props

  const mutation = useChangeAdditionDocumentsStatus(merchantId, refetchFn)

  const [status, setStatus] = useState<AdditionalDocumentsRequestStatus>(
    additionalDocumentsRequestStatus,
  )

  const additionalDocumentsStatusChoices: Array<{
    value: AdditionalDocumentsRequestStatus
    label: string
  }> = [
    {
      value: AdditionalDocumentsRequestStatus.NotRequested,
      label: 'Not requested',
    },
    { value: AdditionalDocumentsRequestStatus.Requested, label: 'Requested' },
    {
      value: AdditionalDocumentsRequestStatus.RequestedNoPayments,
      label: 'Requested (Disable payments)',
    },
    {
      value: AdditionalDocumentsRequestStatus.RequestedNoPayouts,
      label: 'Requested (Disable payouts)',
    },
    {
      value: AdditionalDocumentsRequestStatus.RequestedNoPaymentsOrPayout,
      label: 'Requested (Disable payments & payouts)',
    },
  ]

  const handleSubmit = () => {
    mutation.mutate(status)
  }

  return (
    <div>
      <Row>
        <Label>Additional document verification</Label>
        <Col sm={12} lg={4}>
          <select
            className="form-select"
            value={status}
            onChange={(e) => {
              setStatus(e.target.value as AdditionalDocumentsRequestStatus)
            }}
          >
            {additionalDocumentsStatusChoices.map((o) => (
              <option value={o.value}>{o.label}</option>
            ))}
          </select>
        </Col>
        <Col sm={12} lg={2}>
          <button
            className="btn btn-primary py-0 pe-2 px-1 d-flex align-items-center justify-content-center"
            onClick={handleSubmit}
            disabled={
              mutation.isLoading || additionalDocumentsRequestStatus === status
            }
          >
            {mutation.isLoading ? (
              <i className="mdi mdi-loading mdi-spin font-size-24"></i>
            ) : (
              <i className="mdi mdi-content-save font-size-24"></i>
            )}
            <span className="ms-1">Save</span>
          </button>
        </Col>
      </Row>
    </div>
  )
}

export default KYBAdditionalDocumentsSelector
