import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import TimeAgo from 'javascript-time-ago'
import getSymbolFromCurrency from 'currency-symbol-map'

//redux
import { useDispatch } from 'react-redux'

import { Link } from 'react-router-dom'

import {
  Alert,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Table,
  Container,
  Badge,
  Modal,
} from 'reactstrap'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
import {
  getUserProfile,
  listExternalAccounts,
  listPayouts,
  updateExternalAccount,
  updatePayoutSchedule,
} from '../../helpers/lopay_api_helper'
import { fetchProfile } from '../../store/actions'
import UserProfileResponse from '../../api-dtos/profile/user-profile-response.dto.interface'

// Register timezone
import en from 'javascript-time-ago/locale/en.json'
import ExternalAccountDto from '../../api-dtos/external-account/external-account.dto.interface'
import PayoutAPIDto from '../../api-dtos/payout/payout.dto.interface'
import ReactTimeAgo from 'react-time-ago'
import { priceFormat } from '../../helpers/utils'

try {
  TimeAgo.addDefaultLocale(en)
} catch (e) {
  console.error(e)
}

interface MerchantPageProps {
  history: any
}

const ExternalAccountsPage = ({ history }: MerchantPageProps) => {
  const dispatch = useDispatch()

  const [pendingPayoutScheduleChange, setPendingPayoutScheduleChange] =
    useState<'t2' | 't1' | 'instant' | undefined>()
  const [userProfile] = useState<UserProfileResponse | null>(getUserProfile())
  const [externalAccounts, setExternalAccounts] = useState<
    ExternalAccountDto[]
  >([])
  const [selectedExternalAccount, setSelectedExternalAccount] = useState<
    ExternalAccountDto | undefined
  >()

  const [payouts, setPayouts] = useState<PayoutAPIDto[]>([])

  const merchant = userProfile?.user.merchants[0]

  const [payoutDelayDays, setPayoutDelayDays] = useState<number | undefined>(
    merchant?.payoutSchedule.payoutDelayDays,
  )

  const loadExternalAccounts = async (merchantId: string) => {
    const _externalAccounts = await listExternalAccounts(merchantId)
    setExternalAccounts(_externalAccounts)
  }

  const loadPayouts = async (merchantId: string) => {
    const _payouts = await listPayouts(merchantId)
    setPayouts(_payouts.payouts)
  }

  useEffect(() => {
    dispatch(fetchProfile())
    userProfile?.user.merchants[0]?.id &&
      loadExternalAccounts(userProfile?.user.merchants[0]?.id)
    userProfile?.user.merchants[0]?.id &&
      loadPayouts(userProfile?.user.merchants[0]?.id)
    // eslint-disable-next-line
  }, [])

  if (!merchant) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Bank accounts</title>
          </MetaTags>
          <Container fluid>
            <Alert color="danger">
              Sorry there was a problem loading your details
            </Alert>
          </Container>
        </div>
      </React.Fragment>
    )
  }

  const isSelected = (target: 't2' | 't1' | 'instant') => {
    if (target === 'instant') {
      return payoutDelayDays === -1
    }
    if (target === 't1') {
      return payoutDelayDays === 0
    }
    if (target === 't2') {
      return payoutDelayDays === 1
    }
    return false
  }

  const notSelectedBadgeColour = (box: 't2' | 't1' | 'instant') => {
    if (box === 'instant') {
      return 'me-2 bg-success'
    }
    if (box === 't1') {
      if (payoutDelayDays === 1) {
        return 'me-2 bg-success'
      }
    }
    return 'me-2 bg-dark'
  }

  const changePlan = async () => {
    // User has confirmed, so go ahead and change:

    // TODO: Call API to change payout schedule

    switch (pendingPayoutScheduleChange) {
      case 'instant':
        await updatePayoutSchedule(merchant.id, 'instant')
        setPayoutDelayDays(-1)
        break
      case 't1':
        await updatePayoutSchedule(merchant.id, 't1')
        setPayoutDelayDays(0)
        break
      case 't2':
        await updatePayoutSchedule(merchant.id, 't2')
        setPayoutDelayDays(1)
        break
    }

    setPendingPayoutScheduleChange(undefined)
  }

  const handleChangePayoutPlan = (plan: 't2' | 't1' | 'instant') => {
    switch (plan) {
      case 'instant':
        if (payoutDelayDays !== -1) {
          setPendingPayoutScheduleChange('instant')
        }
        break
      case 't1':
        if (payoutDelayDays !== 0) {
          setPendingPayoutScheduleChange('t1')
        }
        break
      case 't2':
        if (payoutDelayDays !== 1) {
          setPendingPayoutScheduleChange('t2')
        }
        break
    }
  }

  const updateDefaultExternalAccount = async () => {
    if (!selectedExternalAccount?.id) {
      return
    }

    const updatedAccount = await updateExternalAccount(
      merchant.id,
      selectedExternalAccount.id,
      {
        isDefaultForCurrency: true,
      },
    )
    const index = externalAccounts.findIndex(
      (externalAccount) => externalAccount.id === selectedExternalAccount.id,
    )

    externalAccounts[index] = updatedAccount

    await loadExternalAccounts(merchant.id)
    setSelectedExternalAccount(undefined)
  }

  const paymentDate = (date: Date) => {
    return new Date(date)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Bank accounts</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={merchant.name}
            breadcrumbItem="Bank Accounts &amp; Payouts"
          />

          {payoutDelayDays !== undefined && (
            <Row>
              <Col xs={12}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title">Payout Schedule</h4>
                    <p className="card-title-desc">
                      Decide how often we pay into your bank account
                    </p>
                  </CardHeader>

                  <CardBody className="p-4">
                    <Row>
                      <Col lg={4}>
                        <Card
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleChangePayoutPlan('t2')}
                          className={
                            isSelected('t2')
                              ? 'bg-primary border-primary text-white-50'
                              : 'border border-primary'
                          }
                        >
                          <div
                            className={
                              isSelected('t2')
                                ? 'card-header bg-transparent border-info'
                                : 'card-header bg-transparent border-primary'
                            }
                          >
                            <h5
                              className={
                                isSelected('t2')
                                  ? 'text-white mb-0'
                                  : 'my-0 text-primary'
                              }
                            >
                              <i className="mdi mdi-calendar-clock-outline me-3"></i>
                              After two days
                            </h5>
                          </div>
                          <CardBody>
                            <h5
                              className={
                                isSelected('t2')
                                  ? 'card-title text-white'
                                  : 'card-title'
                              }
                            >
                              Payments clear after 2 days as standard
                            </h5>
                            <Badge className={notSelectedBadgeColour('t2')}>
                              Standard {isSelected('t2') && ' (Selected)'}
                            </Badge>
                            <p
                              className={
                                isSelected('t2')
                                  ? 'card-text text-white'
                                  : 'card-text'
                              }
                            >
                              Payments clear into your account the two days
                              after payment.
                            </p>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col lg={4}>
                        <Card
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleChangePayoutPlan('t1')}
                          className={
                            isSelected('t1')
                              ? 'bg-primary border-primary text-white-50'
                              : 'border border-primary'
                          }
                        >
                          <div
                            className={
                              isSelected('t1')
                                ? 'card-header bg-transparent border-info'
                                : 'card-header bg-transparent border-primary'
                            }
                          >
                            <h5
                              className={
                                isSelected('t1')
                                  ? 'text-white mb-0'
                                  : 'my-0 text-primary'
                              }
                            >
                              <i className="mdi mdi-weather-sunset me-3"></i>
                              Next day
                            </h5>
                          </div>
                          <CardBody>
                            <h5
                              className={
                                isSelected('t1')
                                  ? 'card-title text-white'
                                  : 'card-title'
                              }
                            >
                              Receive payments the following day
                            </h5>
                            <Badge className={notSelectedBadgeColour('t1')}>
                              +0.5%{isSelected('t1') && ' (Selected)'}
                            </Badge>
                            <p
                              className={
                                isSelected('t1')
                                  ? 'card-text text-white'
                                  : 'card-text'
                              }
                            >
                              Payments clear into your account the following day
                              after payment.
                            </p>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col lg={4}>
                        <Card
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleChangePayoutPlan('instant')}
                          className={
                            isSelected('instant')
                              ? 'bg-primary border-primary text-white-50'
                              : 'border border-primary'
                          }
                        >
                          <div
                            className={
                              isSelected('instant')
                                ? 'card-header bg-transparent border-info'
                                : 'card-header bg-transparent border-primary'
                            }
                          >
                            <h5
                              className={
                                isSelected('instant')
                                  ? 'text-white mb-0'
                                  : 'my-0 text-primary'
                              }
                            >
                              <i className="mdi mdi-lightning-bolt me-3"></i>
                              Instantly available
                            </h5>
                          </div>
                          <CardBody>
                            <h5
                              className={
                                isSelected('instant')
                                  ? 'card-title text-white'
                                  : 'card-title'
                              }
                            >
                              Instant and same day payouts
                            </h5>
                            <Badge
                              className={notSelectedBadgeColour('instant')}
                            >
                              +1%{isSelected('instant') && ' (Selected)'}
                            </Badge>
                            <p
                              className={
                                isSelected('instant')
                                  ? 'card-text text-white'
                                  : 'card-text'
                              }
                            >
                              Get paid same day or trigger payouts that
                              instantly clear into your account
                            </p>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <div className="row align-ite  ms-center">
                    <div className="col-md-6">
                      <h4 className="card-title">Your bank accounts</h4>
                      <p className="card-title-desc">
                        Funds are paid out to your bank account, based on your
                        payout schedule
                      </p>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                        <div>
                          <Link
                            onClick={(e) => {
                              e.preventDefault()
                              history.push('/add-external-account')
                            }}
                            to="#"
                            className="btn btn-primary"
                          >
                            <i className="bx bx-plus me-1"></i> Add New
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardHeader>

                <CardBody className="p-4">
                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Account</th>
                          <th>Currency</th>
                          <th>Number</th>
                          <th>Sort code</th>
                          <th>Payout Methods</th>
                        </tr>
                      </thead>
                      <tbody>
                        {externalAccounts.map((externalAccount) => {
                          return (
                            <tr
                              onClick={() => {
                                setSelectedExternalAccount(externalAccount)
                              }}
                              key={externalAccount.id}
                            >
                              <td>
                                {externalAccount.name}{' '}
                                {externalAccount.isDefaultForCurrency && (
                                  <Badge className="me-2 bg-success">
                                    Default
                                  </Badge>
                                )}
                              </td>
                              <td>{externalAccount.accountHolderName}</td>
                              <td>
                                {externalAccount.currencyCode} (
                                {getSymbolFromCurrency(
                                  externalAccount.currencyCode.toLocaleUpperCase(),
                                )}
                                )
                              </td>
                              <td>{externalAccount.accountNumber}</td>
                              <td>{externalAccount.routingNumber}</td>
                              <td>
                                {externalAccount.payoutMethods?.map(
                                  (method) => {
                                    return (
                                      <Badge
                                        key={method}
                                        className="me-2 bg-info"
                                      >
                                        {method}
                                      </Badge>
                                    )
                                  },
                                )}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <div className="row align-ite  ms-center">
                    <div className="col-md-6">
                      <h4 className="card-title">Recent payouts</h4>
                      <p className="card-title-desc">
                        Recent payouts to your bank account
                      </p>
                    </div>
                  </div>
                </CardHeader>

                <CardBody className="p-4">
                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Estimated Arrival</th>
                          <th>Status</th>
                          <th>Description</th>
                          <th>Fees</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {payouts.map((payout) => {
                          return (
                            <tr
                              onClick={() => {
                                // open payout details (included payments etc.)
                              }}
                              key={payout.id}
                            >
                              <td>
                                <ReactTimeAgo
                                  date={paymentDate(payout.createdAt)}
                                  locale="en-US"
                                />
                              </td>
                              <td>
                                {payout.estArrivalDate ? (
                                  <ReactTimeAgo
                                    date={paymentDate(payout.estArrivalDate)}
                                    locale="en-US"
                                  />
                                ) : (
                                  'Pending'
                                )}
                              </td>
                              <td>
                                <Badge
                                  className={
                                    payout.status === 'canceled' ||
                                    payout.status === 'failed'
                                      ? 'me-2 bg-danger'
                                      : 'bg-success'
                                  }
                                >
                                  {payout.status}
                                </Badge>
                              </td>
                              <td>{payout.description}</td>
                              <td>{priceFormat(payout.fee)}</td>
                              <td>{priceFormat(payout.amount)}</td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={!!pendingPayoutScheduleChange}
            toggle={() => {
              setPendingPayoutScheduleChange(undefined)
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Update payout schedule
              </h5>
              <button
                type="button"
                onClick={() => {
                  setPendingPayoutScheduleChange(undefined)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>You you sure you want to update your payout schedule?</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setPendingPayoutScheduleChange(undefined)
                }}
                className="btn btn-secondary "
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  changePlan()
                }}
                type="button"
                className="btn btn-primary "
              >
                Update
              </button>
            </div>
          </Modal>

          <Modal
            isOpen={!!selectedExternalAccount}
            toggle={() => {
              setSelectedExternalAccount(undefined)
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                {selectedExternalAccount?.name}
              </h5>
              <button
                type="button"
                onClick={() => {
                  setSelectedExternalAccount(undefined)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Account number: {selectedExternalAccount?.accountNumber}</p>
              <p>Sort code: {selectedExternalAccount?.routingNumber}</p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  setSelectedExternalAccount(undefined)
                }}
                className="btn btn-secondary "
                data-dismiss="modal"
              >
                Close
              </button>
              {!selectedExternalAccount?.isDefaultForCurrency && (
                <button
                  onClick={() => {
                    updateDefaultExternalAccount()
                  }}
                  type="button"
                  className="btn btn-primary "
                >
                  Make default
                </button>
              )}
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ExternalAccountsPage
