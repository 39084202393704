import React, { useEffect } from 'react'
import { useElements } from '@stripe/react-stripe-js'
import { hideAndShowCopySuccess } from '../card-details.hook'

const CardDetailsExpiry = ({
  issuingCard,
  ephemeralKeySecret,
  nonce,
}: {
  issuingCard: string
  ephemeralKeySecret: string
  nonce?: string
}) => {
  const elements = useElements()

  useEffect(() => {
    if (elements) {
      elements
        .create('issuingCardExpiryDisplay', {
          issuingCard,
          ephemeralKeySecret,
          nonce,
          style: {
            base: {
              color: '#007AFF',
              fontWeight: '300',
            },
          },
        })
        .mount('#issuing-card-expiry-container')

      const cardExpiryCopyElement = elements.create('issuingCardCopyButton', {
        toCopy: 'expiry',
      })
      cardExpiryCopyElement.mount('#card-expiry-copy')

      cardExpiryCopyElement.on('click', () =>
        hideAndShowCopySuccess('card-expiry-copy', 'card-expiry-copy-success'),
      )
    }
  }, [elements, issuingCard, ephemeralKeySecret, nonce])

  return (
    <div className="card-details-row">
      <div className="card-details-row-text">
        <strong>Expiry</strong>
        <div id="issuing-card-expiry-container"></div>
      </div>
      <div id="card-expiry-copy"></div>
      <div id="card-expiry-copy-success"></div>
    </div>
  )
}

export default CardDetailsExpiry
