import React, { useEffect, useState } from 'react'
import { Table } from 'react-super-responsive-table'
import { Button } from 'reactstrap'

interface IComponentProps {
  merchantId: string
}

export const SupportMerchantHardwareCustomPricing: React.FC<
  IComponentProps
> = ({ merchantId }) => {
  const [loading, setLoading] = useState(true)
  const [merchantHardware, setMerchantHardware] = useState([])

  const loadMerchantHardware = async () => {
    setLoading(false)
    setMerchantHardware([])
  }

  useEffect(() => {
    loadMerchantHardware()
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <Button color="primary">Add Custom Price</Button>
      <Table className="table table-striped mb-0">
        <thead>
          <tr>
            <th>Name</th>
            <th>Base Price</th>
            <th>First Order Discount</th>
            <th>Postage</th>
          </tr>
        </thead>
        <tbody>
          {loading && !merchantHardware.length ? (
            <tr>
              <td>
                <strong>Loading...</strong>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          ) : (
            merchantHardware.map((mh) => {
              return (
                <tr>
                  <td></td>
                </tr>
              )
            })
          )}
        </tbody>
      </Table>
    </div>
  )
}
