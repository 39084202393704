import { KYBStatus } from '../kyb-threshold.type'
import { Card, CardBody, Col, Input, Label } from 'reactstrap'
import React from 'react'
import { useToggleFraudRuleStatusMutation } from '../fraud-framework.hook'

type FraudRuleProps = {
  /** Indicates if the user should be able to toggle the fraud rule switches */
  isSuperUser: boolean
  /** Function to trigger refreshing of fraud rule data */
  refetchFn: () => void
  fraudRule: {
    /** The unique name tag of the rule. */
    name: string
    /** The body of the rule card. */
    description: string
    /** Actions that a triggered depending on the KYB status. */
    actions: Record<KYBStatus, string>
    /** Whether the rule is enabled or not. */
    enabled: boolean
  }
}

/**
 * Displays an individual fraud rule in a clear, understandable format which helps to visually
 * indicate if the rule triggers a block or just an alert.
 *
 * @param props - Details of the rule.
 * @constructor
 */
const FraudRule = (props: FraudRuleProps) => {
  const {
    isSuperUser,
    refetchFn,
    fraudRule: { name, description, actions, enabled },
  } = props

  const mutation = useToggleFraudRuleStatusMutation(refetchFn)

  const icon = enabled
    ? `mdi-play-circle text-success`
    : 'mdi-pause-circle text-danger'

  const borderColour = enabled ? `border-success` : `border-danger`

  const statusToTitle: Record<KYBStatus, string> = {
    approved: 'Approved',
    unrestrictedOther: 'Unrestricted Other',
    unrestrictedNew: 'Unrestricted New',
    restricted: 'Restricted',
    blocked: 'Blocked',
  }

  const handleToggle = () => {
    mutation.mutate({
      ...(enabled ? { disable: [name] } : { enable: [name] }),
    })
  }

  return (
    <Col md={6}>
      <Card className={`text-black-50 ${borderColour}`}>
        <CardBody>
          <p
            className="card-text text-black"
            style={{ whiteSpace: 'pre-wrap' }}
          >
            {description}
          </p>
          <div>
            <table className="table-sm table-bordered">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(actions).map(([status, action]) => (
                  <tr>
                    <td>{statusToTitle[status as KYBStatus]}</td>
                    <td>{action}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <br />
          <div className="d-flex align-items-center justify-content-between">
            <small>#{name}</small>
            {isSuperUser ? (
              <>
                <Input
                  type="checkbox"
                  id={`${name}Switch`}
                  switch="bool"
                  checked={enabled}
                  disabled={mutation.isLoading}
                  onChange={handleToggle}
                />
                <Label
                  htmlFor={`${name}Switch`}
                  data-on-label="On"
                  data-off-label="Off"
                ></Label>
              </>
            ) : (
              <span>
                <i className={`mdi ${icon} me-2`}></i>{' '}
                {enabled ? 'ENABLED' : 'DISABLED'}
              </span>
            )}
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}

export default FraudRule
