import { takeEvery, fork, put, all, call } from 'redux-saga/effects'

// Login Redux States
import { ProfileTypes } from './actionTypes'
import {
  supportProfileSuccess,
  supportProfileError,
  fetchSupportProfileSuccess,
} from './actions'

import {
  fetchUserSupportProfile,
  postJwtProfile,
} from '../../../helpers/lopay_api_helper'
import UserProfileResponse from '../../../api-dtos/profile/user-profile-response.dto.interface'

function* editSupportProfile({ payload: { user } }: any) {
  try {
    const response: Promise<any> = yield call(postJwtProfile, {
      username: user.username,
      idx: user.idx,
    })
    yield put(supportProfileSuccess(response))
  } catch (error) {
    yield put(supportProfileError(error))
  }
}
// eslint-disable-next-line
function* fetchSupportProfile({
  // eslint-disable-next-line no-empty-pattern
  payload: {},
}: {
  payload: { userId: string }
  type: ProfileTypes
}) {
  try {
    console.log('fetchSupportProfile running...')
    const response: Promise<UserProfileResponse> = yield call(
      fetchUserSupportProfile,
    )
    localStorage.setItem('authSupportUser', JSON.stringify(response))
    yield put(fetchSupportProfileSuccess(response))
  } catch (error) {
    yield put(supportProfileError(error))
  }
}

export function* watchProfile() {
  yield takeEvery(ProfileTypes.UPDATE_EDIT_PROFILE_SUPPORT, editSupportProfile)
  yield takeEvery(ProfileTypes.FETCH_PROFILE_SUPPORT, fetchSupportProfile)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
