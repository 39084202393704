import React from 'react'
import { Container, Card, CardBody } from 'reactstrap'

import { ReactComponent as LopayQuickbooksLogo } from '../../assets/images/quickbooks/lopay_quickbooks.svg'

interface IComponentProps {}

const QuickBooksDisconnectedPage: React.FC<IComponentProps> = () => {
  return (
    <Container className="p-4" fluid="sm">
      <div className="d-flex justify-content-center mb-4">
        <LopayQuickbooksLogo height={50} width={200} />
      </div>
      <div>
        <div>
          <Card className="border border-primary">
            <div className="card-header bg-transparent border-primary">
              <h5 className="my-0 text-primary">
                <i className="mdi mdi-alert-circle-outline me-3"></i>
                QuickBooks Disconnected
              </h5>
            </div>
            <CardBody>
              <div className="card-text">
                <div className="mb-3">
                  Your QuickBooks integration has been disconnected. You will no
                  longer be able to sync your Lopay sales and payments data with
                  QuickBooks.
                </div>
                <div className="mb-3">
                  If you would like to re-connect Lopay and your QuickBooks
                  account, visit our{' '}
                  <a
                    href="https://support.lopay.com/en/articles/8124107-integrate-lopay-with-quickbooks"
                    target="_blank"
                    rel="noreferrer"
                  >
                    help guide here
                  </a>
                  .
                </div>
                <div>
                  For general Lopay support, contact us at{' '}
                  <a href="mailto:support@lopay.com">support@lopay.com</a>.
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </Container>
  )
}

export default QuickBooksDisconnectedPage
