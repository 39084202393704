import { useMutation, useQuery } from 'react-query'
import {
  getFraudRules,
  getKybThresholds,
  toggleFraudRuleStatus,
} from './fraud-framework.api'

export function useFraudRules() {
  return useQuery({
    queryKey: ['fraudRules'],
    queryFn: () => getFraudRules(),
  })
}

export function useKybThresholds() {
  return useQuery({
    queryKey: ['kybThresholds'],
    queryFn: () => getKybThresholds(),
  })
}

export function useToggleFraudRuleStatusMutation(refetchFn: () => void) {
  return useMutation(
    (payload: { enable?: string[]; disable?: string[] }) => {
      return toggleFraudRuleStatus(payload)
    },
    {
      onSuccess: async () => refetchFn(),
    },
  )
}
