import { PostRequest } from '../../../../../helpers/lopay_api_helper'

export function setBlockMaintained(
  merchantId: string,
  isBlockMaintained: boolean,
): Promise<void> {
  return PostRequest(`support/merchant/${merchantId}/kyc/block-maintained`, {
    isBlockMaintained,
  })
}
