import { ProfileTypes } from './actionTypes'

const initialState = {
  error: '',
  success: '',
  profile: null,
}

const profile = (state = initialState, action: any) => {
  switch (action.type) {
    case ProfileTypes.FETCH_PROFILE:
      state = { ...state }
      break
    case ProfileTypes.FETCH_PROFILE_SUCCESS:
      state = { ...state, profile: action.payload }
      break

    case ProfileTypes.UPDATE_EDIT_PROFILE:
      state = { ...state }
      break
    case ProfileTypes.UPDATE_PROFILE_SUCCESS:
      state = { ...state, success: action.payload }
      break
    case ProfileTypes.UPDATE_PROFILE_ERROR:
      state = { ...state, error: action.payload }
      break
    case ProfileTypes.UPDATE_RESET_PROFILE_FLAG:
      state = { ...state, success: '' }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
