import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components'

import { FormattedChallengeSection } from '../support-merchant-reward-challenges.type'
import { Card, CardBody } from 'reactstrap'
import { priceFormat } from 'src/helpers/utils'
import moment from 'moment'

const StyledLine = styled.li`
  margin-bottom: 15px;
`

const StyledItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledItemCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledItemDescriptionContainer = styled.div`
  margin-top: 5px;
`

type IComponentProps = {
  section: FormattedChallengeSection
}

const RewardChallengeSection: React.FC<IComponentProps> = ({ section }) => {
  const isCompleted = section.completedAt && section.reward?.claimedAt

  const cardStyles = classNames({
    border: true,
    'w-50': true,
    'border-info': !isCompleted,
    'border-success': isCompleted,
  })

  const cardHeaderContainerStyles = classNames({
    'card-header': true,
    'bg-transparent': true,
    'border-info': !isCompleted,
    'border-success': isCompleted,
  })

  const cardHeaderStyles = classNames({
    'my-0': true,
    'text-info': !isCompleted,
    'text-success': isCompleted,
  })

  const cardRewardBody = classNames({
    'bg-transparent': true,
    'border-info': !isCompleted,
    'border-success': isCompleted,
    'text-info': !isCompleted,
    'text-success': isCompleted,
  })

  return (
    <Card className={cardStyles}>
      <div className={cardHeaderContainerStyles}>
        <h5 className={cardHeaderStyles}>{section.title}</h5>
        <p className={cardHeaderStyles}>{section.description}</p>
      </div>
      <CardBody>
        <ol>
          {section.steps.map((step) => (
            <StyledLine key={step.id}>
              <StyledItemContainer>
                <div>
                  <b>{step.title}</b>
                  <StyledItemDescriptionContainer>
                    {step.action?.appSection && (
                      <div>{`App section: ${step.action.appSection}`}</div>
                    )}
                    {step.action?.url && (
                      <div>
                        <a
                          href={step.action.url}
                          rel="noreferrer"
                          target="_blank"
                        >
                          Survey link
                        </a>
                      </div>
                    )}
                    {step.availableFrom && (
                      <div>
                        Available from{' '}
                        {moment(step.availableFrom)
                          .local()
                          .format('DD-MM-YY hh:mm')}
                      </div>
                    )}
                    {step.completedAt && (
                      <div>
                        Completed on{' '}
                        {moment(step.completedAt)
                          .local()
                          .format('DD-MM-YY hh:mm')}
                      </div>
                    )}
                  </StyledItemDescriptionContainer>
                </div>
                <StyledItemCheckboxContainer>
                  {step.completedAt && (
                    <i className={`fas fa-check-circle ${cardRewardBody}`}></i>
                  )}
                </StyledItemCheckboxContainer>
              </StyledItemContainer>
            </StyledLine>
          ))}
        </ol>
      </CardBody>
      {section.reward && (
        <CardBody className={`border-top ${cardRewardBody}`}>
          <p className="card-text">
            <strong>Reward value of {priceFormat(section.reward.value)}</strong>
          </p>
          {section.reward.claimedAt && (
            <p className="card-text">
              <strong>
                Claimed on{' '}
                {moment(section.reward.claimedAt)
                  .local()
                  .format('DD-MM-YY hh:mm')}
              </strong>
            </p>
          )}
        </CardBody>
      )}
    </Card>
  )
}

export default RewardChallengeSection
