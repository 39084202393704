import { KYBStatus } from '../../../../FraudPrevention/kyb-threshold.type'
import React, { useState } from 'react'
import { Col, Label, Row } from 'reactstrap'
import { useUpdateKYBStatusMutation } from '../../hooks/kyb-settings.hook'

type KYBStatusSelectorLightProps = {
  merchantId: string
  kybStatus: KYBStatus
  refetchFn: (kybStatus?: KYBStatus) => void
}

const KYBStatusSelectorLight = (props: KYBStatusSelectorLightProps) => {
  const { merchantId, kybStatus, refetchFn } = props

  const mutation = useUpdateKYBStatusMutation(merchantId, refetchFn)

  const [status, setStatus] = useState<KYBStatus>(kybStatus)

  const kybChoices: Array<{ value: KYBStatus; label: string }> = [
    { value: 'approved', label: 'Approved' },
    { value: 'unrestrictedOther', label: 'Unrestricted Other' },
    { value: 'unrestrictedNew', label: 'Unrestricted New' },
    { value: 'restricted', label: 'Restricted' },
    { value: 'blocked', label: 'Blocked' },
  ]

  const handleSubmit = () => {
    mutation.mutate({
      kybStatus: status,
    })
  }

  return (
    <Row className="mx-0">
      <Label className="px-0">Internal KYB Status</Label>
      <Col sm={10} lg={10} className="px-0">
        <select
          className="form-select"
          value={status}
          disabled={mutation.isLoading}
          onChange={(e) => {
            setStatus(e.target.value as KYBStatus)
          }}
        >
          {kybChoices.map((o) => (
            <option value={o.value}>{o.label}</option>
          ))}
        </select>
      </Col>
      <Col sm={2} lg={2}>
        <button
          className="btn btn-primary py-0 pe-2 px-1 d-flex align-items-center justify-content-center"
          onClick={handleSubmit}
          disabled={mutation.isLoading || kybStatus === status}
        >
          {mutation.isLoading ? (
            <i className="mdi mdi-loading mdi-spin font-size-24"></i>
          ) : (
            <i className="mdi mdi-content-save font-size-24"></i>
          )}
          <span className="ms-1">Save</span>
        </button>
      </Col>
    </Row>
  )
}

export default KYBStatusSelectorLight
