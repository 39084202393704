import React, { useState } from 'react'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import PaymentRequestDto from '../../api-dtos/payment-requests/payment-request.dto.interface'
import CreatePaymentIntentRequest from '../../api-dtos/payment-intent/create-payment-intent.dto'
import { createPaymentIntent } from '../../helpers/lopay_api_helper'
import brand from '../../helpers/brand'
import { rgba } from 'polished'
import fonts from '../../helpers/fonts'
import { Col, Label, Input } from 'reactstrap'
import { priceFormat } from '../../helpers/utils'

const PaymentForm = (props: {
  link: PaymentRequestDto
  setupSecret: string
  paymentCompleted: () => void
}) => {
  const stripe = useStripe()
  const elements = useElements()

  const [paymentIsLoading, setPaymentIsLoading] = useState<boolean>(false)
  const [cardHolderName, setCardHolderName] = useState<string>('')

  // Stripe elements configuration options
  const elementsOptions = {
    style: {
      base: {
        iconColor: rgba('#000000', 0.5),
        color: brand.body,
        fontWeight: fonts.weights.light.toString(),
        fontFamily: fonts.family,
        fontSize: '14px',
        '::placeholder': { color: rgba('#000000', 0.5) },
      },
      invalid: {
        iconColor: brand.validationError,
        color: brand.validationError,
      },
    },
  }

  const makePayment = async (link: PaymentRequestDto) => {
    if (paymentIsLoading) {
      return
    }

    if (!link) {
      return
    }

    if (!stripe || !elements) {
      alert('Stripe is not yet ready')
      return
    }

    const cardElement = elements.getElement(CardNumberElement)
    if (!cardElement) {
      alert('Card not ready')
      return
    }

    // Create payment method config
    const paymentMethodConfig = {
      card: cardElement,
      billing_details: {
        name: cardHolderName,
      },
    }

    // Create payment method object with stripe
    const { paymentMethod, error: paymentMethodError } =
      await stripe.createPaymentMethod({
        ...paymentMethodConfig,
        type: 'card',
      })

    setPaymentIsLoading(true)
    console.log('paymentMethod:', paymentMethod)

    if (paymentMethodError) {
      console.log(paymentMethodError)
      alert('Sorry, your payment could not be completed')
      setPaymentIsLoading(false)
      return
    }

    if (!paymentMethod) {
      alert('Payment method is not ready')
      setPaymentIsLoading(false)
      return
    }

    console.log('payment link:', link)

    const paymentDetails: CreatePaymentIntentRequest = {
      amount: link.amount,
      paymentRequestId: link.id,
      stripePaymentMethodId: paymentMethod.id,
      merchantId: link.merchantId,
    }

    const opPaymentIntent = await createPaymentIntent(paymentDetails)

    console.log('opPaymentIntent:', opPaymentIntent)

    // Confirm the PaymentIntent without handling potential next actions (yet).
    const { paymentIntent, error: confirmError } =
      await stripe.confirmCardPayment(
        opPaymentIntent.clientSecret,
        { payment_method: paymentMethod.id },
        { handleActions: true },
      )

    if (confirmError) {
      alert('Sorry, your payment could not be completed')
      setPaymentIsLoading(false)
      return
    }

    // Check if the PaymentIntent requires any actions
    if (paymentIntent && paymentIntent.status === 'requires_action') {
      // Let Stripe.js handle the rest of the payment flow
      const { error } = await stripe.confirmCardPayment(props.setupSecret)
      if (error) {
        alert(error.message || 'Error with payment')
        setPaymentIsLoading(false)
        return
      }
    }

    alert('Payment processed')
    setPaymentIsLoading(false)
    props.paymentCompleted()
  }

  return (
    <React.Fragment>
      <form className="row gx-3 gy-2 align-items-center">
        {paymentIsLoading ? (
          <>Connecting to the bank...</>
        ) : (
          <>
            <div className="mb-3">
              <Label htmlFor="example-text-input" className="form-Label">
                Card Holder Name
              </Label>
              <Input
                className="form-control"
                type="text"
                placeholder="Enter card holder name"
                value={cardHolderName}
                onChange={(e) => setCardHolderName(e.target.value)}
                id="company-name-text-input"
                validate={{ required: { value: true } }}
              />
            </div>

            <Col sm={6}>
              <CardNumberElement options={elementsOptions} />
            </Col>
            <Col sm={3}>
              <CardExpiryElement options={elementsOptions} />
            </Col>
            <Col sm={3}>
              <CardCvcElement options={elementsOptions} />
            </Col>
          </>
        )}

        <button
          onClick={async () => {
            await makePayment(props.link)
          }}
          className="btn btn-primary w-100 waves-effect waves-light mt-4"
          type="button"
        >
          {paymentIsLoading
            ? `Processing payment for ${priceFormat(props.link.amount)}`
            : `Make Payment for ${priceFormat(props.link.amount)}`}
        </button>
      </form>
    </React.Fragment>
  )
}

export default PaymentForm
