import { Card, CardHeader, CardTitle } from 'reactstrap'
import React from 'react'
import SupportMerchantApiDto from '../../../../../api-dtos/support/merchant/support-merchant-api.dto.interface'

type LinkedMerchantsProps = {
  merchant: SupportMerchantApiDto
  history: any
}

/**
 * Component handling the display of merchants whose owner is the same as the given merchant's.
 *
 * @param merchant - The merchant whose owner's other merchants we are finding.
 * @param history - The React router dom history entity.
 */
const LinkedMerchants = ({ merchant, history }: LinkedMerchantsProps) => {
  const owner = merchant?.associatedUsers?.find((user) => user.isOwner)
  const sameOwnerMerchants =
    owner?.merchants.filter((m) => m.id !== merchant.id) || []

  return (
    <Card>
      <CardHeader>
        <CardTitle className="h4">Linked Merchants</CardTitle>
        <p className="card-title-desc">
          {sameOwnerMerchants.length === 0 ? (
            <>No other merchants have been created by this user</>
          ) : (
            <>
              These merchants are associated with the owner of {merchant.name}
            </>
          )}
        </p>
      </CardHeader>

      <ul className="list-group list-group-flush">
        {sameOwnerMerchants.map((linkedMerchant) => {
          return (
            <li className="list-group-item" key={linkedMerchant.id}>
              <button
                className="btn btn-link"
                onClick={() => {
                  /**
                   * `.push` didn't seem to work by itself, and neither did `.go`...
                   * But together, they seem to redirect properly!
                   */
                  history.push('/merchant/' + linkedMerchant?.id)
                  history.go()
                }}
              >
                {linkedMerchant.name}
              </button>
            </li>
          )
        })}
      </ul>
    </Card>
  )
}

export default LinkedMerchants
