import { takeEvery, fork, put, all } from 'redux-saga/effects'

// Login Redux States
import { ForgetPwdTypes } from './actionTypes'
import { userForgetPasswordSuccess, userForgetPasswordError } from './actions'

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }: any) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      // const response: Promise<any> = yield call(fireBaseBackend.forgetPassword, user.email)
      yield put(
        userForgetPasswordSuccess(
          'Reset link are sended to your mailbox, check there first',
        ),
      )
    } else if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
      /*
      const response: Promise<any> = yield call(postJwtForgetPwd, {
        email: user.email,
      })
      */
      yield put(
        userForgetPasswordSuccess(
          'Reset link are sended to your mailbox, check there first',
        ),
      )
    } else {
      /*
      const response: Promise<any> = yield call(postFakeForgetPwd, {
        email: user.email,
      })
      */
      yield put(
        userForgetPasswordSuccess(
          'Reset link are sended to your mailbox, check there first',
        ),
      )
    }
  } catch (error) {
    yield put(userForgetPasswordError(error))
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(ForgetPwdTypes.FORGET_PASSWORD, forgetUser)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
