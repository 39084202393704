import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

// Login Redux States
import { LoginTypes } from './actionTypes'
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  sendMagicLinkSuccess,
} from './actions'

//Include Both Helper File with needed methods
import { getFirebaseBackend } from '../../../helpers/firebase_helper'
import {
  getLoggedInSupportUser,
  postSocialLogin,
  requestOTP,
  validateOTP,
} from '../../../helpers/lopay_api_helper'

const fireBaseBackend = getFirebaseBackend()

function* sendMagicLink({ payload: { email } }: any) {
  try {
    const response: { userId: string } = yield call(requestOTP, email)

    console.log('send magic link response:', response)

    yield put(sendMagicLinkSuccess(response.userId))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* loginUser({
  payload: { userId, otp, history },
}: {
  payload: {
    userId: string
    otp: string
    history: any
  }
  type: LoginTypes
}) {
  try {
    const response: Promise<any> = yield call(validateOTP, userId, otp)

    console.log('validate OTP response:', response)

    localStorage.setItem('authUser', JSON.stringify(response))
    yield put(loginSuccess(response))

    // TODO: string onboarding - in dash or new screen?
    history.push('/dashboard')
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }: any) {
  try {
    const supportUser = getLoggedInSupportUser()
    /**
     * Logout both support user and normal user
     */
    localStorage.removeItem('authUser')
    localStorage.removeItem('authSupportUser')

    if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
      const response: Promise<any> = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }

    if (supportUser?.auth?.accessToken) {
      history.push('/login-support')
    } else {
      history.push('/login')
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }: any) {
  try {
    console.log('signign in...')
    const response: Promise<any> = yield call(postSocialLogin, data)

    console.log('response:', response)

    localStorage.setItem('authSupportUser', JSON.stringify(response))
    yield put(loginSuccess(response))

    history.push('/dashboard')
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LoginTypes.SEND_MAGIC_LINK, sendMagicLink)
  yield takeEvery(LoginTypes.LOGIN_USER, loginUser)
  yield takeLatest(LoginTypes.SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LoginTypes.LOGOUT_USER, logoutUser)
}

export default authSaga
