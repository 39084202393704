import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import { getLoggedInUser, getLoggedInSupportUser } from './lopay_api_helper'

//apply base url for axios
export const API_URL = process.env.REACT_APP_API_HOST

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.request.use((config) => {
  const user = getLoggedInUser()
  const supportUser = getLoggedInSupportUser()

  let currentRegion: string | undefined
  if (supportUser) {
    currentRegion = localStorage.getItem('REGION') || 'region_eu'
  } else if (user) {
    currentRegion = user.usersRegion
  }

  if (currentRegion) {
    config.headers['x-region'] = currentRegion
  }

  if (user?.auth?.accessToken) {
    config.headers['Authorization'] = `Bearer ${user?.auth.accessToken}`
  }

  if (supportUser?.auth?.accessToken) {
    console.log('supportUser:', supportUser)
    config.headers['x-support-jwt-auth'] = `${supportUser?.auth.accessToken}`
  }

  return config
})

// Disable caching
axiosApi.defaults.headers.common['Cache-Control'] = 'no-cache'
axiosApi.defaults.headers.common['Pragma'] = 'no-cache'
axiosApi.defaults.headers.common['Expires'] = '0'

// Register the users browser as a "device"
let deviceId = window.localStorage.getItem('deviceId')
let deviceName = window.localStorage.getItem('deviceName')

if (!deviceName || !deviceId) {
  deviceName = navigator.userAgent
  deviceId = uuidv4()
  window.localStorage.setItem('deviceName', deviceName)
  window.localStorage.setItem('deviceId', deviceId)
}

axiosApi.defaults.headers.common['x-device-identifier'] = deviceId
axiosApi.defaults.headers.common['x-device-name'] = deviceName

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
)

export function axiosInstance() {
  return axiosApi
}

export async function get(url: string, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data)
}

export async function post(url: string, data: any, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data)
}

export async function put(url: string, data: any, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data)
}

export async function del(url: string, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data)
}
