import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
  Table,
  Col,
  Card,
  Input,
  CardBody,
  CardTitle,
  Row,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  Alert,
  Label,
} from 'reactstrap'
import MerchantApiDto from '../../../api-dtos/merchant/merchant.dto.interface'
import SupportUserApiDto from '../../../api-dtos/support/user/support-user-api.dto.interface'
import {
  checkIfWorkingCapitalEnabled,
  supportGetWorkingCapitalCashAdvances,
  supportGetWorkingCapitalQuote,
  supportSubmitCashAdvanceApplication,
  toggleWorkingCapitalEnabled,
} from '../../../helpers/lopay_api_helper'
import { priceFormat } from '../../../helpers/utils'
import WorkingCapitalCashAdvanceApplicationDto from './dtos/working-capital-cash-advance-application-body.dto.interface'
import WorkingCapitalCashAdvanceDto from './dtos/working-capital-cash-advance.dto.interface'
import WorkingCapitalQuoteDto from './dtos/working-capital-quote.dto.interface'

const MerchantWorkingCapital = (props: {
  merchant: MerchantApiDto
  owner: SupportUserApiDto
}) => {
  useEffect(() => {
    if (props.owner) {
      loadQuoteForUser(props.owner.id)
      loadCashAdvancesForUser(props.owner.id)
      loadIsEnabledCheckbox()
    }
    // eslint-disable-next-line
  }, [props.merchant])

  const [selectedPaybackRate, setSelectedPaybackRate] = useState<string>('0.2')

  // If Lopay capital is enabled for this merchant
  const [workingCapitalEnabled, setWorkingCapitalEnabled] = useState<
    boolean | undefined
  >()
  const [
    loadingUpdatingWorkingCapitalEnabled,
    setLoadingUpdatingWorkingCapitalEnabled,
  ] = useState<boolean | undefined>()

  // Quote state
  const [workingCapitalQuote, setWorkingCapitalQuote] =
    useState<WorkingCapitalQuoteDto>()
  const [workingCapitalQuoteError, setWorkingCapitalQuoteError] = useState<
    string | undefined
  >()
  const [loadingQuote, setLoadingQuote] = useState<boolean>(false)

  // Historical cash advances
  const [cashAdvances, setCashAdvances] = useState<{
    cashAdvances: WorkingCapitalCashAdvanceDto[]
  }>({ cashAdvances: [] })
  const [cashAdvanceError, setCashAdvanceError] = useState<string | undefined>()
  const [loadingCashAdvances, setLoadingCashAdvances] = useState<boolean>(false)

  // Make an application state:
  const getDefaultApplicationState =
    (): WorkingCapitalCashAdvanceApplicationDto => ({
      payBackPercentageThousandths: 200,
      feePercentageThousandths: 0,
      cashAdvanced: {
        currencyCode: 'GBP',
        units: 0,
      },
      totalRepayment: {
        currencyCode: 'GBP',
        units: 0,
      },
      fee: {
        currencyCode: 'GBP',
        units: 0,
      },
      termsAcceptedAt: new Date(),
      merchantId: props.merchant?.id,
    })

  const [cashAdvanceApplicationPopup, setCashAdvanceApplicationPopup] =
    useState<boolean>(false)
  const [acknowledgedNewCashAdvance, setAcknowledgedNewCashAdvance] =
    useState<boolean>(false)

  const [cashAdvanceApplicationError, setCashAdvanceApplicationError] =
    useState<string | undefined>()
  const [loadingCashAdvanceApplication, setLoadingCashAdvanceApplication] =
    useState<boolean>(false)
  const [cashAdvanceApplication, setCashAdvanceApplication] =
    useState<WorkingCapitalCashAdvanceApplicationDto>(
      getDefaultApplicationState(),
    )

  const paybackRateChoices: {
    value: number
    label: string
  }[] = [
    {
      value: 0.1,
      label: '10%',
    },
    {
      value: 0.2,
      label: '20%',
    },
    {
      value: 0.3,
      label: '30%',
    },
  ]

  const applicationFormIsValid = () => {
    // TODO: Cash advance validation
    return true
  }

  const loadIsEnabledCheckbox = async () => {
    setLoadingUpdatingWorkingCapitalEnabled(true)

    const result = await checkIfWorkingCapitalEnabled(props.merchant.id)

    setWorkingCapitalEnabled(result.enabled)

    setLoadingUpdatingWorkingCapitalEnabled(false)
  }

  const loadQuoteForUser = async (userId: string) => {
    setLoadingQuote(true)
    setWorkingCapitalQuoteError(undefined)
    try {
      const quote = await supportGetWorkingCapitalQuote(
        userId,
        props.merchant.id,
      )
      setWorkingCapitalQuote(quote)

      // Update the cash advance application state base don the quote values:

      const feeUnits = Math.round(
        quote.suggestedCapital.units * quote.quote20Percent,
      )

      setCashAdvanceApplication({
        ...cashAdvanceApplication,
        cashAdvanced: quote.suggestedCapital,
        feePercentageThousandths: quote.quote20Percent * 100000,
        fee: {
          ...cashAdvanceApplication.fee,
          units: feeUnits,
        },
        totalRepayment: {
          ...cashAdvanceApplication.totalRepayment,
          units: feeUnits + quote.suggestedCapital.units,
        },
      })
    } catch (error: any) {
      setWorkingCapitalQuoteError(error?.message || error)
    }
    setLoadingQuote(false)
  }

  const loadCashAdvancesForUser = async (userId: string) => {
    setLoadingCashAdvances(true)
    setCashAdvanceError(undefined)
    try {
      setCashAdvances(await supportGetWorkingCapitalCashAdvances(userId))
    } catch (error: any) {
      setCashAdvanceError(error?.message || error)
    }
    setLoadingCashAdvances(false)
  }

  const applyForWorkingCapital = async (quoteId: string) => {
    setLoadingCashAdvanceApplication(true)
    setCashAdvanceApplicationError(undefined)

    const augmentedCashAdvanceApplication = {
      ...cashAdvanceApplication,
      payBackPercentageThousandths:
        cashAdvanceApplication.payBackPercentageThousandths * 100,
    }

    console.log('cashAdvanceApplication:', augmentedCashAdvanceApplication)

    let didError = false

    const result = await supportSubmitCashAdvanceApplication(
      quoteId,
      augmentedCashAdvanceApplication,
    ).catch((error: any) => {
      didError = true
      setCashAdvanceApplicationError(error?.message || error)
    })

    console.log('result:', result)

    if (!didError) {
      // Refresh application state
      setCashAdvanceApplication(getDefaultApplicationState())

      // close the popup
      setCashAdvanceApplicationPopup(false)

      // Load list of cash advances
      loadCashAdvancesForUser(props.owner.id)
    }

    setLoadingCashAdvanceApplication(false)
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle>
            <b>Working Capital Status</b>
          </CardTitle>

          <div>
            <div>
              {workingCapitalQuote?.cashAdvance ? (
                <></>
              ) : (
                <Alert color="secondary">No active cash advances</Alert>
              )}

              <div className="form-check">
                <input
                  onChange={async (e) => {
                    const newValue = !workingCapitalEnabled

                    setWorkingCapitalEnabled(newValue)

                    setLoadingUpdatingWorkingCapitalEnabled(true)

                    await toggleWorkingCapitalEnabled(
                      props.merchant.id,
                      newValue,
                    )

                    setLoadingUpdatingWorkingCapitalEnabled(false)
                  }}
                  checked={workingCapitalEnabled}
                  className="form-check-input"
                  type="checkbox"
                  id="capabilities-working-capital"
                />
                <label
                  className="form-check-label"
                  htmlFor="capabilities-working-capital"
                >
                  {loadingUpdatingWorkingCapitalEnabled && (
                    <b>[Updating...] </b>
                  )}
                  Lopay Capital is{' '}
                  {workingCapitalEnabled ? 'enabled' : 'not enabled'}
                </label>
              </div>
            </div>

            {workingCapitalQuoteError && (
              <Alert color="danger">{workingCapitalQuoteError}</Alert>
            )}
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>
            <b>Working Capital Quote</b>
          </CardTitle>

          <div>
            {loadingQuote && <Alert color="warning">Loading quote...</Alert>}

            {workingCapitalQuote && !workingCapitalQuote.cashAdvance && (
              <>
                <div className="mb-4">
                  <p className="card-title-desc mb-2">
                    This person can borrow between{' '}
                    {priceFormat(workingCapitalQuote.minimumCapital)} and{' '}
                    {priceFormat(workingCapitalQuote.maximumCapital)}
                  </p>
                  <div className="mt-4">
                    <Row className="mb-4 mt-2">
                      <Label
                        htmlFor="horizontal-firstname-input"
                        className="col-sm-3 col-form-label"
                      >
                        Amount to borrow
                      </Label>
                      <Col sm={3}>
                        <div className="input-group">
                          <div className="input-group-text">Cash Advance £</div>
                          <Input
                            type="text"
                            className="form-control"
                            id="cash-advance-amount"
                            value={
                              cashAdvanceApplication.cashAdvanced.units / 100
                            }
                            placeholder="0.00"
                            onChange={(e) => {
                              const cashAdvanceUnits = Math.round(
                                parseFloat(e.target.value) * 100,
                              )

                              const getFreeRate = () => {
                                if (
                                  cashAdvanceApplication.payBackPercentageThousandths ===
                                  100
                                ) {
                                  return workingCapitalQuote.quote10Percent
                                }
                                if (
                                  cashAdvanceApplication.payBackPercentageThousandths ===
                                  200
                                ) {
                                  return workingCapitalQuote.quote20Percent
                                }
                                if (
                                  cashAdvanceApplication.payBackPercentageThousandths ===
                                  300
                                ) {
                                  return workingCapitalQuote.quote30Percent
                                }
                                throw new Error(
                                  `Unrecognized payback percentage: ${cashAdvanceApplication.payBackPercentageThousandths}`,
                                )
                              }

                              const feeUnits = Math.round(
                                getFreeRate() * cashAdvanceUnits,
                              )

                              setCashAdvanceApplication({
                                ...cashAdvanceApplication,
                                cashAdvanced: {
                                  ...cashAdvanceApplication.cashAdvanced,
                                  units: cashAdvanceUnits,
                                },
                                fee: {
                                  ...cashAdvanceApplication.fee,
                                  units: feeUnits,
                                },
                                totalRepayment: {
                                  ...cashAdvanceApplication.totalRepayment,
                                  units: feeUnits + cashAdvanceUnits,
                                },
                              })
                            }}
                          />
                        </div>
                      </Col>
                      <Col sm={3}>
                        <Input
                          type="range"
                          className="form-range"
                          min={workingCapitalQuote.minimumCapital.units}
                          max={workingCapitalQuote.maximumCapital.units}
                          value={cashAdvanceApplication.cashAdvanced.units}
                          id="customRange2"
                          onChange={(e) => {
                            const cashAdvanceUnits = parseFloat(e.target.value)

                            const getFreeRate = () => {
                              if (
                                cashAdvanceApplication.payBackPercentageThousandths ===
                                100
                              ) {
                                return workingCapitalQuote.quote10Percent
                              }
                              if (
                                cashAdvanceApplication.payBackPercentageThousandths ===
                                200
                              ) {
                                return workingCapitalQuote.quote20Percent
                              }
                              if (
                                cashAdvanceApplication.payBackPercentageThousandths ===
                                300
                              ) {
                                return workingCapitalQuote.quote30Percent
                              }
                              throw new Error(
                                `Unrecognized payback percentage: ${cashAdvanceApplication.payBackPercentageThousandths}`,
                              )
                            }

                            const feeUnits = Math.round(
                              getFreeRate() * cashAdvanceUnits,
                            )

                            setCashAdvanceApplication({
                              ...cashAdvanceApplication,
                              cashAdvanced: {
                                ...cashAdvanceApplication.cashAdvanced,
                                units: cashAdvanceUnits,
                              },
                              fee: {
                                ...cashAdvanceApplication.fee,
                                units: feeUnits,
                              },
                              totalRepayment: {
                                ...cashAdvanceApplication.totalRepayment,
                                units: feeUnits + cashAdvanceUnits,
                              },
                            })
                          }}
                        />
                      </Col>
                    </Row>

                    <Row className="mb-4 mt-2">
                      <Label
                        htmlFor="horizontal-firstname-input"
                        className="col-sm-3 col-form-label"
                      >
                        Payback rate (%)
                      </Label>
                      <Col sm={3}>
                        <select
                          value={selectedPaybackRate}
                          className="form-select"
                          onChange={(e) => {
                            console.log(
                              'Payback rate e.target:',
                              e.target.value,
                            )

                            // Update the application:
                            let feePercentage = 0
                            switch (e.target.value) {
                              case '0.1':
                                setSelectedPaybackRate('0.1')
                                feePercentage =
                                  workingCapitalQuote.quote10Percent
                                break
                              case '0.2':
                                setSelectedPaybackRate('0.2')
                                feePercentage =
                                  workingCapitalQuote.quote20Percent
                                break
                              case '0.3':
                                setSelectedPaybackRate('0.3')
                                feePercentage =
                                  workingCapitalQuote.quote30Percent
                                break
                              default:
                                throw new Error(
                                  `Unrecognized payback percentage: ${e.target.value}`,
                                )
                            }

                            const feeUnits = Math.round(
                              feePercentage *
                                cashAdvanceApplication.cashAdvanced.units,
                            )

                            setCashAdvanceApplication({
                              ...cashAdvanceApplication,
                              feePercentageThousandths: feePercentage * 100000,
                              payBackPercentageThousandths:
                                parseFloat(e.target.value) * 1000,
                              fee: {
                                ...cashAdvanceApplication.fee,
                                units: feeUnits,
                              },
                              totalRepayment: {
                                ...cashAdvanceApplication.totalRepayment,
                                units:
                                  feeUnits +
                                  cashAdvanceApplication.cashAdvanced.units,
                              },
                            })
                          }}
                        >
                          {paybackRateChoices.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </Col>
                    </Row>
                  </div>

                  <Row className="mb-4 mt-2">
                    <Col sm={3}></Col>

                    <Col sm={3}>
                      <div className="input-group">
                        <div className="input-group-text">Fee £</div>
                        <Input
                          type="text"
                          className="form-control"
                          disabled
                          id="cash-advance-fee"
                          value={cashAdvanceApplication.fee.units / 100}
                          placeholder="0.00"
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={3}></Col>
                    <Col sm={3}>
                      <div className="input-group">
                        <div className="input-group-text">Repayable £</div>
                        <Input
                          type="text"
                          className="form-control"
                          disabled
                          id="cash-advance-repayable"
                          value={
                            cashAdvanceApplication.totalRepayment.units / 100
                          }
                          placeholder="0.00"
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={3}></Col>
                    <Col sm={3}>
                      <button
                        type="button"
                        className="btn btn-primary w-md mt-4"
                        onClick={async (e) => {
                          setCashAdvanceApplicationPopup(true)
                        }}
                      >
                        {'Apply for cash advance'}
                      </button>
                    </Col>
                  </Row>
                </div>
              </>
            )}

            {workingCapitalQuoteError && (
              <Alert color="danger">{workingCapitalQuoteError}</Alert>
            )}
          </div>
        </CardBody>
      </Card>

      <div className="table-responsive">
        <Table className="table table-striped mb-0">
          <thead>
            <tr>
              <th>Date</th>
              <th>Cash Advanced</th>
              <th>Fee</th>
              <th>Total Repayable</th>
              <th>Total Repaid</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          {loadingCashAdvances ? (
            <tbody>
              <tr>
                <th>Loading...</th>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {cashAdvances.cashAdvances?.map((cashAdvance) => {
                return (
                  <tr key={cashAdvance.id}>
                    <td>
                      <span className="time">
                        {moment(new Date(cashAdvance.createdAt)).format(
                          'MMM Do h:mm:ss a',
                        )}
                      </span>
                    </td>
                    <th scope="row">{priceFormat(cashAdvance.cashAdvanced)}</th>
                    <th scope="row">{priceFormat(cashAdvance.fee)}</th>
                    <th scope="row">
                      {priceFormat(cashAdvance.totalRepayment)}
                    </th>
                    <th scope="row">{priceFormat(cashAdvance.totalRepaid)}</th>
                    <th scope="row">
                      <Badge className="me-2 bg-primary">
                        {cashAdvance.status}
                      </Badge>
                    </th>
                    <td>[Edit]</td>
                  </tr>
                )
              })}
            </tbody>
          )}
        </Table>
      </div>

      <Modal isOpen={Boolean(cashAdvanceApplicationPopup)} scrollable={true}>
        <ModalHeader tag="h4">Apply for cash advance</ModalHeader>

        <ModalBody>
          <Row className="mb-4">
            <Alert color="primary">
              Caution: This will submit an application for a cash advance on the
              merchants behalf
            </Alert>
          </Row>

          {cashAdvanceError && (
            <Row className="mb-4">
              <Alert color="primary">{cashAdvanceError}</Alert>
            </Row>
          )}

          <Row className="mb-4">
            <Col>
              <div className="form-check mb-3">
                <input
                  disabled={!applicationFormIsValid()}
                  className="form-check-input"
                  type="checkbox"
                  id="formCheck1"
                  checked={acknowledgedNewCashAdvance}
                  onChange={() =>
                    setAcknowledgedNewCashAdvance(!acknowledgedNewCashAdvance)
                  }
                />
                <label className="form-check-label" htmlFor="formCheck1">
                  {applicationFormIsValid()
                    ? `Confirm the application of ${priceFormat(
                        cashAdvanceApplication.cashAdvanced,
                      )} with a fee of ${priceFormat(
                        cashAdvanceApplication.fee,
                      )} with a total of ${priceFormat(
                        cashAdvanceApplication.totalRepayment,
                      )}`
                    : 'Confirm application for cah advance'}
                </label>
              </div>
            </Col>
          </Row>

          {cashAdvanceApplicationError && (
            <Alert color="danger">{cashAdvanceApplicationError}</Alert>
          )}

          {workingCapitalQuote?.id && (
            <button
              type="button"
              disabled={!acknowledgedNewCashAdvance}
              className="btn btn-primary w-md"
              onClick={() => applyForWorkingCapital(workingCapitalQuote!.id)}
            >
              {loadingCashAdvanceApplication
                ? 'Loading...'
                : 'Submit application'}
            </button>
          )}

          <button
            type="button"
            className="btn btn-secondary w-md m-1"
            onClick={() => {
              setCashAdvanceApplicationPopup(false)
              setCashAdvanceApplication(getDefaultApplicationState())
            }}
          >
            Cancel
          </button>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default MerchantWorkingCapital
