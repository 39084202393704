import { GetRequest } from 'src/helpers/lopay_api_helper'
import { RewardActivityResponseDto } from './support-merchant-reward-activity.type'

export function getRewardActivity({
  merchantId,
  pageSize,
}: {
  merchantId: string
  pageSize?: number
}) {
  return function ({
    pageParam,
  }: {
    pageParam?: string
  }): Promise<RewardActivityResponseDto> {
    const [offsetId, offsetType] = pageParam
      ? pageParam.split(',')
      : [undefined, undefined]

    return GetRequest(`support/merchant/${merchantId}/rewards-activity`, {
      params: {
        ...(pageParam && { offsetId, offsetType }),
        ...(pageSize && { pageSize }),
      },
    })
  }
}
