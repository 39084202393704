import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'

import OrderDeliveryStatus from '../../api-dtos/types/order-delivery-status.type'

import { Link } from 'react-router-dom'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'

import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  CardHeader,
  Modal,
  Table,
  Container,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
import {
  listTerminalOrders,
  updateDeliveryStatus,
  updateTerminalSerialNumber,
} from '../../helpers/lopay_api_helper'
import TerminalOrderDto from '../../api-dtos/terminal-order/terminal-order.dto.interface'
import ReactTimeAgo from 'react-time-ago'

interface PageProps {
  history: any
}

try {
  TimeAgo.addDefaultLocale(en)
} catch (e) {
  console.error(e)
}

const TerminalShippingPage = ({ history }: PageProps) => {
  // state of dropdowns in table
  const [isDropdownOpen, setIsDropdownOpen] = useState<string | undefined>()

  // Searching of terminal
  const [terminalSearchQuery, setTerminalSearchQuery] = useState<
    string | undefined
  >()

  // Selected terminal (for modal)
  const [selectedTerminalOrder, setSelectedTerminalOrder] = useState<
    TerminalOrderDto | undefined
  >()
  const [selectedTerminalSerialNumber, setSelectedTerminalSerialNumber] =
    useState<string>('')
  const [terminalOrders, setTerminalOrders] = useState<TerminalOrderDto[]>([])

  // Available terminal order status's
  const orderStatuses: OrderDeliveryStatus[] = [
    'pending_placement',
    'order_submitted',
    'order_failed',
    'order_shipped',
    'order_delivered',
  ]
  const friendlyOrderStatusName = (status: OrderDeliveryStatus) => {
    switch (status) {
      case 'pending_placement':
        return 'Pending postage'
      case 'order_submitted':
        return 'Order submitted'
      case 'order_failed':
        return 'Order failed'
      case 'order_shipped':
        return 'Order shipped'
      case 'order_delivered':
        return 'Delivered'
      default:
        return status
    }
  }
  useEffect(() => {
    loadTerminalOrders()
    // eslint-disable-next-line
  }, [])

  const loadTerminalOrders = async () => {
    const { terminalOrders } = await listTerminalOrders(terminalSearchQuery)
    setTerminalOrders(terminalOrders)
  }

  const handleUpdateDeliveryStatus = async (
    orderId: string,
    status: OrderDeliveryStatus,
  ) => {
    await updateDeliveryStatus(orderId, status)
  }

  const openTerminalOrderInStripe = (providerId: string) => {
    const url = `https://dashboard.stripe.com/terminal/hardware_orders/${providerId}`
    window.open(url, '_blank')?.focus()
  }

  const openTerminalOrderInMintSoft = (providerId: string) => {
    const url = `https://om.mintsoft.co.uk/Order/Details/${providerId}`
    window.open(url, '_blank')?.focus()
  }

  const parseDate = (date: Date) => {
    return new Date(date)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Terminal Orders</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={'LoPay Support'}
            breadcrumbItem="Terminal Orders"
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <div className="row align-ite  ms-center">
                    <form
                      onSubmit={(e) => {
                        setSelectedTerminalOrder(undefined)
                        loadTerminalOrders()
                        e.preventDefault()
                      }}
                      className="row gx-3 gy-2 align-items-center"
                    >
                      <Col sm={5}>
                        <Label
                          className="visually-hidden"
                          htmlFor="specificSizeInputName"
                        >
                          Search
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="search input"
                          placeholder="Merchant id, thor id or address"
                          onChange={(e) =>
                            setTerminalSearchQuery(e.target.value)
                          }
                        />
                      </Col>
                      <div className="col-auto">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={(e) => {
                            setSelectedTerminalOrder(undefined)
                            loadTerminalOrders()
                            e.preventDefault()
                          }}
                        >
                          Search
                        </button>
                      </div>
                    </form>
                  </div>
                </CardHeader>
                <CardBody>
                  <div
                    className="table-responsive"
                    style={{
                      paddingBottom: 550,
                      marginBottom: -550,
                      msOverflowStyle: 'none',
                    }}
                  >
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Date Ordered</th>
                          <th>Delivery Address</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {terminalOrders.map((terminalOrder) => {
                          return (
                            <tr key={terminalOrder.id}>
                              <th
                                scope="row"
                                onClick={() => {
                                  setSelectedTerminalOrder(terminalOrder)
                                  setSelectedTerminalSerialNumber(
                                    terminalOrder.serialNumber,
                                  )
                                }}
                              >
                                <ReactTimeAgo
                                  date={parseDate(terminalOrder.createdAt)}
                                  locale="en-US"
                                />
                              </th>
                              <td
                                onClick={() => {
                                  setSelectedTerminalOrder(terminalOrder)
                                  setSelectedTerminalSerialNumber(
                                    terminalOrder.serialNumber,
                                  )
                                }}
                              >
                                {terminalOrder.merchant?.name && (
                                  <b>
                                    {terminalOrder.merchant?.name}
                                    <br />
                                  </b>
                                )}
                                {terminalOrder.delivery.address.street} <br />
                                {terminalOrder.delivery.address.town} <br />
                                {terminalOrder.delivery.address.postcode} <br />
                              </td>
                              <td>
                                <div className="btn-group">
                                  <ButtonDropdown
                                    isOpen={isDropdownOpen === terminalOrder.id}
                                    toggle={() => {
                                      if (isDropdownOpen === terminalOrder.id) {
                                        setIsDropdownOpen(undefined)
                                      } else {
                                        setIsDropdownOpen(terminalOrder.id)
                                      }
                                    }}
                                  >
                                    <Button id="caret" color="success">
                                      {friendlyOrderStatusName(
                                        terminalOrder.delivery.status,
                                      )}
                                    </Button>
                                    <DropdownToggle caret color="success">
                                      <i className="mdi mdi-chevron-down" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem header>
                                        Update delivery status
                                      </DropdownItem>
                                      {orderStatuses.map((status) => {
                                        const selected =
                                          status ===
                                          terminalOrder.delivery.status
                                        return (
                                          <DropdownItem
                                            onClick={() => {
                                              handleUpdateDeliveryStatus(
                                                terminalOrder.id,
                                                status,
                                              )
                                              terminalOrder.delivery.status =
                                                status
                                            }}
                                            active={selected}
                                            disabled={selected}
                                            key={status}
                                          >
                                            {friendlyOrderStatusName(status)}
                                          </DropdownItem>
                                        )
                                      })}
                                    </DropdownMenu>
                                  </ButtonDropdown>
                                </div>
                              </td>
                              <td>
                                <Link
                                  onClick={(e) => {
                                    setSelectedTerminalOrder(terminalOrder)
                                    setSelectedTerminalSerialNumber(
                                      terminalOrder.serialNumber,
                                    )
                                    e.preventDefault()
                                  }}
                                  to="#"
                                  className="btn btn-secondary"
                                >
                                  {!terminalOrder.serialNumber?.trim()?.length
                                    ? 'Set serial number'
                                    : `Paired to ${terminalOrder.serialNumber}`}
                                </Link>

                                <Link
                                  onClick={(e) => {
                                    if (!terminalOrder.providerId) {
                                      alert('This order has no provider id')
                                      return
                                    }
                                    if (
                                      terminalOrder.delivery.source ===
                                      'mintsoft'
                                    ) {
                                      openTerminalOrderInMintSoft(
                                        terminalOrder.providerId,
                                      )
                                    } else {
                                      openTerminalOrderInStripe(
                                        terminalOrder.providerId,
                                      )
                                    }
                                    e.preventDefault()
                                  }}
                                  to="#"
                                  className="btn btn-primary m-1"
                                >
                                  View in {terminalOrder.delivery.source}
                                </Link>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={!!selectedTerminalOrder}
            toggle={() => {
              setSelectedTerminalOrder(undefined)
            }}
            scrollable={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">
                {selectedTerminalOrder?.name}
              </h5>
              <button
                type="button"
                onClick={() => setSelectedTerminalOrder(undefined)}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <div className="mb-4">
                  <h5 className="font-size-14 mb-1">Terminal Serial Number</h5>
                  <p className="card-title-desc mb-2">
                    A serial number is required before shipping.
                  </p>

                  <hr />

                  <div>
                    <div className="mb-3">
                      <Label
                        htmlFor="example-text-input"
                        className="form-Label"
                      >
                        Serial Number
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="WPC32310500____"
                        onChange={(e) =>
                          setSelectedTerminalSerialNumber(e.target.value)
                        }
                        defaultValue={selectedTerminalSerialNumber}
                        id="example-text-input"
                      />
                      <p className="card-title-desc mb-2 mt-1">
                        {1 > 2 && ( // eslint-disable-next-line
                          <a
                            href="#"
                            onClick={() => {
                              setSelectedTerminalSerialNumber('WPC32310500')
                            }}
                          >
                            Click here
                          </a>
                        )}
                        to pre-populate the prefix.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={async () => {
                  if (!selectedTerminalOrder) {
                    return
                  }
                  await updateTerminalSerialNumber(
                    selectedTerminalOrder.id,
                    selectedTerminalSerialNumber,
                  )
                  setSelectedTerminalOrder(undefined)
                  loadTerminalOrders()
                }}
              >
                Update serial number
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TerminalShippingPage
