import { useInfiniteQuery } from 'react-query'
import { getRepaymentActivity } from './api/get-merchant-cash-advances.api'

export function useCashAdvanceRepaymentActivity({
  isActive,
  merchantId,
  pageSize,
}: {
  isActive: boolean
  merchantId: string
  pageSize?: number
}) {
  return useInfiniteQuery(
    ['repaymentActivity', { merchantId, pageSize }],
    getRepaymentActivity({ merchantId, pageSize }),
    {
      enabled: isActive,
      getNextPageParam: (lastPage) => {
        const nextOffsetId = lastPage.nextOffset?.id

        return nextOffsetId || undefined
      },
    },
  )
}
