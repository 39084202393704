import styled from 'styled-components'

const TimelineItemSubcontent = styled.p`
  font-size: 0.875rem;
  line-height: 1.25rem;
  white-space: nowrap;
  margin-bottom: 0;
  margin-top: 0.375rem;
  color: rgb(107, 114, 128);
`

export default TimelineItemSubcontent
