import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import { Row, Col, Alert, Container } from 'reactstrap'

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation'

// action
import { registerUser, apiError } from '../../store/actions'

//redux
import { useSelector, useDispatch } from 'react-redux'

import { Link } from 'react-router-dom'

// import images
import logo from '../../assets/images/logo-sm.svg'
// import CarouselPage from "../AuthenticationInner/CarouselPage";
import { UnregisteredUser } from '../../store/auth/register/types'
import CountryAPIResponse from '../../api-dtos/country/countries-response.dto.interface'
import { fetchCountries } from '../../helpers/lopay_api_helper'

import { Label, Input } from 'reactstrap'

interface RegisterProps {
  history: object
}

const Register = ({ history }: RegisterProps) => {
  const dispatch = useDispatch()

  const [countries, setCountries] = useState<CountryAPIResponse[]>()
  const [error, setError] = useState<string | null>()

  const [companyName, setCompanyName] = useState<string>('')
  const [country, setCountry] = useState<CountryAPIResponse>()

  const queryParams = new URLSearchParams(window.location.search)
  const email = queryParams.get('email')
  const firstName = queryParams.get('firstName')
  const lastName = queryParams.get('lastName')

  // If signing up via Xero:
  const xero_id = queryParams.get('xero_id')
  const xero_idToken = queryParams.get('xero_idtoken')
  const xero_authToken = queryParams.get('xero_authtoken')
  const xero_refresh = queryParams.get('xero_refresh')
  const xero_sessionState = queryParams.get('xero_sessionState')

  const isXeroSignUp =
    Boolean(xero_idToken) &&
    Boolean(xero_authToken) &&
    Boolean(xero_refresh) &&
    Boolean(xero_sessionState)

  const defaultValues = {
    email,
    firstName,
    lastName,
  }

  const { user, registrationError, loading } = useSelector((state: any) => ({
    user: state.register.user,
    registrationError: state.register.registrationError,
    loading: state.register.loading,
  }))

  // handleValidSubmit
  const handleValidSubmit = (values: UnregisteredUser) => {
    if (!country) {
      setError('Please select a county')
      handleFetchCountries()
      return
    }

    if (!companyName || !companyName.length) {
      setError('Please enter your company or trading name')
      return
    }

    if (!country) {
      setError('Please select your country')
      return
    }

    values.country = country.code
    values.companyName = companyName
    values.currencyCode = country.defaultCurrency.code

    values.xero_id = xero_id
    values.xero_idToken = xero_idToken
    values.xero_authToken = xero_authToken
    values.xero_refresh = xero_refresh
    values.xero_sessionState = xero_sessionState

    console.log('handleValidSubmit values: ', values)
    dispatch(registerUser(values, history))
  }

  const handleFetchCountries = async () => {
    const countries = await fetchCountries()
    setCountries(countries)
    if (countries.length) {
      setCountry(countries[0])
    }
  }

  useEffect(() => {
    handleFetchCountries()
    dispatch(apiError(''))
  }, [dispatch])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Register | Lopay</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={logo} alt="" height="28" />{' '}
                        <span className="logo-txt">Lopay</span>
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Register Account</h5>
                        <p className="text-muted mt-2">
                          {isXeroSignUp
                            ? 'Signing up with Xero'
                            : 'Get your Lopay account now.'}
                        </p>
                      </div>
                      <AvForm
                        className="needs-validation custom-form mt-4 pt-2"
                        model={defaultValues}
                        onValidSubmit={(e: any, v: any) => {
                          handleValidSubmit(v)
                        }}
                      >
                        {user && user ? (
                          <Alert color="success">
                            Register User Successfully
                          </Alert>
                        ) : null}

                        {registrationError && registrationError ? (
                          <Alert color="danger">{registrationError}</Alert>
                        ) : null}
                        {error ? <Alert color="danger">{error}</Alert> : null}

                        <div className="mb-3">
                          <AvField
                            id="email"
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="john@mybusiness.com"
                            type="email"
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="firstName"
                            label="First Name"
                            type="text"
                            required
                            placeholder="John"
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="lastName"
                            label="Last Name"
                            type="text"
                            required
                            placeholder="Appleseed"
                          />
                        </div>

                        <div className="mb-3">
                          <Label
                            htmlFor="example-text-input"
                            className="form-Label"
                          >
                            Business or trading name
                          </Label>
                          <Input
                            className="form-control"
                            type="text"
                            value={companyName}
                            placeholder={'Business name'}
                            onChange={(e) => setCompanyName(e.target.value)}
                            id="company-name-text-input"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                        <div className="mb-3">
                          <Label className="form-Label">Country</Label>
                          <select
                            className="form-select"
                            onChange={(e) =>
                              countries &&
                              setCountry(countries[parseInt(e.target.value)])
                            }
                          >
                            {countries &&
                              countries.map((country, i) => (
                                <option key={country.code} value={i}>
                                  {country.flag} {country.name}
                                </option>
                              ))}
                          </select>
                        </div>

                        <div className="mb-4">
                          <p className="mb-0">
                            By registering you agree to the Lopay{' '}
                            <Link to="#" className="text-primary">
                              Terms of Use
                            </Link>
                          </p>
                        </div>
                        <div className="mb-3">
                          <button
                            disabled={loading}
                            className="btn btn-primary w-100 waves-effect waves-light"
                            type="submit"
                          >
                            {loading ? `Loading...` : `Register`}
                          </button>
                        </div>
                      </AvForm>

                      <div className="mt-5 text-center">
                        <p className="text-muted mb-0">
                          Already have an account ?{' '}
                          <Link
                            to="/login"
                            className="text-primary fw-semibold"
                          >
                            {' '}
                            Login{' '}
                          </Link>{' '}
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Lopay . Crafted with{' '}
                        <i className="mdi mdi-heart text-danger"></i> by Lopay
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            {
              // <CarouselPage />
            }
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register
