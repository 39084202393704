import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { InfiniteData } from 'react-query'
import { Link } from 'react-router-dom'
import { Badge, Input, Table } from 'reactstrap'
import styled from 'styled-components'

import { PaginatedWaitingListMerchants } from '../../waiting-list-merchants.type'

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`

type IComponentProps = {
  data: InfiniteData<PaginatedWaitingListMerchants> | undefined
  isLoading: boolean
  isError: boolean
  toggleSelectedMerchantId: (merchantId: string) => void
  isMerchantSelected: (merchantId: string) => boolean
}

const WaitingListMerchantsTable: React.FC<IComponentProps> = ({
  data,
  isLoading,
  isError,
  toggleSelectedMerchantId,
  isMerchantSelected,
}) => {
  const [hasToggledAll, setHasToggledAll] = useState<boolean>(false)

  const dataArray = useMemo(
    () => (data?.pages ?? []).flatMap((page) => page.data),
    [data],
  )

  const toggleAll = useCallback(() => {
    ;(dataArray ?? []).forEach((waitingListMerchant) => {
      toggleSelectedMerchantId(waitingListMerchant.merchant.id)
    })
  }, [dataArray, toggleSelectedMerchantId])

  if (isLoading && dataArray.length === 0) {
    return <div>Loading ...</div>
  }

  if (isError) {
    return <div>Error loading waiting lists. Please try again.</div>
  }

  if (dataArray?.length === 0) {
    return (
      <React.Fragment>
        <div
          className="table-responsive"
          style={{
            paddingBottom: 550,
            marginBottom: -550,
            msOverflowStyle: 'none',
          }}
        >
          <CenterContainer>No results</CenterContainer>
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div
        className="table-responsive"
        style={{
          paddingBottom: 550,
          marginBottom: -550,
          msOverflowStyle: 'none',
        }}
      >
        <Table className="table table-striped mb-0">
          <thead>
            <tr>
              <th>
                <Input
                  type="checkbox"
                  className="form-check-input"
                  id={`toggle-all`}
                  checked={hasToggledAll}
                  onClick={() => {
                    setHasToggledAll((prev) => !prev)
                    toggleAll()
                  }}
                />
              </th>
              <th>Merchant name</th>
              <th>Requested access</th>
              <th>Joined on</th>
              <th>Grant access at</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {(dataArray ?? []).map((waitingListMerchant) => {
              const merchantId = waitingListMerchant.merchant.id

              const requestedAccess = waitingListMerchant.requestedAccess ? (
                <Badge className="me-2 bg-success">Yes</Badge>
              ) : (
                <Badge className="me-2 bg-warning">No</Badge>
              )

              return (
                <tr key={waitingListMerchant.id}>
                  <td>
                    <Input
                      type="checkbox"
                      className="form-check-input"
                      id={`merchant-selected-${merchantId}`}
                      checked={isMerchantSelected(merchantId)}
                      onClick={() => toggleSelectedMerchantId(merchantId)}
                    />
                  </td>
                  <td>{waitingListMerchant.merchant.name}</td>
                  <td>{requestedAccess}</td>
                  <td>
                    {moment(waitingListMerchant.createdAt)
                      .local()
                      .format('DD-MM-YY hh:mm')}
                  </td>
                  <td>
                    {waitingListMerchant.grantAccessAt
                      ? moment(waitingListMerchant.grantAccessAt)
                          .local()
                          .format('DD-MM-YY hh:mm')
                      : 'No date set'}
                  </td>
                  <td>
                    <Link
                      to={`/merchant/${waitingListMerchant.merchant.id}`}
                      target="_blank"
                    >
                      View
                    </Link>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    </React.Fragment>
  )
}

export default WaitingListMerchantsTable
