import React from 'react'
import QuickbooksConnectButton from '../quickbooks-connect-button/quickbooks-connect-button.component'

type IComponentProps = {
  merchantId: string
  apiKey: string
}

const QuickbooksStartConnection: React.FC<IComponentProps> = ({
  merchantId,
  apiKey,
}) => {
  return (
    <div className="auth-content my-auto">
      <div className="text-center">
        <h5 className="mb-0">Connect QuickBooks with Lopay</h5>
        <p className="text-muted mt-2">
          <a href="https://quickbooks.intuit.com/">
            https://quickbooks.intuit.com/
          </a>
        </p>
        <p className="text-muted mt-2">
          Lopay lets you sync your payment and payout data daily into your
          QuickBooks account. It's simple to set up and once you've connected
          your QuickBooks account, Lopay automatically syncs your data into the
          accounts of your choice in QuickBooks.
        </p>
        <p className="text-muted mt-2">
          Learn more about the{' '}
          <a href="https://support.lopay.com/en/articles/8124107-integrate-lopay-with-quickbooks">
            Lopay QuickBooks integration
          </a>
          .
        </p>
      </div>
      <div className="text-center mt-4 pt-2">
        <QuickbooksConnectButton merchantId={merchantId} apiKey={apiKey} />
      </div>
    </div>
  )
}

export default QuickbooksStartConnection
