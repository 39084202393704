import { Alert, Card, CardBody, Spinner } from 'reactstrap'
import React, { useState } from 'react'
import moment from 'moment'
import { useGetMerchantNotes } from './merchant-notes.hook'
import { addMerchantNote, deleteMerchantNote } from './merchant-notes.api'

type NoteProps = {
  displayName: string
  createdAt: Date
  updatedAt: Date
  note: string
  id: string
  onDelete: (noteId: string) => Promise<void>
}

const Note = ({
  displayName,
  createdAt,
  updatedAt,
  note,
  id,
  onDelete,
}: NoteProps) => {
  const [showButtons, setShowButtons] = useState(false)

  const createdTime = moment(createdAt).format('Do MMM YYYY')
  const editedTime =
    createdAt.getTime() !== updatedAt.getTime()
      ? moment(updatedAt).format('Do MMM YYYY')
      : undefined

  return (
    <Card
      onMouseEnter={() => setShowButtons(true)}
      onMouseLeave={() => setShowButtons(false)}
    >
      <CardBody>
        <div className="d-flex justify-content-between">
          <p className="card-title-desc">
            {displayName}
            <br />
            <span className="font-size-10">
              {createdTime} {editedTime && ` | Edited ${editedTime}`}
            </span>
          </p>
          {showButtons && (
            <button
              className="btn btn-sm btn-danger"
              onClick={() => onDelete(id)}
            >
              <i className="mdi mdi-trash-can font-size-16 align-middle"></i>
            </button>
          )}
        </div>
        <br />
        <span style={{ whiteSpace: 'pre-wrap' }}>{note}</span>
      </CardBody>
    </Card>
  )
}

type MerchantNotesProps = {
  merchantId: string
}

const MerchantNotes = ({ merchantId }: MerchantNotesProps) => {
  const result = useGetMerchantNotes({
    merchantId,
  })
  const [noteText, setNoteText] = useState('')

  if (result.isLoading || !result.data) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner className="mt-4" color="primary" />
      </div>
    )
  }

  if (result.error) {
    return (
      <Alert color="danger">
        Something went wrong whilst loading merchant notes. Please refresh the
        page.
      </Alert>
    )
  }

  const {
    data: { notes },
  } = result

  const handleDeleteMerchantNote = async (noteId: string) => {
    await deleteMerchantNote({ merchantId, noteId }).catch((e) => {
      console.log(e)
      window.alert(
        'Could not delete note. Please refresh the page or check your network.',
      )
    })
    await result.refetch()
  }

  const handleAddMerchantNote = async () => {
    if (noteText === '') return

    await addMerchantNote({ merchantId, note: noteText }).catch((e) => {
      console.log(e)
      window.alert(
        'Could not add note. Please refresh the page or check your network.',
      )
    })
    setNoteText('')
    await result.refetch()
  }

  const formattedNotes = notes?.map((n) => {
    const createdAt = new Date(n.createdAt)
    const updatedAt = new Date(n.updatedAt)

    return (
      <Note
        id={n.id}
        displayName={n.supportUser.name || n.supportUser.email || '[Not Found]'}
        createdAt={createdAt}
        updatedAt={updatedAt}
        note={n.note}
        onDelete={handleDeleteMerchantNote}
      />
    )
  })

  return (
    <>
      <Card>
        <CardBody>
          <textarea
            className="form-control"
            value={noteText}
            id="text-new-note"
            onChange={(e) => {
              setNoteText(e.target.value)
            }}
            placeholder="Write your new note here..."
            rows={3}
          ></textarea>
          <button
            className="mt-2 float-end btn btn-sm btn-primary"
            onClick={handleAddMerchantNote}
          >
            Add note
          </button>
        </CardBody>
      </Card>
      {formattedNotes ? (
        formattedNotes
      ) : (
        <p className="card-title-desc text-center mt-4">No notes recorded.</p>
      )}
    </>
  )
}

export default MerchantNotes
