import { GetRequest } from '../../../../helpers/lopay_api_helper'
import {
  AccountType,
  ScheduledPayoutConfig,
} from '../../../../api-dtos/merchant/merchant-audit-log.dto.interface'
import { DayName } from '../../../../api-dtos/payout/day-name.type'

export type GetPayoutScheduleDTO = {
  accountType: AccountType
  paused: boolean
  config: ScheduledPayoutConfig | null
  forcedManualPayouts: boolean
  defaultWeeklyAnchor: DayName
  minimumInstantPayoutBalance: number
}

export function getPayoutSchedule(
  merchantId: string,
): Promise<GetPayoutScheduleDTO> {
  return GetRequest(
    `support/merchant/${merchantId}/payouts/stripe-payout-schedule`,
  )
}
