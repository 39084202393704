import { z, ZodError } from 'zod'

export function validateAgainstSchema<T>(
  schema: z.ZodType<T, any>,
  value: unknown,
): { field: string; error: string }[] {
  try {
    schema.parse(value)
    // If the value is valid, return an empty array
    return []
  } catch (error) {
    if (error instanceof ZodError) {
      return error.errors.map((err) => ({
        field: err.path.join('.'),
        error: err.message,
      }))
    }
    throw error
  }
}
