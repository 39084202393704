/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  CardHeader,
  Table,
  Container,
  Button,
} from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import { listUsers } from '../../helpers/lopay_api_helper'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import styled from 'styled-components'
import { UserApiDto } from 'src/api-dtos/user/user-api.dto.interface'
import { UserRow } from './UserRow'
import { UserDetailsModal } from './UserDetailsModal'

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`

const Spacer = styled.div`
  padding: 10px 0;
`

try {
  TimeAgo.addDefaultLocale(en)
} catch (e) {
  console.error(e)
}
interface PageProps {
  history: any
}

const UsersPage = ({ history }: PageProps) => {
  // Searching of users
  const [searchQuery, setSearchQuery] = useState<string | undefined>()

  // Selected user (for modal)
  const [selectedUser, setSelectedUser] = useState<UserApiDto | undefined>()

  const [users, setUsers] = useState<UserApiDto[]>([])

  useEffect(() => {
    loadUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showLoadMoreButton = (): boolean => {
    const pageSize = 50
    return users.length > 0 && Number.isSafeInteger(users.length / pageSize)
  }

  let usersController = new AbortController()

  const loadUsers = async (offsetId?: string) => {
    console.log('Getting users...')
    // Cancel any current requests
    try {
      usersController?.abort()
    } catch (e) {
      console.error(e)
    }

    const params: {
      offsetId?: string
    } = {
      offsetId,
    }

    usersController = new AbortController()
    const response = await listUsers(searchQuery, params, usersController)

    if (response?.users) {
      setUsers(response?.users)
    }
  }

  const onUserRowClicked = (user: UserApiDto) => {
    setSelectedUser(user)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Users</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={'LoPay Support'} breadcrumbItem="Users" />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <div className="row align-ite  ms-center">
                    <form
                      onSubmit={(e) => {
                        setSelectedUser(undefined)
                        loadUsers()
                        e.preventDefault()
                      }}
                      className="row gx-3 gy-2 align-items-center"
                    >
                      <Col sm={5}>
                        <Label
                          className="visually-hidden"
                          htmlFor="specificSizeInputName"
                        >
                          Search
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="search input"
                          placeholder="User ID, email, phone number, name or address"
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </Col>

                      <div className="col-auto">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={(e) => {
                            setSelectedUser(undefined)
                            loadUsers()
                            e.preventDefault()
                          }}
                        >
                          Search
                        </button>
                      </div>
                    </form>
                  </div>
                </CardHeader>
                <CardBody>
                  <div
                    className="table-responsive"
                    style={{
                      paddingBottom: 550,
                      marginBottom: -550,
                      msOverflowStyle: 'none',
                    }}
                  >
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Signed up</th>
                          <th>Merchants</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {users.map((user) => (
                          <UserRow user={user} onClick={onUserRowClicked} />
                        ))}
                      </tbody>
                    </Table>
                  </div>

                  <Spacer />
                  <CenterContainer>
                    <p>Showing {users?.length || 0} users.</p>
                  </CenterContainer>

                  {showLoadMoreButton() ? (
                    <CenterContainer>
                      <Button
                        color="secondary"
                        onClick={() => loadUsers(users[users.length - 1].id)}
                      >
                        Load more
                      </Button>
                    </CenterContainer>
                  ) : (
                    <CenterContainer>
                      <p>No more users to load</p>
                    </CenterContainer>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <UserDetailsModal
            user={selectedUser}
            onClose={() => setSelectedUser(undefined)}
            history={history}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UsersPage
