import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import QRCode from 'qrcode.react'
import { Badge, Button, Modal, Table } from 'reactstrap'
import { Link } from 'react-router-dom'
import SupportExternalAccountDto, {
  OpenBankingVRPConsentStatusType,
} from '../../../api-dtos/support/external-account/support-external-account.dto'

import {
  createVrpConsent,
  generateVrpConsentUrl,
  listExternalAccountWithVrpConsents,
} from '../../../helpers/lopay_api_helper'

function getAccountDetails(externalAccount: SupportExternalAccountDto): string {
  return `${externalAccount.bankName ?? '[Missing bank name]'} | ${externalAccount.routingNumber} ${externalAccount.accountNumber}`.trim()
}

function getConsentDetails(externalAccount: SupportExternalAccountDto): string {
  if (externalAccount.consents.length === 0) {
    return 'Missing'
  }

  return externalAccount.consents[0].status
}

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`

const ConsentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Spacer = styled.div`
  padding: 10px 0;
`

interface IComponentProps {
  search: string | undefined
  status: OpenBankingVRPConsentStatusType | undefined
  payoutSchedule: 't2' | 't1' | 'instant' | undefined
  minimumTotalPaymentUnitsSum: number | undefined
  kybStatus: 'not_reviewed' | 'approved' | 'rejected' | undefined
}

const ExternalAccountOpenBankingVrpTable: React.FC<IComponentProps> = ({
  search,
  status,
  payoutSchedule,
  minimumTotalPaymentUnitsSum,
  kybStatus,
}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [showLoadMoreButton, setLoadMoreButton] = useState<boolean>(false)
  const [totalExternalAccountCount, setTotalExternalAccountCount] =
    useState<number>(0)
  const [externalAccounts, setExternalAccounts] = useState<
    SupportExternalAccountDto[]
  >([])
  const [selectedExternalAccount, setSelectedExternalAccount] = useState<
    SupportExternalAccountDto | undefined
  >()
  const [hybridConsentUrl, setHybridConsentUrl] = useState<string | undefined>()

  const loadExternalAccountWithVrpConsents = useCallback(async () => {
    const { data, count } = await listExternalAccountWithVrpConsents({
      offsetId: undefined,
      search,
      status,
      payoutSchedule,
      minimumTotalPaymentUnitsSum,
      kybStatus,
    })

    setExternalAccounts(data)
    setTotalExternalAccountCount(count)

    if (count > 0) {
      setLoadMoreButton(true)
    }
  }, [search, status, payoutSchedule, minimumTotalPaymentUnitsSum, kybStatus])

  useEffect(() => {
    loadExternalAccountWithVrpConsents()
  }, [
    loadExternalAccountWithVrpConsents,
    search,
    status,
    payoutSchedule,
    minimumTotalPaymentUnitsSum,
    kybStatus,
  ])

  useEffect(() => {
    if (externalAccounts.length === totalExternalAccountCount) {
      setLoadMoreButton(false)
    }
  }, [externalAccounts, totalExternalAccountCount])

  const loadMore = async () => {
    if (externalAccounts.length === 0) {
      return
    }

    const lastExternalAccount = externalAccounts[externalAccounts.length - 1]
    const { data, count } = await listExternalAccountWithVrpConsents({
      offsetId: lastExternalAccount.id,
      search,
      status,
      payoutSchedule,
      minimumTotalPaymentUnitsSum,
      kybStatus,
    })

    setExternalAccounts((previousExternalAccounts) =>
      previousExternalAccounts.concat(data),
    )
    setTotalExternalAccountCount(count)
  }

  const generateHybridConsentUrl = async (
    externalAccount: SupportExternalAccountDto | undefined,
  ) => {
    if (!externalAccount) {
      setSelectedExternalAccount(undefined)
      setHybridConsentUrl(undefined)

      return
    }

    const awaitingAuthorisationConsent = externalAccount.consents.find(
      (consent) => consent.status === 'AwaitingAuthorisation',
    )

    if (!awaitingAuthorisationConsent) {
      return
    }

    try {
      const { url } = await generateVrpConsentUrl(
        awaitingAuthorisationConsent!.id,
      )

      setHybridConsentUrl(url)
      setSelectedExternalAccount(externalAccount)
    } catch (error) {
      alert(
        'Something went wrong whilst generating link. Please contact a backend developer.',
      )
      setHybridConsentUrl(undefined)
      setSelectedExternalAccount(undefined)
    }
  }

  const createConsent = async (
    externalAccount: SupportExternalAccountDto | undefined,
  ) => {
    if (!externalAccount) {
      setSelectedExternalAccount(undefined)
      setHybridConsentUrl(undefined)

      return
    }

    try {
      setLoading(true)

      const { consentUrl } = await createVrpConsent({
        externalAccountId: externalAccount.id,
      })

      setHybridConsentUrl(consentUrl)
      setSelectedExternalAccount(externalAccount)

      // Reload external accounts with VRP consents
      loadExternalAccountWithVrpConsents()
    } catch (error) {
      alert(
        'Something went wrong whilst creating an Open Banking VRP consent. Please contact a backend developer.',
      )

      setHybridConsentUrl(undefined)
      setSelectedExternalAccount(undefined)
    } finally {
      setLoading(false)
    }
  }

  return (
    <React.Fragment>
      <div
        className="table-responsive"
        style={{
          paddingBottom: 550,
          marginBottom: -550,
          msOverflowStyle: 'none',
        }}
      >
        {externalAccounts.length > 0 ? (
          <Table className="table table-striped mb-0">
            <thead>
              <tr>
                <th>Merchant name</th>
                <th>Account details</th>
                <th>Open Banking VRP status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {externalAccounts.map((externalAccount) => {
                const consentDetails = getConsentDetails(externalAccount)
                const showCreateConsentButton =
                  consentDetails === 'Missing' ||
                  consentDetails === 'Rejected' ||
                  consentDetails === 'Revoked' ||
                  consentDetails === 'Expired'
                const showApproveConsentButton =
                  consentDetails === 'AwaitingAuthorisation'

                return (
                  <tr key={externalAccount.id}>
                    <td>{externalAccount.merchant.name}</td>
                    <td>{getAccountDetails(externalAccount)}</td>
                    <td>
                      {consentDetails === 'Missing' && (
                        <Badge className="me-2 bg-danger">
                          Missing - requires action
                        </Badge>
                      )}
                      {consentDetails === 'Rejected' && (
                        <Badge className="me-2 bg-danger">
                          Rejected - requires action
                        </Badge>
                      )}
                      {consentDetails === 'Revoked' && (
                        <Badge className="me-2 bg-danger">
                          Revoked - requires action
                        </Badge>
                      )}
                      {consentDetails === 'Expired' && (
                        <Badge className="me-2 bg-danger">
                          Expired - requires action
                        </Badge>
                      )}
                      {consentDetails === 'AwaitingAuthorisation' && (
                        <Badge className="me-2 bg-warning">
                          Awaiting authorisation - requires action
                        </Badge>
                      )}
                      {consentDetails === 'Authorised' && (
                        <Badge className="me-2 bg-success">Authorised</Badge>
                      )}
                    </td>
                    <td>
                      {showCreateConsentButton && (
                        <Link
                          onClick={(e) => {
                            e.preventDefault()

                            if (loading) {
                              return
                            }

                            createConsent(externalAccount)
                          }}
                          to="#"
                          className="btn btn-primary"
                        >
                          Create consent
                        </Link>
                      )}
                      {showApproveConsentButton && (
                        <Link
                          onClick={(e) => {
                            generateHybridConsentUrl(externalAccount)
                            e.preventDefault()
                          }}
                          to="#"
                          className="btn btn-success"
                        >
                          Authorise consent
                        </Link>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        ) : (
          <CenterContainer>No results</CenterContainer>
        )}
      </div>
      <Spacer />
      {showLoadMoreButton && (
        <CenterContainer>
          <Button color="secondary" onClick={() => loadMore()}>
            Load more
          </Button>
        </CenterContainer>
      )}
      <Modal
        isOpen={!!hybridConsentUrl}
        toggle={() => generateHybridConsentUrl(undefined)}
        scrollable={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {selectedExternalAccount?.merchant?.name}
          </h5>
          <button
            type="button"
            onClick={() => generateHybridConsentUrl(undefined)}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="mb-4">
            <h5 className="font-size-14 mb-1">Bank account details</h5>
            {selectedExternalAccount?.accountHolderName && (
              <p className="card-title-desc mb-2">
                Account holder name:{' '}
                {selectedExternalAccount?.accountHolderName}
              </p>
            )}
            {selectedExternalAccount?.bankName && (
              <p className="card-title-desc mb-2">
                Bank name: {selectedExternalAccount?.bankName}
              </p>
            )}
            {selectedExternalAccount?.routingNumber && (
              <p className="card-title-desc mb-2">
                Sort code: {selectedExternalAccount?.routingNumber}
              </p>
            )}
            {selectedExternalAccount?.accountNumber && (
              <p className="card-title-desc mb-2">
                Account number: {selectedExternalAccount?.accountNumber}
              </p>
            )}
            <hr />
            <h5 className="font-size-14 mb-1">VRP consent instructions</h5>
            <p className="card-title-desc mb-2">
              In order to authorise this VRP consent, you will need to either:
              <ul>
                <li>
                  Scan the below QR code on your device and sign in to the
                  NatWest Mobile Banking app. If you use this method you will
                  also need either:
                </li>
                <li>
                  Sign in using the NatWest Online Banking Portal by clicking
                  link below these instructions. You will also need:
                </li>
              </ul>
            </p>
            {hybridConsentUrl && (
              <ConsentContainer>
                <QRCode value={hybridConsentUrl} />
                <Spacer />
                <Link
                  to={{ pathname: hybridConsentUrl }}
                  target="_blank"
                  className="btn btn-primary"
                >
                  Authorise on desktop
                </Link>
              </ConsentContainer>
            )}
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ExternalAccountOpenBankingVrpTable
