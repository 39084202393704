import React, { useState } from 'react'
import { Badge, Input, Label } from 'reactstrap'

import { HardwareDto } from '../hardware.type'
import { formatNiceName } from '../hardware.utils'

type IComponentProps = {
  hardware: HardwareDto
  onCloseModal: () => void
  onSubmitDeleteHardware: (hardwareId: string) => void
  deleteError: string
}

const DeleteHardware: React.FC<IComponentProps> = ({
  hardware,
  onCloseModal,
  onSubmitDeleteHardware,
  deleteError,
}) => {
  const [confirmName, setConfirmName] = useState('')

  return (
    <React.Fragment>
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          You are about to delete {formatNiceName(hardware.name)}
        </h5>
        <button
          type="button"
          onClick={() => onCloseModal()}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body">
        <div>
          <div className="mb-4">
            <div>
              <h5 className="font-size-14 mb-1">
                Are you sure you want to do this?
              </h5>
              <p className="card-title-desc mb-2">
                Deleting this hardware means that <u>no merchants</u> will be
                able to order this item anymore.
              </p>
            </div>
          </div>

          <div className="mb-3">
            <Label htmlFor="hardware-name" className="form-Label">
              Please type the name of this hardware ({hardware.name}) to verify
              you wish to delete it.
            </Label>
            <Input
              className="form-control"
              type="text"
              placeholder={hardware.name}
              onChange={(e) => {
                setConfirmName(e.target.value)
              }}
              id="hardware-name"
            />
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <Badge className="me-2 bg-danger">{deleteError}</Badge>
        <button
          onClick={(e) => {
            e.preventDefault()
            onSubmitDeleteHardware(hardware.id!)
          }}
          type="button"
          className="btn btn-danger"
          disabled={hardware.name !== confirmName}
        >
          Delete Hardware
        </button>
      </div>
    </React.Fragment>
  )
}

export default DeleteHardware
