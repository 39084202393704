/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import { Row, Col, Alert, Container } from 'reactstrap'

// availity-reactstrap-validation
import { AvForm } from 'availity-reactstrap-validation'

// action
import { apiError } from '../../store/actions'

//redux
import { useDispatch } from 'react-redux'

// import images
import logo from '../../assets/images/xero/logo-blue.png'
import {
  getXeroTenants,
  updateXeroAuthtoken,
} from '../../helpers/lopay_api_helper'
import { XeroConnection } from '../../api-dtos/xero/xero.dto'

interface PageProps {
  google: Object
  history: any
  match: {
    params: {}
  }
}

const XeroConnected = ({ history, match }: PageProps) => {
  const dispatch = useDispatch()

  const [selectedTenantId, setSelectedTenantId] = useState<string | undefined>()
  const [xeroConnections, setXeroConnections] = useState<
    XeroConnection[] | undefined
  >()

  const [error] = useState<string | null>()
  const [loading, setLoading] = useState<boolean>(true)

  // Get the Xero params and update the API
  const queryParams = new URLSearchParams(window.location.search)
  const idToken = queryParams.get('xero_idtoken')
  const authToken = queryParams.get('xero_authtoken')
  const refresh = queryParams.get('xero_refresh')
  const sessionState = queryParams.get('xero_sessionState')

  console.log('xero idToken: ', idToken)
  console.log('xero authToken: ', authToken)
  console.log('xero refresh: ', refresh)

  const listXeroConnections = async () => {
    const merchantId = localStorage.getItem('merchantId')
    const APIKey = localStorage.getItem('APIKey')

    if (!merchantId || !APIKey) {
      alert('Unable to authenticate')
      return
    }

    if (!idToken || !authToken || !refresh || !sessionState) {
      alert('Could to retrieve code from Xero')
      return
    }

    setLoading(true)
    setXeroConnections(await getXeroTenants(merchantId, APIKey, authToken))
  }

  const updateXeroToken = async (tenantId: string) => {
    const merchantId = localStorage.getItem('merchantId')
    const APIKey = localStorage.getItem('APIKey')

    if (!merchantId || !APIKey) {
      alert('Unable to authenticate')
      return
    }

    if (!idToken || !authToken || !refresh || !sessionState) {
      alert('Could to retrieve code from Xero')
      return
    }

    setLoading(true)
    await updateXeroAuthtoken(
      merchantId,
      APIKey,
      idToken,
      authToken,
      refresh,
      sessionState,
      tenantId,
    )

    history.push(`/xero/${merchantId}/${APIKey}`)
  }

  const handleValidSubmit = () => {
    window.location.href = 'https://lopay.com/download'
  }

  useEffect(() => {
    dispatch(apiError(''))
    listXeroConnections().then(() => setLoading(false))
  }, [dispatch])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Connect Xero | Lopay</title>
      </MetaTags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={6} md={6} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <img src={logo} alt="" height="100" />{' '}
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <h5 className="mb-0">Xero // Lopay</h5>
                        <p className="text-muted mt-2">
                          Select which Xero account to connect to Lopay
                        </p>
                      </div>
                      <AvForm className="needs-validation custom-form mt-4 pt-2">
                        {error && error ? (
                          <Alert color="danger">{error}</Alert>
                        ) : null}
                        {error ? <Alert color="danger">{error}</Alert> : null}

                        {!xeroConnections && loading && (
                          <>Loading Xero accounts...</>
                        )}

                        {xeroConnections && loading && <>Connecting...</>}

                        {!selectedTenantId && !loading && (
                          <ul className="list-group list-group-flush">
                            {xeroConnections?.map((tenant) => {
                              return (
                                <button
                                  key={tenant.tenantId}
                                  type="button"
                                  className="btn btn-primary btn-lg waves-effect waves-light"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setSelectedTenantId(tenant.tenantId)
                                    updateXeroToken(tenant.tenantId).then(() =>
                                      setLoading(false),
                                    )
                                  }}
                                >
                                  Connect to {tenant.tenantName}
                                </button>
                              )
                            })}
                          </ul>
                        )}
                        {selectedTenantId && (
                          <div className="mb-3">
                            <button
                              disabled={loading}
                              className="btn btn-primary w-100 waves-effect waves-light"
                              type="button"
                              onClick={() => {
                                handleValidSubmit()
                              }}
                            >
                              {loading ? `Loading...` : `Go back to Lopay`}
                            </button>
                          </div>
                        )}
                      </AvForm>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Lopay . Crafted with{' '}
                        <i className="mdi mdi-heart text-danger"></i> by Lopay
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default XeroConnected
