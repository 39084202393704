/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useReducer, useState } from 'react'
import MetaTags from 'react-meta-tags'
import QRCode from 'qrcode.react'
import { Link } from 'react-router-dom'
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  CardHeader,
  Modal,
  Table,
  Container,
  Badge,
  Button,
} from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import {
  listMerchants,
  supportFetchMerchant,
  supportGenerateSignInLink,
  supportUpdateMerchantPaymentConfigDetails,
} from '../../helpers/lopay_api_helper'
import ReactTimeAgo from 'react-time-ago'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import styled from 'styled-components'
import KYCBadge from '../Merchant/support-merchant-kyc/components/KYCBadge/KYCBadge'
import SupportMerchantApiDto from '../../api-dtos/support/merchant/support-merchant-api.dto.interface'
import KYBStatusPill from '../Merchant/support-merchant-kyb/components/kyb-status-pill/kyb-status-pill.component'
import KYBStatusSelectorLight from '../Merchant/support-merchant-kyb/components/kyb-status-selector/kyb-status-selector-light.component'
import { KYBStatus } from '../FraudPrevention/kyb-threshold.type'

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`

const Spacer = styled.div`
  padding: 10px 0;
`

try {
  TimeAgo.addDefaultLocale(en)
} catch (e) {
  console.error(e)
}
interface PageProps {
  history: any
}

const RegisteredMerchantsPage = ({ history }: PageProps) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0)

  const [loadingIndividualMerchant, setLoadingIndividualMerchant] =
    useState<boolean>(false)

  // const dispatch = useDispatch()

  // State of copying merchant ID button
  const [copyMerchantIdText, setCopyMerchantIdText] = useState<string>('Copy')

  // Searching of merchants
  const [searchQuery, setSearchQuery] = useState<string | undefined>()

  // For signing in as merchants

  const [signInAsMagicLink, setSignInAsMagicLink] = useState<
    string | undefined
  >()

  // Selected merchant (for modal)
  const [selectedMerchant, setSelectedMerchant] = useState<
    SupportMerchantApiDto | undefined
  >()

  const [merchants, setMerchants] = useState<SupportMerchantApiDto[]>([])

  const copyMerchantId = (id: string) => {
    navigator.clipboard.writeText(id).then(
      function () {
        setCopyMerchantIdText('Copied 🎉')
      },
      function (err) {
        setCopyMerchantIdText('Could not copy link 😭')
      },
    )

    setTimeout(function () {
      setCopyMerchantIdText('Copy')
    }, 2500)
  }

  const kybChoices = [
    {
      value: undefined,
      label: 'KYB not reviewed',
    },
    {
      value: `${false}`,
      label: 'Approved',
    },
    {
      value: `${true}`,
      label: 'Rejected',
    },
  ]

  const kybFilterChoices = [
    {
      value: undefined,
      label: 'Select KYB filter',
    },
    {
      value: 'not-set',
      label: 'Not reviewed',
    },
    {
      value: `${false}`,
      label: 'Approved',
    },
    {
      value: `${true}`,
      label: 'Rejected',
    },
  ]

  const [selectedKybFilter, setSelectedKybFilter] = useState<
    'true' | 'false' | 'not-set' | undefined
  >()

  const kybSelectedFilterValue = () => {
    if (selectedKybFilter === undefined) {
      return undefined
    } else {
      return `${selectedKybFilter}`
    }
  }

  const kybValue = () => {
    if (
      selectedMerchant?.kybDisabledCharges === undefined ||
      selectedMerchant?.kybDisabledCharges === null
    ) {
      return undefined
    } else {
      return `${selectedMerchant?.kybDisabledCharges}`
    }
  }

  const signInAsUser = async (userId: string) => {
    // Fetch magic link form backend
    const { magicLink } = await supportGenerateSignInLink(userId)
    setSignInAsMagicLink(magicLink)
  }

  useEffect(() => {
    loadMerchants()
    // eslint-disable-next-line
  }, [selectedKybFilter])

  const showLoadMoreButton = (): boolean => {
    const pageSize = 50
    return (
      merchants.length > 0 && Number.isSafeInteger(merchants.length / pageSize)
    )
  }

  let merchantsController = new AbortController()

  const loadMerchants = async (offsetId?: string) => {
    console.log('Getting merchants...')
    // Cancel any current requests
    try {
      merchantsController?.abort()
    } catch (e) {
      console.error(e)
    }

    const params: {
      kybDisabledCharges?: boolean | null
      offsetId?: string
    } = {
      offsetId,
    }

    if (selectedKybFilter !== 'not-set') {
      if (selectedKybFilter === undefined) {
        params.kybDisabledCharges = null
      } else {
        params.kybDisabledCharges =
          selectedKybFilter === undefined
            ? undefined
            : JSON.parse(selectedKybFilter)
      }
    }

    merchantsController = new AbortController()
    const response = await listMerchants(
      searchQuery,
      params,
      merchantsController,
    )

    if (response?.merchants) {
      setMerchants(response?.merchants)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Registered Merchants</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={'LoPay Support'} breadcrumbItem="Merchants" />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <div className="row align-ite  ms-center">
                    <form
                      onSubmit={(e) => {
                        setSelectedMerchant(undefined)
                        loadMerchants()
                        e.preventDefault()
                      }}
                      className="row gx-3 gy-2 align-items-center"
                    >
                      <Col sm={5}>
                        <Label
                          className="visually-hidden"
                          htmlFor="specificSizeInputName"
                        >
                          Search
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="search input"
                          placeholder="Merchant id, stripe id, email, name or address"
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </Col>

                      <Col sm={3}>
                        <Label
                          className="visually-hidden"
                          htmlFor="specificSizeInputName"
                        >
                          KYB Status
                        </Label>
                        <select
                          className="form-select"
                          value={kybSelectedFilterValue()}
                          onChange={(e) => {
                            if (e.target.value === 'not-set') {
                              setSelectedKybFilter('not-set')
                            } else {
                              setSelectedKybFilter(
                                e.target.value as 'true' | 'false' | undefined,
                              )
                            }
                          }}
                        >
                          {kybFilterChoices.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </Col>

                      <div className="col-auto">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={(e) => {
                            setSelectedMerchant(undefined)
                            loadMerchants()
                            e.preventDefault()
                          }}
                        >
                          Search
                        </button>
                      </div>
                    </form>
                  </div>
                </CardHeader>
                <CardBody>
                  <div
                    className="table-responsive"
                    style={{
                      paddingBottom: 550,
                      marginBottom: -550,
                      msOverflowStyle: 'none',
                    }}
                  >
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Signed up</th>
                          <th>Schedule</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {merchants.map((merchant) => {
                          return (
                            <tr key={merchant.id}>
                              <td
                                onClick={async () => {
                                  setSignInAsMagicLink(undefined)
                                  setSelectedMerchant(merchant)
                                  setLoadingIndividualMerchant(true)
                                  try {
                                    setSelectedMerchant(
                                      await supportFetchMerchant(merchant.id),
                                    )
                                  } catch (e: any) {
                                    alert(
                                      (e?.message as string) ||
                                        `Could not load merchant details ${e}`.trim(),
                                    )
                                  }
                                  setLoadingIndividualMerchant(false)
                                }}
                              >
                                <b>{merchant.name}</b>
                                <br />
                                {merchant.associatedUsers
                                  ?.filter((u) =>
                                    searchQuery?.includes('@')
                                      ? true
                                      : u.isAdmin,
                                  )
                                  .map((profile) =>
                                    searchQuery?.toLowerCase()?.trim() ===
                                    profile.email ? (
                                      <span className="me-2 bg-danger">
                                        {profile.firstName} {profile.lastName} (
                                        {profile.email}
                                        )
                                        <br />
                                      </span>
                                    ) : (
                                      <span>
                                        {profile.firstName} {profile.lastName} (
                                        {profile.email}
                                        )
                                        <br />
                                      </span>
                                    ),
                                  )}{' '}
                                {merchant.location?.address?.street} <br />
                                {merchant.location?.address?.town} <br />
                                {merchant.location?.address?.postcode} <br />
                                {
                                  <span
                                    style={{
                                      color: '#df7178',
                                    }}
                                  >
                                    <b>{merchant.attribution}</b>
                                  </span>
                                }{' '}
                                <br />
                              </td>
                              <td>
                                <ReactTimeAgo
                                  date={new Date(merchant.createdAt)}
                                  locale="en-US"
                                />
                              </td>
                              <td>
                                {merchant.payoutSchedule.schedule ===
                                  'instant' && 'Instant payouts'}
                                {merchant.payoutSchedule.schedule === 't1' &&
                                  'Next day'}
                                {merchant.payoutSchedule.schedule === 't2' &&
                                  'Weekly'}
                              </td>
                              <td>
                                <div className="mt-1">
                                  {merchant.kycStatus.restrictions ? (
                                    <Badge className="me-2 bg-danger">
                                      Restricted
                                    </Badge>
                                  ) : (
                                    <Badge className="me-2 bg-success">
                                      Unrestricted by Stripe
                                    </Badge>
                                  )}

                                  {merchant.kycStatus.payouts.disabled ? (
                                    <Badge className="me-2 bg-danger">
                                      Disabled payouts
                                    </Badge>
                                  ) : (
                                    <Badge className="me-2 bg-success">
                                      Payouts enabled
                                    </Badge>
                                  )}

                                  {merchant.kycStatus.hasPayoutMethod ? (
                                    <Badge className="me-2 bg-success">
                                      Has bank account
                                    </Badge>
                                  ) : (
                                    <Badge className="me-2 bg-danger">
                                      No bank account
                                    </Badge>
                                  )}

                                  {merchant.kycStatus.eventuallyDue && (
                                    <Badge className="me-2 bg-danger">
                                      ID Verification eventually due
                                    </Badge>
                                  )}

                                  {merchant.useFraudFramework ? (
                                    <KYBStatusPill
                                      kybStatus={merchant.kybStatus}
                                      includePrefix
                                    />
                                  ) : (
                                    <KYCBadge
                                      blockMaintained={
                                        merchant.kybBlockMaintained
                                      }
                                      disabledCharges={
                                        merchant.kybDisabledCharges
                                      }
                                      messagePrefix={'KYC'}
                                    />
                                  )}

                                  {merchant.terminals.isSetup ? (
                                    <Badge className="me-2 bg-success">
                                      Terminals
                                    </Badge>
                                  ) : (
                                    <Badge className="me-2 bg-danger">
                                      No terminals
                                    </Badge>
                                  )}

                                  <Badge
                                    className={`me-2 ${
                                      merchant.onboarding.percentage === 100
                                        ? 'bg-success'
                                        : 'bg-warning'
                                    }`}
                                  >
                                    Onboarding:{' '}
                                    {merchant.onboarding.percentage || 0}%
                                  </Badge>
                                </div>
                              </td>
                              <td>
                                <Link
                                  onClick={async (e) => {
                                    setSignInAsMagicLink(undefined)
                                    setSelectedMerchant(merchant)
                                    setLoadingIndividualMerchant(true)
                                    try {
                                      setSelectedMerchant(
                                        await supportFetchMerchant(merchant.id),
                                      )
                                    } catch (e: any) {
                                      alert(
                                        (e?.message as string) ||
                                          `Could not load merchant details ${e}`.trim(),
                                      )
                                    }
                                    setLoadingIndividualMerchant(false)
                                    e.preventDefault()
                                  }}
                                  to="#"
                                  className="btn btn-primary"
                                >
                                  View account
                                </Link>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>

                  <Spacer />
                  <CenterContainer>
                    <p>Showing {merchants?.length || 0} merchants.</p>
                  </CenterContainer>

                  {showLoadMoreButton() ? (
                    <CenterContainer>
                      <Button
                        color="secondary"
                        onClick={() =>
                          loadMerchants(merchants[merchants.length - 1].id)
                        }
                      >
                        Load more
                      </Button>
                    </CenterContainer>
                  ) : (
                    <CenterContainer>
                      <p>No more merchants to load</p>
                    </CenterContainer>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={!!selectedMerchant}
            toggle={() => {
              setSelectedMerchant(undefined)
            }}
            scrollable={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">{selectedMerchant?.name}</h5>
              <button
                type="button"
                onClick={() => setSelectedMerchant(undefined)}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <div className="mb-4">
                  <h5 className="font-size-14 mb-1">Merchant Details</h5>
                  <p className="card-title-desc mb-2">
                    ID: {selectedMerchant?.id}{' '}
                    <b>
                      <a
                        href="#"
                        onClick={() => copyMerchantId(selectedMerchant!.id)}
                      >
                        [{copyMerchantIdText}]
                      </a>
                    </b>
                  </p>
                  <hr />
                  <div>
                    {selectedMerchant?.useFraudFramework ? (
                      <div className="mb-3">
                        <KYBStatusSelectorLight
                          merchantId={selectedMerchant.id}
                          kybStatus={selectedMerchant.kybStatus}
                          refetchFn={(kybStatus?: KYBStatus) => {
                            if (kybStatus) {
                              setSelectedMerchant({
                                ...selectedMerchant,
                                kybStatus,
                              })
                              setMerchants(
                                merchants.map((m) => {
                                  if (m.id === selectedMerchant.id) {
                                    return {
                                      ...selectedMerchant,
                                      kybStatus,
                                    }
                                  }
                                  return m
                                }),
                              )
                              forceUpdate()
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <div className="mb-3">
                        <Label className="form-Label">KYB Status</Label>

                        <select
                          className="form-select"
                          value={kybValue()}
                          onChange={(e) => {
                            if (selectedMerchant) {
                              console.log('e.target:', e.target.value)

                              const kybDisabledCharges =
                                e.target.value === undefined ||
                                e.target.value === null ||
                                e.target.value === 'KYB not reviewed'
                                  ? null
                                  : JSON.parse(e.target.value)
                              selectedMerchant.kybDisabledCharges =
                                kybDisabledCharges

                              setSelectedMerchant({
                                ...selectedMerchant,
                                kybDisabledCharges: kybDisabledCharges,
                              })

                              supportUpdateMerchantPaymentConfigDetails(
                                selectedMerchant.id,
                                {
                                  kybDisabledCharges: kybDisabledCharges,
                                },
                              ).then(() => {
                                console.log(
                                  'updated kybDisabledCharges value',
                                  selectedMerchant.kybDisabledCharges,
                                )
                                setSelectedMerchant({
                                  ...selectedMerchant,
                                  kybDisabledCharges: kybDisabledCharges,
                                })

                                setMerchants(
                                  merchants.map((m) => {
                                    if (m.id === selectedMerchant.id) {
                                      return {
                                        ...selectedMerchant,
                                        kybDisabledCharges: kybDisabledCharges,
                                      }
                                    }
                                    return m
                                  }),
                                )

                                forceUpdate()
                              })
                            }
                          }}
                        >
                          {kybChoices.map((option) => (
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                      </div>
                    )}

                    {
                      // eslint-disable-next-line
                      signInAsMagicLink && (
                        <div className="mb-3">
                          <h5 className="font-size-14 mb-1">
                            Sign in as{' '}
                            <a
                              href="/#"
                              onClick={() => setSignInAsMagicLink(undefined)}
                            >
                              (hide)
                            </a>
                            :
                          </h5>
                          <QRCode value={signInAsMagicLink} />
                        </div>
                      )
                    }

                    {loadingIndividualMerchant ? (
                      <>Loading users....</>
                    ) : (
                      <>
                        <h5 className="font-size-14 mb-1">Users</h5>
                        <div className="mb-3">
                          <div className="table-responsive">
                            <Table className="table table-striped mb-0">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Email</th>
                                  <th>Options</th>
                                </tr>
                              </thead>
                              <tbody>
                                {selectedMerchant?.associatedUsers?.length ===
                                  0 && (
                                  <Badge className="me-2 bg-danger">
                                    Danger: This merchant has no users. Perhaps
                                    they have been deleted?
                                  </Badge>
                                )}
                                {selectedMerchant?.associatedUsers?.map(
                                  (user) => {
                                    return (
                                      <tr
                                        key={`${selectedMerchant?.id}=${user.id}`}
                                      >
                                        <th scope="row">
                                          {user.firstName} {user.lastName}
                                        </th>
                                        <td>{user.email}</td>
                                        <td>
                                          <button
                                            onClick={() =>
                                              signInAsUser(user.id)
                                            }
                                            type="button"
                                            className="btn btn-sm btn-secondary"
                                          >
                                            Sign in as
                                          </button>
                                        </td>
                                      </tr>
                                    )
                                  },
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="btn-group">
                      {selectedMerchant?.kycStatus.restrictions ? (
                        <Badge className="me-2 bg-danger">Restricted</Badge>
                      ) : (
                        <Badge className="me-2 bg-success">
                          Unrestricted by Stripe
                        </Badge>
                      )}
                      {selectedMerchant?.kycStatus.payouts.disabled ? (
                        <Badge className="me-2 bg-danger">
                          Disabled payouts
                        </Badge>
                      ) : (
                        <Badge className="me-2 bg-success">
                          Payouts enabled
                        </Badge>
                      )}
                      {selectedMerchant?.kycStatus.hasPayoutMethod ? (
                        <Badge className="me-2 bg-success">
                          Has bank account
                        </Badge>
                      ) : (
                        <Badge className="me-2 bg-danger">
                          No bank account
                        </Badge>
                      )}
                      {selectedMerchant?.kycStatus.eventuallyDue && (
                        <Badge className="me-2 bg-danger">
                          KYC eventually due
                        </Badge>
                      )}
                      {selectedMerchant?.terminals.isSetup ? (
                        <Badge className="me-2 bg-success">Terminals</Badge>
                      ) : (
                        <Badge className="me-2 bg-danger">No terminals</Badge>
                      )}

                      {selectedMerchant?.useFraudFramework ? (
                        <KYBStatusPill
                          kybStatus={selectedMerchant.kybStatus}
                          includePrefix
                        />
                      ) : (
                        <KYCBadge
                          blockMaintained={selectedMerchant?.kybBlockMaintained}
                          disabledCharges={selectedMerchant?.kybDisabledCharges}
                          messagePrefix={'KYC'}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                onClick={(e) => {
                  e.preventDefault()
                  history.push('/merchant/' + selectedMerchant?.id)
                }}
                type="button"
                className="btn btn-primary"
              >
                View details
              </button>

              {selectedMerchant?.paymentProviderId && (
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => {
                    const url = `https://dashboard.stripe.com/connect/accounts/${selectedMerchant?.paymentProviderId}`
                    window.open(url, '_blank')?.focus()
                  }}
                >
                  View in Stripe
                </button>
              )}
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  const url = `https://data.lopay.com/dashboard/4?merchant_id=${selectedMerchant?.id}`
                  window.open(url, '_blank')?.focus()
                }}
              >
                View in Metabase
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default RegisteredMerchantsPage
