import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import TimeAgo from 'javascript-time-ago'
import moment from 'moment'

import 'flatpickr/dist/themes/material_blue.css'
import Flatpickr from 'react-flatpickr'

import { Search } from 'react-bootstrap-table2-toolkit'

import _ from 'lodash'

//redux
import { useDispatch } from 'react-redux'

import {
  Alert,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Table,
  Container,
  Modal,
  Label,
  Input,
} from 'reactstrap'
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
import {
  createRefund,
  downloadTransactions,
  getUserProfile,
  listTransactions,
} from '../../helpers/lopay_api_helper'
import { fetchProfile } from '../../store/actions'
import UserProfileResponse from '../../api-dtos/profile/user-profile-response.dto.interface'
import ListTransactionsAPIDto from '../../api-dtos/transaction/list-transactionss.dto.interface'
import { priceFormat } from '../../helpers/utils'
import ReactTimeAgo from 'react-time-ago'

// Register timezone
import en from 'javascript-time-ago/locale/en.json'
import TransactionApiDto from '../../api-dtos/transaction/transaction.dto.interface'
import { Link } from 'react-router-dom'

try {
  TimeAgo.addDefaultLocale(en)
} catch (e) {
  console.error(e)
}
interface MerchantPageProps {
  history: any
}

const TransactionsPage = ({ history }: MerchantPageProps) => {
  const dispatch = useDispatch()

  const { SearchBar } = Search

  const [userProfile] = useState<UserProfileResponse | null>(getUserProfile())

  const [startDate, setStartDate] = useState<Date | undefined>(undefined)
  const [endDate, setEndDate] = useState<Date | undefined>(undefined)

  const [isProcessingRefund, setIsProcessingRefund] = useState<boolean>(false)

  const [isSelectingDateRange, setIsSelectingDateRange] =
    useState<boolean>(false)

  const [isLoadingTransactions, setIsLoadingTransactions] =
    useState<boolean>(false)

  const [selectedTransaction, setSelectedTransaction] = useState<
    TransactionApiDto | undefined
  >()
  const [selectedTransactionToRefund, setSelectedTransactionToRefund] =
    useState<TransactionApiDto | undefined>()

  const [refundAmount, setRefundAmount] = useState<number>(0)

  const [searchText, setSearchText] = useState<string>('')

  const [transactionsData, setTransactionsData] =
    useState<ListTransactionsAPIDto>({
      pageSize: 0,
      count: 0,
      transactions: [],
    })

  const loadTransactions = async (
    merchantId: string,
    offsetId?: string,
    overrideStartDate?: Date,
    overrideEndDate?: Date,
    query?: string,
  ) => {
    setIsLoadingTransactions(true)
    const transactions = await listTransactions(
      merchantId,
      offsetId,
      overrideStartDate,
      overrideEndDate,
      'json',
      query,
    )
    setIsLoadingTransactions(false)

    setTransactionsData(transactions)
  }

  const newRefund = async () => {
    if (!selectedTransactionToRefund || isProcessingRefund) {
      return
    }
    if (refundAmount < -1) {
      alert('Please enter an amount to refund')
      return
    }

    setIsProcessingRefund(true)
    const refund = await createRefund(
      merchant!.id,
      selectedTransactionToRefund.id,
      { amount: refundAmount * -1 },
    )

    setIsProcessingRefund(false)

    console.log('refund:', refund)

    selectedTransaction?.associatedRefunds?.push(refund)
    transactionsData.transactions.unshift(refund)

    setRefundAmount(0)
    setSelectedTransactionToRefund(undefined)
  }

  const merchant = userProfile?.user.merchants[0]

  var debouncedSearch = _.debounce(function (e) {
    if (!merchant) {
      return
    }
    console.log('Searching transactions for:', e)
    loadTransactions(merchant.id, undefined, undefined, undefined, e.trim())
  }, 1200)

  const paymentDate = (date: Date) => {
    return new Date(date)
  }

  useEffect(() => {
    dispatch(fetchProfile())
    userProfile?.user.merchants[0]?.id &&
      loadTransactions(userProfile?.user.merchants[0]?.id)
    // eslint-disable-next-line
  }, [])

  if (!merchant) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Your merchant</title>
          </MetaTags>
          <Container fluid>
            <Alert color="danger">
              Sorry there was a problem loading your details
            </Alert>
          </Container>
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Recent transactions</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={merchant.name}
            breadcrumbItem="Recent Transactions"
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <div className="row">
                    <div className="col-md-2">
                      <Link
                        onClick={async (e) => {
                          e.preventDefault()

                          let _startDate = startDate
                          let _endDate = endDate

                          /*
                            if (!startDate && !endDate && transactionsData?.transactions?.length > 0) {
                                _endDate = transactionsData?.transactions[0].createdAt
                                _startDate =  transactionsData?.transactions[transactionsData?.transactions.length-1].createdAt
                            }
                            */

                          const download = await downloadTransactions(
                            'lopay-report.csv',
                            merchant.id,
                            undefined,
                            _startDate,
                            _endDate,
                            'excel',
                          )

                          console.log('download:', download)
                        }}
                        to="#"
                        className="btn btn-secondary"
                      >
                        Download
                      </Link>
                    </div>

                    <div className="col-md-4 align-items-left">
                      <div className="search-box d-inline-block">
                        <div className="position-relative">
                          <SearchBar
                            placeholder="Search by reference"
                            searchText={searchText}
                            onSearch={(e) => {
                              setSearchText(e)
                              debouncedSearch(e)
                            }}
                          />
                          <i className="bx bx-search-alt search-icon-search" />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="d-flex flex-wrap align-items-center justify-content-end">
                        <form className="row gx-3 gy-2 align-items-center">
                          {!isSelectingDateRange && (
                            <Link
                              onClick={(e) => {
                                e.preventDefault()
                                setIsSelectingDateRange(true)
                              }}
                              to="#"
                              className="btn btn-primary m-2"
                            >
                              Filter by date
                            </Link>
                          )}

                          {isSelectingDateRange && (
                            <>
                              <Col sm={10}>
                                <div className="">
                                  <Flatpickr
                                    className="form-control"
                                    placeholder="Select date range..."
                                    onChange={(dates: [Date, Date]) => {
                                      setStartDate(dates[0])
                                      setEndDate(dates[1])
                                      loadTransactions(
                                        merchant.id,
                                        undefined,
                                        dates[0],
                                        dates[1],
                                      )
                                    }}
                                    options={{
                                      mode: 'range',
                                      dateFormat: 'Y-m-d',
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col sm={1}>
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => {
                                    setIsSelectingDateRange(false)
                                    setStartDate(undefined)
                                    setEndDate(undefined)
                                    loadTransactions(
                                      merchant.id,
                                      undefined,
                                      undefined,
                                      undefined,
                                    )
                                  }}
                                >
                                  X
                                </button>
                              </Col>
                            </>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="p-4">
                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Reference</th>
                          <th>Status</th>
                          <th>Fee</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      {isLoadingTransactions && <>Loading...</>}
                      <tbody>
                        {transactionsData.transactions.map((transaction) => {
                          return (
                            <tr
                              onClick={() =>
                                setSelectedTransaction(transaction)
                              }
                              key={transaction.id}
                            >
                              <td>
                                <span className="time">
                                  {moment(
                                    paymentDate(transaction.createdAt),
                                  ).format('MMM Do h:mm:ss a')}
                                </span>
                              </td>
                              <td>{transaction.description}</td>
                              <td>{transaction.status}</td>
                              <td>{priceFormat(transaction.fee)}</td>
                              <th scope="row">
                                {priceFormat(transaction.total)}
                              </th>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={!!selectedTransaction}
            toggle={() => {
              setSelectedTransaction(undefined)
            }}
            scrollable={true}
          >
            {selectedTransaction && (
              <>
                <div className="modal-header">
                  <h5 className="modal-title mt-0">
                    {selectedTransaction.description}:{' '}
                    {priceFormat(selectedTransaction.total)}{' '}
                    <p className="card-title-desc">
                      <span className="time">
                        {moment(selectedTransaction.createdAt).format(
                          'MMM Do h:mm:ss a',
                        )}
                      </span>
                    </p>
                  </h5>

                  <button
                    type="button"
                    onClick={() => setSelectedTransaction(undefined)}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div>
                    <div className="mb-4">
                      <div className="table-responsive">
                        <Table className="table table-striped mb-0">
                          <tbody>
                            <tr>
                              <td>Transaction Ref</td>
                              <td>{selectedTransaction?.shortRef}</td>
                            </tr>
                            {selectedTransaction?.card && (
                              <>
                                <tr>
                                  <td>Card last four</td>
                                  <td>{selectedTransaction?.card?.lastFour}</td>
                                </tr>
                                <tr>
                                  <td>Card type</td>
                                  <td>{selectedTransaction?.card?.type}</td>
                                </tr>
                                <tr>
                                  <td>Card origin</td>
                                  <td>{selectedTransaction?.card?.country}</td>
                                </tr>
                              </>
                            )}

                            <tr className="border-top">
                              <th scope="row">Subtotal</th>
                              <th>
                                {priceFormat(selectedTransaction.subtotal)}
                              </th>
                            </tr>
                            {selectedTransaction.taxes?.map((tax) => (
                              <tr>
                                <td>{tax.name}</td>
                                <td>{priceFormat(tax.amount)}</td>
                              </tr>
                            ))}

                            <tr>
                              <th scope="row">Transaction amount</th>
                              <th>{priceFormat(selectedTransaction.total)}</th>
                            </tr>
                            <tr>
                              <td>Fees</td>
                              <td>{priceFormat(selectedTransaction.fee)}</td>
                            </tr>
                            {selectedTransaction.processingFeeDiscounts.map(
                              (discount) => (
                                <tr>
                                  <th scope="row">{discount.type}</th>
                                  <td>{priceFormat(discount.feeDiscounted)}</td>
                                </tr>
                              ),
                            )}
                            {(selectedTransaction?.associatedRefunds?.length ||
                              0) > 0 && (
                              <>
                                <tr>
                                  <th>Refunds</th>
                                  <th></th>
                                </tr>
                                {selectedTransaction?.associatedRefunds?.map(
                                  (refund) => (
                                    <tr>
                                      <td>
                                        <span className="time">
                                          {moment(
                                            paymentDate(refund.createdAt),
                                          ).format('MMM Do h:mm:ss a')}
                                        </span>
                                      </td>
                                      <td>{priceFormat(refund.total)}</td>
                                    </tr>
                                  ),
                                )}
                              </>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() =>
                      setSelectedTransactionToRefund(selectedTransaction)
                    }
                  >
                    Refund
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => setSelectedTransaction(undefined)}
                  >
                    Done
                  </button>
                </div>
              </>
            )}
          </Modal>

          <Modal
            isOpen={!!selectedTransactionToRefund}
            toggle={() => {
              setSelectedTransactionToRefund(undefined)
            }}
            scrollable={true}
          >
            {selectedTransactionToRefund && (
              <>
                <div className="modal-header">
                  <h5 className="modal-title mt-0">
                    Refund {selectedTransactionToRefund.description}:{' '}
                    {priceFormat(selectedTransactionToRefund.total)}{' '}
                    <p className="card-title-desc">
                      <ReactTimeAgo
                        date={paymentDate(
                          selectedTransactionToRefund.createdAt,
                        )}
                        locale="en-US"
                      />
                    </p>
                  </h5>

                  <button
                    type="button"
                    onClick={() => setSelectedTransactionToRefund(undefined)}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div>
                    <div className="mb-4">
                      <div className="table-responsive">
                        <Col sm={6}>
                          <Label
                            className="visually-hidden"
                            htmlFor="specificSizeInputGroupUsername"
                          >
                            Amount to refund
                          </Label>
                          <div className="input-group">
                            <div className="input-group-text">£</div>
                            <Input
                              type="text"
                              className="form-control"
                              id="specificSizeInputGroupUsername"
                              placeholder="0.00"
                              value={refundAmount / 100}
                              onChange={(e) => {
                                const value =
                                  e.target.value === '' ? '0' : e.target.value
                                setRefundAmount(parseFloat(value) * 100)
                              }}
                            />
                          </div>
                        </Col>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setSelectedTransactionToRefund(undefined)}
                  >
                    Cancel
                  </button>

                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => newRefund()}
                  >
                    {isProcessingRefund ? <>Refunding...</> : <>Make refund</>}
                  </button>
                </div>
              </>
            )}
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TransactionsPage
