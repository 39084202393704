import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Progress,
  Spinner,
  Table,
} from 'reactstrap'
import React, { useEffect, useState } from 'react'
import {
  CashAdvanceStatus,
  cashAdvanceStatusToTitle,
  MerchantCashAdvanceDTO,
  RepaymentActivityType,
} from './dtos/merchant-cash-advance.dto'
import { getMerchantCashAdvances } from './api/get-merchant-cash-advances.api'
import { priceFormat } from '../../../helpers/utils'
import { useCashAdvanceRepaymentActivity } from './get-merchant-cash-advance-repayments.hook'
import moment from 'moment'

type RepaymentsTableProps = {
  isActive: boolean
  merchantId: string
}

const RepaymentsTable = (props: RepaymentsTableProps) => {
  const { isActive, merchantId } = props

  const results = useCashAdvanceRepaymentActivity({
    isActive,
    merchantId,
    pageSize: 20,
  })

  const data = React.useMemo(
    () => (results?.data?.pages ?? []).flatMap((page) => page.items),
    [results.data],
  )

  if (results.isLoading && data.length === 0) {
    return <Spinner className="ms-2" color="primary" />
  }

  if (results.isError) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Repayment activity</CardTitle>
        </CardHeader>
        <CardBody>
          <div>
            Something went wrong whilst loading repayment activity. Please try
            again.
          </div>
        </CardBody>
      </Card>
    )
  }

  if (data.length === 0) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>Repayment activity</CardTitle>
        </CardHeader>
        <CardBody>
          <div
            className="table-responsive"
            style={{
              paddingBottom: 550,
              marginBottom: -550,
              msOverflowStyle: 'none',
            }}
          >
            The merchant has no repayment activity.
          </div>
        </CardBody>
      </Card>
    )
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Repayment history</CardTitle>
      </CardHeader>
      <CardBody>
        <div className="table-responsive">
          <Table className="table table-striped mb-0">
            <thead>
              <tr>
                <th>Date</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Sent to YouLend at</th>
              </tr>
            </thead>
            <tbody>
              {data.map((d) => {
                const typeMap: Record<RepaymentActivityType, string> = {
                  cashAdvanceRepayment: 'repayment',
                  cashAdvanceRepaymentRefund: 'refund',
                }
                return (
                  <tr key={d.id}>
                    <td>
                      {moment(d.createdAt).local().format('DD-MM-YY hh:mm')}
                    </td>
                    <td>{typeMap[d.type]}</td>
                    <td>{priceFormat(d.amount)}</td>
                    <td>
                      {d.paidAt
                        ? moment(d.paidAt).local().format('DD-MM-YY hh:mm')
                        : null}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
          <br />
          {results.hasNextPage && (
            <Button
              type="button"
              color="primary"
              disabled={results.isLoading}
              onClick={() => results.fetchNextPage()}
            >
              Load more
            </Button>
          )}
        </div>
      </CardBody>
    </Card>
  )
}

type CashAdvanceProps = {
  cashAdvance: MerchantCashAdvanceDTO
}

const CashAdvance = (props: CashAdvanceProps) => {
  const { cashAdvance } = props

  const amount = cashAdvance.appliedAmount
    ? priceFormat(cashAdvance.appliedAmount)
    : '-'

  const repaid = cashAdvance.amountRepaid
    ? priceFormat(cashAdvance.amountRepaid)
    : '-'

  const owed =
    cashAdvance.amountRemaining && cashAdvance.amountRepaid
      ? priceFormat({
          units:
            cashAdvance.amountRemaining.units + cashAdvance.amountRepaid.units,
          currencyCode: cashAdvance.amountRemaining.currencyCode,
        })
      : '-'

  const percentage = cashAdvance.paidOffPercentage
    ? (cashAdvance.paidOffPercentage.value /
        cashAdvance.paidOffPercentage.precision) *
      100
    : 0

  const getBadge = (status: CashAdvanceStatus) => {
    switch (status) {
      case CashAdvanceStatus.OffTrack:
        return <Badge className="bg-danger me-2">Off Track</Badge>
      case CashAdvanceStatus.OnTrack:
        return <Badge className="bg-success me-2">On Track</Badge>
      case CashAdvanceStatus.Repaid:
        return <Badge className="bg-primary me-2">Repaid</Badge>
    }
  }

  const shouldShowProgress = [
    CashAdvanceStatus.OnTrack,
    CashAdvanceStatus.OffTrack,
    CashAdvanceStatus.Repaid,
  ].includes(cashAdvance.status)

  return (
    <Card className={cashAdvance.isDisplayed ? 'border-primary' : ''}>
      <CardBody>
        {shouldShowProgress ? (
          <>
            <div className="d-flex align-items-center">
              <div className="me-2">
                <b>Amount: {amount}</b> (Total to repay: {owed})
              </div>
              {cashAdvance.isDisplayed && (
                <Badge className="bg-primary me-2">Displayed in-app</Badge>
              )}
              {getBadge(cashAdvance.status)}
            </div>
            <small>
              {repaid} / {owed} repaid
            </small>
            <Progress
              value={percentage}
              color="primary"
              className="progress-xl mt-2"
            >
              {percentage}%
            </Progress>
          </>
        ) : (
          <div className="me-2">
            <b>Application status:</b>{' '}
            {cashAdvanceStatusToTitle[cashAdvance.status]}
          </div>
        )}
        <br />
        <small>
          <b>Lopay ID:</b> {cashAdvance.id || '-'}
        </small>
        <br />
        <small>
          <b>YouLend Lead ID:</b> {cashAdvance.leadId || '-'}
        </small>
      </CardBody>
    </Card>
  )
}

type MerchantCashAdvancesProps = {
  isActive: boolean
  merchantId: string
}

const MerchantCashAdvances = (props: MerchantCashAdvancesProps) => {
  const { isActive, merchantId } = props

  const [cashAdvances, setCashAdvances] = useState<
    MerchantCashAdvanceDTO[] | undefined
  >(undefined)

  useEffect(() => {
    if (!isActive) {
      return
    }
    getMerchantCashAdvances(merchantId).then((response) => {
      if (response) {
        if (response.cashAdvances) {
          setCashAdvances(response.cashAdvances)
        }
      } else {
        setCashAdvances([])
      }
    })
  }, [isActive, merchantId])

  const isQualified = cashAdvances?.length

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>Cash advances</CardTitle>
        </CardHeader>
        <CardBody>
          {cashAdvances ? (
            isQualified ? (
              cashAdvances.map((ca) => (
                <>
                  <CashAdvance cashAdvance={ca} />
                </>
              ))
            ) : (
              <div>Merchant does not qualify for cash advances.</div>
            )
          ) : (
            <div>Loading...</div>
          )}
        </CardBody>
      </Card>
      <RepaymentsTable isActive={isActive} merchantId={merchantId} />
    </>
  )
}

export default MerchantCashAdvances
