import styled from 'styled-components'

const TimelineItemIcon = styled.span<{ iconColor: 'green' | 'red' | 'grey' }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 9999px;
  background-color: ${(props) => {
    switch (props.iconColor) {
      case 'green':
        return 'rgb(34, 197, 94)'
      case 'red':
        return '#fd625e'
      case 'grey':
        return 'rgb(156 163 175)'
      default:
        return 'rgb(156 163 175)'
    }
  }};
`

export default TimelineItemIcon
