import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query'

import {
  getPaymentFees,
  getPayments,
  refundPayment,
} from './support-merchant-payments.api'

export function usePaymentFees({ paymentId }: { paymentId?: string }) {
  return useQuery({
    queryKey: ['paymentFees', { paymentId }],
    queryFn: () =>
      paymentId ? getPaymentFees({ paymentId }) : Promise.resolve(undefined),
  })
}

export function usePayments({
  isActive,
  merchantId,
  pageSize,
}: {
  isActive: boolean
  merchantId: string
  pageSize: number
}) {
  return useInfiniteQuery(
    ['payments', { isActive, merchantId, pageSize }],
    getPayments({ merchantId, pageSize }),
    {
      enabled: isActive,
      getNextPageParam: (lastPage) =>
        lastPage.transactions[lastPage.transactions.length - 1]?.id,
    },
  )
}

export function usePaymentRefund() {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      paymentId,
      amountUnits,
    }: {
      paymentId: string
      amountUnits: number
    }) => {
      return refundPayment({
        paymentId,
        amountUnits,
      })
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['payments'],
        })
      },
    },
  )
}
