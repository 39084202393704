import styled from 'styled-components'

const TimelineItemContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0px;
  margin-left: 0.75rem;
`

export default TimelineItemContentContainer
