import PriceDto from '../../../../api-dtos/types/price.dto'

export enum CashAdvanceStatus {
  PendingApplication = 'pendingApplication',
  AwaitingDecision = 'awaitingDecision',
  OffersAvailable = 'offersAvailable',
  AwaitingOfferAcceptance = 'awaitingOfferAcceptance',
  MoreInformationRequired = 'moreInformationRequired',
  InstantSignRequired = 'instantSignRequired',
  DocusignRequired = 'docusignRequired',
  OnTrack = 'onTrack',
  OffTrack = 'offTrack',
  Repaid = 'repaid',
  Declined = 'declined',
  AwaitingFunding = 'awaitingFunding',
}

export const cashAdvanceStatusToTitle: Record<CashAdvanceStatus, string> = {
  [CashAdvanceStatus.PendingApplication]: 'Pending application',
  [CashAdvanceStatus.AwaitingDecision]: 'Awaiting decision',
  [CashAdvanceStatus.OffersAvailable]: 'Offers available',
  [CashAdvanceStatus.AwaitingOfferAcceptance]: 'Awaiting offer acceptance',
  [CashAdvanceStatus.MoreInformationRequired]: 'More information required',
  [CashAdvanceStatus.InstantSignRequired]: 'Instant sign required',
  [CashAdvanceStatus.DocusignRequired]: 'Docusign required',
  [CashAdvanceStatus.OnTrack]: 'On track',
  [CashAdvanceStatus.OffTrack]: 'Off track',
  [CashAdvanceStatus.Repaid]: 'Repaid',
  [CashAdvanceStatus.Declined]: 'Declined',
  [CashAdvanceStatus.AwaitingFunding]: 'Awaiting funding',
}

export interface MerchantCashAdvanceDTO {
  id: string
  leadId: string
  isDisplayed: boolean
  appliedAmount?: PriceDto
  amountRepaid?: PriceDto
  amountRemaining?: PriceDto
  paidOffPercentage?: {
    value: number
    precision: number
  }
  status: CashAdvanceStatus
}

export interface GetMerchantcashAdvancesDTO {
  cashAdvances: MerchantCashAdvanceDTO[]
}

export type RepaymentActivityType =
  | 'cashAdvanceRepayment'
  | 'cashAdvanceRepaymentRefund'

export type RepaymentActivityStatus = 'success' | 'refund'

export interface RepaymentActivityOffset {
  id: string
  type: RepaymentActivityType
}

export interface RepaymentActivityItem {
  id: string
  type: RepaymentActivityType
  status: RepaymentActivityStatus
  title: string
  amount: PriceDto
  createdAt: Date
  paidAt: Date
}

export interface RepaymentActivityResponseDto {
  nextOffset: RepaymentActivityOffset | null
  items: RepaymentActivityItem[]
  pageSize: number
}
