import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'

//redux
import { useDispatch } from 'react-redux'

import { Link } from 'react-router-dom'

import {
  Alert,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  CardHeader,
  Modal,
  Table,
  Container,
} from 'reactstrap'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'
import {
  createPayPoint,
  deletePayPoint,
  getUserProfile,
  listMerchantPayPoints,
  updatePayPoint,
} from '../../helpers/lopay_api_helper'
import { fetchProfile } from '../../store/actions'
import UserProfileResponse from '../../api-dtos/profile/user-profile-response.dto.interface'
import { currencyFormat } from '../../helpers/utils'
import PayPointDto from '../../api-dtos/pay-point/pay-point.dto.interface'
import UpdatePayPointRequest from '../../api-dtos/pay-point/update-payout-request.dto.interface'

interface MerchantPageProps {
  history: any
}

const PayPointsPage = ({ history }: MerchantPageProps) => {
  const dispatch = useDispatch()

  // Selected pay point (for modal)
  const [selectedPayPoint, setSelectedPayPoint] = useState<
    PayPointDto | undefined
  >()
  const [selectedPayPointName, setSelectedPayPointName] = useState<string>('')
  const [selectedPayPointAmountDecimal, setSelectedPayPointAmountDecimal] =
    useState<string>('')
  const [selectedPayPointIsSticky, setSelectedPayPointIsSticky] =
    useState<boolean>(false)

  // Pay point state (created in model)
  const [, setNewPayPointLoading] = useState<boolean>(false)
  const [newPayPointError, setNewPayPointError] = useState<string | null>()
  const [newPayPoint, setNewPayPoint] = useState<PayPointDto | undefined>()
  const [newPayPointName, setNewPayPointName] = useState<string>('')

  const [payPoints, setPayPoints] = useState<PayPointDto[]>([])

  const [userProfile] = useState<UserProfileResponse | null>(getUserProfile())

  const merchant = userProfile?.user.merchants[0]

  useEffect(() => {
    dispatch(fetchProfile())
    userProfile?.user.merchants[0]?.id &&
      loadPayPoints(userProfile?.user.merchants[0]?.id)
    // eslint-disable-next-line
  }, [])

  const [isShowingNewPayPointModal, setIsShowingNewPayPointModal] =
    useState(false)

  function toggleIsShowingNewPayPointModal() {
    setNewPayPoint(undefined)
    setIsShowingNewPayPointModal(!isShowingNewPayPointModal)
  }

  if (!merchant) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Pay points</title>
          </MetaTags>
          <Container fluid>
            <Alert color="danger">
              Sorry there was a problem loading your pay points
            </Alert>
          </Container>
        </div>
      </React.Fragment>
    )
  }

  const loadPayPoints = async (merchantId: string) => {
    const { payPoints } = await listMerchantPayPoints(merchantId)

    console.log('got pay points:', payPoints)

    setPayPoints(payPoints)
  }

  const handleCreatePayPoint = async () => {
    setNewPayPointError(null)

    if (!newPayPointName || !newPayPointName.trim().length) {
      setNewPayPointError(
        'Please enter a name or short description for your pay point',
      )
      return
    }

    setNewPayPointLoading(true)

    const payPoint = await createPayPoint(merchant.id, newPayPointName)

    setNewPayPoint(payPoint)
    setNewPayPointLoading(false)

    payPoints.unshift(payPoint)
  }

  const handleUpdatePayPoint = async () => {
    if (!selectedPayPoint) {
      return
    }

    const amountUnits: number = parseInt(
      (parseFloat(selectedPayPointAmountDecimal) * 100).toFixed(0),
    )

    console.log('Updating pay point:', amountUnits, selectedPayPointIsSticky)

    const values: UpdatePayPointRequest = {
      name: selectedPayPointName,
      currentValue: {
        currencyCode: selectedPayPoint.currentValue.currencyCode,
        units: amountUnits,
      },
      hasStickyValue: selectedPayPointIsSticky,
    }

    const response = await updatePayPoint(
      merchant.id,
      selectedPayPoint.id,
      values,
    )

    console.log('update pay point response: ', response)

    loadPayPoints(merchant.id)
    setSelectedPayPoint(undefined)
  }

  const handleArchivePayPoint = async () => {
    if (!selectedPayPoint) {
      return
    }

    await deletePayPoint(merchant.id, selectedPayPoint.id)

    console.log('archived pay point:', selectedPayPoint)

    const indexOfPaymentRequest = payPoints.findIndex(
      (pr) => pr.id === selectedPayPoint.id,
    )

    payPoints.splice(indexOfPaymentRequest, 1)

    setSelectedPayPoint(undefined)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Pay Points</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title={merchant.name} breadcrumbItem="Pay Points" />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                  <div className="row align-ite  ms-center">
                    <div className="col-md-6">
                      <div className="mb-3"></div>
                    </div>
                    <div className="col-md-6">
                      <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                        <div>
                          <Link
                            onClick={(e) => {
                              e.preventDefault()
                              toggleIsShowingNewPayPointModal()
                            }}
                            to="#"
                            className="btn btn-primary"
                          >
                            <i className="bx bx-plus me-1"></i> Add New
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive">
                    <Table className="table table-striped mb-0">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Link</th>
                          <th>Current value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {payPoints.map((payPoint) => {
                          return (
                            <tr
                              onClick={() => {
                                setSelectedPayPoint(payPoint)
                                setSelectedPayPointAmountDecimal(
                                  `${payPoint.currentValue.units / 100}`,
                                )
                                setSelectedPayPointName(payPoint.name)
                                setSelectedPayPointIsSticky(
                                  payPoint.hasStickyValue,
                                )
                              }}
                              key={payPoint.id}
                            >
                              <th scope="row">{payPoint.name}</th>
                              <td>{payPoint.url}</td>
                              <td>
                                £
                                {currencyFormat(
                                  payPoint.currentValue.units || 0,
                                )}
                                {payPoint.hasStickyValue && (
                                  <i className="bx bx-pin"></i>
                                )}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={isShowingNewPayPointModal}
            toggle={() => {
              toggleIsShowingNewPayPointModal()
            }}
            scrollable={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">New pay point</h5>
              <button
                type="button"
                onClick={() => setIsShowingNewPayPointModal(false)}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>
                Customers can scan pay point QR codes to make in-person payments
              </p>
              <div>
                <div>
                  {newPayPointError ? (
                    <Alert color="danger">{newPayPointError}</Alert>
                  ) : null}
                </div>

                {newPayPoint ? (
                  <div className="modal-body">
                    <div>
                      <div className="mb-4">
                        <h5 className="font-size-14 mb-1">Pay Point</h5>
                        <p className="card-title-desc mb-2">
                          Customers can scan QR codes or click on the link to
                          make in-person payments
                        </p>
                        <hr />

                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={newPayPoint?.url}
                        >
                          {newPayPoint?.url}
                        </a>

                        {newPayPoint && (
                          <img
                            alt="qr code"
                            src={`${process.env.REACT_APP_API_HOST}public/pay-points/${newPayPoint?.id}/qr`}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="mb-3">
                      <Label
                        htmlFor="example-text-input"
                        className="form-Label"
                      >
                        Name or description
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="Main cashier"
                        onChange={(e) => setNewPayPointName(e.target.value)}
                        defaultValue=""
                        id="example-text-input"
                      />
                    </div>
                  </div>
                )}
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setIsShowingNewPayPointModal(false)}
                >
                  Close
                </button>

                {!newPayPoint?.id && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => handleCreatePayPoint()}
                  >
                    Create pay point
                  </button>
                )}
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={!!selectedPayPoint}
            toggle={() => {
              setSelectedPayPoint(undefined)
            }}
            scrollable={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">{selectedPayPoint?.name}</h5>
              <button
                type="button"
                onClick={() => setSelectedPayPoint(undefined)}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <div className="mb-4">
                  <h5 className="font-size-14 mb-1">Pay Point Details</h5>
                  <p className="card-title-desc mb-2">
                    Customers can scan QR codes or click on the link to make
                    in-person payments
                  </p>
                  <hr />

                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={selectedPayPoint?.url}
                  >
                    {selectedPayPoint?.url}
                  </a>

                  {selectedPayPoint && (
                    <img
                      alt="qr code"
                      src={`${process.env.REACT_APP_API_HOST}public/pay-points/${selectedPayPoint?.id}/qr`}
                    />
                  )}

                  <hr />

                  <div>
                    <div className="mb-3">
                      <Label
                        htmlFor="example-text-input"
                        className="form-Label"
                      >
                        Name
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        onChange={(e) =>
                          setSelectedPayPointName(e.target.value)
                        }
                        defaultValue={selectedPayPointName}
                        id="example-text-input"
                      />
                    </div>

                    <Label htmlFor="example-text-input" className="form-Label">
                      Current value
                    </Label>
                    <div className="input-group">
                      <div className="input-group-text">£</div>
                      <Input
                        type="number"
                        min="1"
                        step="any"
                        className="form-control"
                        value={selectedPayPointAmountDecimal}
                        onChange={(e) =>
                          setSelectedPayPointAmountDecimal(e.target.value)
                        }
                        id="specificSizeInputGroupUsername"
                        placeholder="0.00"
                      />
                    </div>
                    <div
                      className="form-check form-switch form-switch-md mt-3"
                      dir="ltr"
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={selectedPayPointIsSticky}
                        onChange={(e) =>
                          setSelectedPayPointIsSticky(e.target.checked)
                        }
                        id="customSwitchsizemd"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="customSwitchsizemd"
                      >
                        This pay point always has same value
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => handleArchivePayPoint()}
              >
                Delete
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleUpdatePayPoint()}
              >
                Save
              </button>
            </div>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PayPointsPage
