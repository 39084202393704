import { GetRequest } from 'src/helpers/lopay_api_helper'
import { RewardChallengeResponsesType } from './support-merchant-reward-challenges.type'

export function getRewardChallenges({
  userId,
}: {
  userId: string
}): Promise<RewardChallengeResponsesType> {
  return GetRequest<RewardChallengeResponsesType>(
    `support/users/${userId}/rewards/challenges`,
  )
}
