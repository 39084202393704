import {
  DeleteRequest,
  GetRequest,
  PostRequest,
} from 'src/helpers/lopay_api_helper'
import { MerchantNotesDto } from './merchant-notes.dto'

export function getMerchantNotes({ merchantId }: { merchantId: string }) {
  return GetRequest<MerchantNotesDto>(`support/merchant/${merchantId}/notes`)
}

export function addMerchantNote({
  merchantId,
  note,
}: {
  merchantId: string
  note: string
}) {
  return PostRequest(`support/merchant/${merchantId}/notes`, {
    merchantId,
    note,
  })
}

export function deleteMerchantNote({
  merchantId,
  noteId,
}: {
  merchantId: string
  noteId: string
}) {
  return DeleteRequest(`support/merchant/${merchantId}/notes/${noteId}`)
}
