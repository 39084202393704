import React, { useEffect, useMemo, useState } from 'react'
import { AvForm } from 'availity-reactstrap-validation'
import {
  Badge,
  Button,
  CardBody,
  CardTitle,
  Collapse,
  Modal,
  Table,
} from 'reactstrap'
import {
  supportFetchMerchantQuickbooksAccounts,
  supportFetchMerchantQuickbooksCompanyInfo,
  supportFetchMerchantQuickbooksConfig,
  supportFetchMerchantQuickbooksCreditMemos,
  supportFetchMerchantQuickbooksCustomers,
  supportFetchMerchantQuickbooksInvoices,
  supportFetchMerchantQuickbooksItems,
  supportFetchMerchantQuickbooksPayments,
  supportFetchMerchantQuickbooksPurchases,
  supportFetchMerchantQuickbooksTaxCodes,
  supportFetchMerchantQuickbooksTransfers,
  supportFetchMerchantQuickbooksVendors,
} from 'src/helpers/lopay_api_helper'
import SupportMerchantApiDto from 'src/api-dtos/support/merchant/support-merchant-api.dto.interface'
import moment from 'moment'
import classnames from 'classnames'

type IComponentProps = {
  merchant: SupportMerchantApiDto | undefined
}

const SupportMerchantQuickbooks: React.FC<IComponentProps> = ({ merchant }) => {
  const [vendors, setVendors] = useState<any[]>([])
  const [vendorsTabOpen, setVendorsTabOpen] = useState(false)
  const [vendorsRawData, setVendorsRawData] = useState([])
  const [vendorsLoaded, setVendorsLoaded] = useState(false)

  const [customers, setCustomers] = useState<any[]>([])
  const [customersTabOpen, setCustomersTabOpen] = useState(false)
  const [customersRawData, setCustomersRawData] = useState([])
  const [customersLoaded, setCustomersLoaded] = useState(false)

  const [invoices, setInvoices] = useState<any[]>([])
  const [invoicesTabOpen, setInvoicesTabOpen] = useState(false)
  const [invoicesRawData, setInvoicesRawData] = useState([])
  const [invoicesLoaded, setInvoicesLoaded] = useState(false)

  const [transfers, setTransfers] = useState<any[]>([])
  const [transfersTabOpen, setTransfersTabOpen] = useState(false)
  const [transfersRawData, setTransfersRawData] = useState([])
  const [transfersLoaded, setTransfersLoaded] = useState(false)

  const [payments, setPayments] = useState<any[]>([])
  const [paymentsTabOpen, setPaymentsTabOpen] = useState(false)
  const [paymentsRawData, setPaymentsRawData] = useState([])
  const [paymentsLoaded, setPaymentsLoaded] = useState(false)

  const [purchases, setPurchases] = useState<any[]>([])
  const [purchasesTabOpen, setPurchasesTabOpen] = useState(false)
  const [purchasesRawData, setPurchasesRawData] = useState([])
  const [purchasesLoaded, setPurchasesLoaded] = useState(false)

  const [creditMemos, setCreditMemos] = useState<any[]>([])
  const [creditMemosTabOpen, setCreditMemosTabOpen] = useState(false)
  const [creditMemosRawData, setCreditMemosRawData] = useState([])
  const [creditMemosLoaded, setCreditMemosLoaded] = useState(false)

  const [taxCodes, setTaxCodes] = useState<any[]>([])
  const [taxCodesTabOpen, setTaxCodesTabOpen] = useState(false)
  const [taxCodesRawData, setTaxCodesRawData] = useState([])
  const [taxCodesLoaded, setTaxCodesLoaded] = useState(false)

  const [items, setItems] = useState<any[]>([])
  const [itemsTabOpen, setItemsTabOpen] = useState(false)
  const [itemsRawData, setItemsRawData] = useState([])
  const [itemsLoaded, setItemsLoaded] = useState(false)

  const [accounts, setAccounts] = useState<any[]>([])
  const [accountsTabOpen, setAccountsTabOpen] = useState(false)
  const [accountsRawData, setAccountsRawData] = useState([])
  const [accountsLoaded, setAccountsLoaded] = useState(false)

  const [companyInfo, setCompanyInfo] = useState<any>(null)
  const [companyInfoTabOpen, setCompanyInfoTabOpen] = useState(false)
  const [companyInfoLoaded, setCompanyInfoLoaded] = useState(false)

  const [config, setConfig] = useState<any>(null)
  const [configTabOpen, setConfigTabOpen] = useState(false)
  const [configLoaded, setConfigLoaded] = useState(false)
  const [configErrored, setConfigErrored] = useState(false)

  const [modalData, setModalData] = useState<any>(null)
  const [modalType, setModalType] = useState<any>(null)
  const [modalOpen, setModalOpen] = useState(false)

  const toggleModal = () => {
    setModalOpen(!modalOpen)
  }

  const openAllTabs = () => {
    setVendorsTabOpen(true)
    setCustomersTabOpen(true)
    setInvoicesTabOpen(true)
    setTransfersTabOpen(true)
    setPaymentsTabOpen(true)
    setPurchasesTabOpen(true)
    setCreditMemosTabOpen(true)
    setTaxCodesTabOpen(true)
    setItemsTabOpen(true)
    setAccountsTabOpen(true)
    setCompanyInfoTabOpen(true)
  }

  const closeAllTabs = () => {
    setVendorsTabOpen(false)
    setCustomersTabOpen(false)
    setInvoicesTabOpen(false)
    setTransfersTabOpen(false)
    setPaymentsTabOpen(false)
    setPurchasesTabOpen(false)
    setCreditMemosTabOpen(false)
    setTaxCodesTabOpen(false)
    setItemsTabOpen(false)
    setAccountsTabOpen(false)
    setCompanyInfoTabOpen(false)
  }

  const toggleVendorsTab = () => {
    setVendorsTabOpen(!vendorsTabOpen)
  }

  const toggleCustomersTab = () => {
    setCustomersTabOpen(!customersTabOpen)
  }

  const toggleInvoicesTab = () => {
    setInvoicesTabOpen(!invoicesTabOpen)
  }

  const toggleTransfersTab = () => {
    setTransfersTabOpen(!transfersTabOpen)
  }

  const togglePaymentsTab = () => {
    setPaymentsTabOpen(!paymentsTabOpen)
  }

  const togglePurchasesTab = () => {
    setPurchasesTabOpen(!purchasesTabOpen)
  }

  const toggleCreditMemosTab = () => {
    setCreditMemosTabOpen(!creditMemosTabOpen)
  }

  const toggleTaxCodesTab = () => {
    setTaxCodesTabOpen(!taxCodesTabOpen)
  }

  const toggleItemsTab = () => {
    setItemsTabOpen(!itemsTabOpen)
  }

  const toggleAccountsTab = () => {
    setAccountsTabOpen(!accountsTabOpen)
  }

  const toggleCompanyInfoTab = () => {
    setCompanyInfoTabOpen(!companyInfoTabOpen)
  }

  const toggleConfigTab = () => {
    setConfigTabOpen(!configTabOpen)
  }

  const loadVendors = React.useCallback(async () => {
    if (!merchant) {
      return
    }

    const res = await supportFetchMerchantQuickbooksVendors({
      merchantId: merchant.id,
    })

    setVendorsLoaded(true)

    if (
      res &&
      res.QueryResponse &&
      res.QueryResponse.Vendor &&
      res.QueryResponse.Vendor.length
    ) {
      setVendorsRawData(res.QueryResponse.Vendor)
      setVendors(
        res.QueryResponse.Vendor.map((v: any) => ({
          id: v.Id,
          name: v.DisplayName,
          balance: v.Balance,
          created: v.MetaData.CreateTime,
        })),
      )
    }
  }, [merchant])

  const loadCustomers = React.useCallback(async () => {
    if (!merchant) {
      return
    }

    const res = await supportFetchMerchantQuickbooksCustomers({
      merchantId: merchant.id,
    })

    setCustomersLoaded(true)

    if (
      res &&
      res.QueryResponse &&
      res.QueryResponse.Customer &&
      res.QueryResponse.Customer.length
    ) {
      setCustomersRawData(res.QueryResponse.Customer)
      setCustomers(
        res.QueryResponse.Customer.map((v: any) => ({
          id: v.Id,
          name: v.DisplayName,
          balance: v.Balance,
          created: v.MetaData.CreateTime,
        })),
      )
    }
  }, [merchant])

  const loadInvoices = React.useCallback(async () => {
    if (!merchant) {
      return
    }

    const res = await supportFetchMerchantQuickbooksInvoices({
      merchantId: merchant.id,
    })

    setInvoicesLoaded(true)

    if (
      res &&
      res.QueryResponse &&
      res.QueryResponse.Invoice &&
      res.QueryResponse.Invoice.length
    ) {
      setInvoicesRawData(res.QueryResponse.Invoice)
      setInvoices(
        res.QueryResponse.Invoice.map((v: any) => ({
          id: v.Id,
          docNumber: v.DocNumber,
          balance: v.Balance,
          created: v.MetaData.CreateTime,
        })),
      )
    }
  }, [merchant])

  const loadTransfers = React.useCallback(async () => {
    if (!merchant) {
      return
    }

    const res = await supportFetchMerchantQuickbooksTransfers({
      merchantId: merchant.id,
    })

    setTransfersLoaded(true)

    if (
      res &&
      res.QueryResponse &&
      res.QueryResponse.Transfer &&
      res.QueryResponse.Transfer.length
    ) {
      setTransfersRawData(res.QueryResponse.Transfer)
      setTransfers(
        res.QueryResponse.Transfer.map((t: any) => ({
          id: t.Id,
          from: t.FromAccountRef.name,
          to: t.ToAccountRef.name,
          amount: t.Amount,
          created: t.MetaData.CreateTime,
        })),
      )
    }
  }, [merchant])

  const loadPayments = React.useCallback(async () => {
    if (!merchant) {
      return
    }

    const res = await supportFetchMerchantQuickbooksPayments({
      merchantId: merchant.id,
    })

    setPaymentsLoaded(true)

    if (
      res &&
      res.QueryResponse &&
      res.QueryResponse.Payment &&
      res.QueryResponse.Payment.length
    ) {
      setPaymentsRawData(res.QueryResponse.Payment)
      setPayments(
        res.QueryResponse.Payment.map((t: any) => ({
          id: t.Id,
          customer: t.CustomerRef.name,
          amount: t.TotalAmt,
          created: t.MetaData.CreateTime,
        })),
      )
    }
  }, [merchant])

  const loadPurchases = React.useCallback(async () => {
    if (!merchant) {
      return
    }

    const res = await supportFetchMerchantQuickbooksPurchases({
      merchantId: merchant.id,
    })

    setPurchasesLoaded(true)

    if (
      res &&
      res.QueryResponse &&
      res.QueryResponse.Purchase &&
      res.QueryResponse.Purchase.length
    ) {
      setPurchasesRawData(res.QueryResponse.Purchase)
      setPurchases(
        res.QueryResponse.Purchase.map((t: any) => ({
          id: t.Id,
          account: t.AccountRef.name,
          amount: t.TotalAmt,
          created: t.MetaData.CreateTime,
        })),
      )
    }
  }, [merchant])

  const loadCreditMemos = React.useCallback(async () => {
    if (!merchant) {
      return
    }

    const res = await supportFetchMerchantQuickbooksCreditMemos({
      merchantId: merchant.id,
    })

    setCreditMemosLoaded(true)

    if (
      res &&
      res.QueryResponse &&
      res.QueryResponse.CreditMemo &&
      res.QueryResponse.CreditMemo.length
    ) {
      setCreditMemosRawData(res.QueryResponse.CreditMemo)
      setCreditMemos(
        res.QueryResponse.CreditMemo.map((t: any) => ({
          id: t.Id,
          account: t.AccountRef.name,
          amount: t.TotalAmt,
          created: t.MetaData.CreateTime,
        })),
      )
    }
  }, [merchant])

  const loadTaxCodes = React.useCallback(async () => {
    if (!merchant) {
      return
    }

    const res = await supportFetchMerchantQuickbooksTaxCodes({
      merchantId: merchant.id,
    })

    setTaxCodesLoaded(true)

    if (
      res &&
      res.QueryResponse &&
      res.QueryResponse.TaxCode &&
      res.QueryResponse.TaxCode.length
    ) {
      setTaxCodesRawData(res.QueryResponse.TaxCode)
      setTaxCodes(
        res.QueryResponse.TaxCode.map((t: any) => ({
          id: t.Id,
          name: t.Name,
          created: t.MetaData.CreateTime,
        })),
      )
    }
  }, [merchant])

  const loadItems = React.useCallback(async () => {
    if (!merchant) {
      return
    }

    const res = await supportFetchMerchantQuickbooksItems({
      merchantId: merchant.id,
    })

    setItemsLoaded(true)

    if (
      res &&
      res.QueryResponse &&
      res.QueryResponse.Item &&
      res.QueryResponse.Item.length
    ) {
      setItemsRawData(res.QueryResponse.Item)
      setItems(
        res.QueryResponse.Item.map((t: any) => ({
          id: t.Id,
          name: t.Name,
          created: t.MetaData.CreateTime,
        })),
      )
    }
  }, [merchant])

  const loadAccounts = React.useCallback(async () => {
    if (!merchant) {
      return
    }

    const res = await supportFetchMerchantQuickbooksAccounts({
      merchantId: merchant.id,
    })

    setAccountsLoaded(true)

    if (
      res &&
      res.QueryResponse &&
      res.QueryResponse.Account &&
      res.QueryResponse.Account.length
    ) {
      setAccountsRawData(res.QueryResponse.Account)
      setAccounts(
        res.QueryResponse.Account.map((t: any) => ({
          id: t.Id,
          name: t.Name,
          balance: t.CurrentBalance,
          created: t.MetaData.CreateTime,
        })),
      )
    }
  }, [merchant])

  const loadCompanyInfo = React.useCallback(async () => {
    if (!merchant) {
      return
    }

    const res = await supportFetchMerchantQuickbooksCompanyInfo({
      merchantId: merchant.id,
    })

    setCompanyInfoLoaded(true)

    if (res.CompanyInfo) {
      setCompanyInfo(res.CompanyInfo)
    }
  }, [merchant])

  const loadConfig = React.useCallback(async () => {
    if (!merchant) {
      return
    }

    const res = await supportFetchMerchantQuickbooksConfig({
      merchantId: merchant.id,
    })
    setConfigLoaded(true)
    setConfig(res)

    if (res.message) {
      setConfigErrored(true)
    } else {
      setConfigErrored(false)
    }
  }, [merchant])

  const onItemClicked = (
    type:
      | 'Customer'
      | 'Vendor'
      | 'Invoice'
      | 'Transfer'
      | 'Payment'
      | 'Purchase'
      | 'CreditMemo'
      | 'TaxCode'
      | 'Item'
      | 'Account',
    id: string,
  ) => {
    let data
    switch (type) {
      case 'Customer':
        data = customersRawData.find((i: any) => i.Id === id)
        break
      case 'Vendor':
        data = vendorsRawData.find((i: any) => i.Id === id)
        break
      case 'Invoice':
        data = invoicesRawData.find((i: any) => i.Id === id)
        break
      case 'Transfer':
        data = transfersRawData.find((i: any) => i.Id === id)
        break
      case 'Payment':
        data = paymentsRawData.find((i: any) => i.Id === id)
        break
      case 'Purchase':
        data = purchasesRawData.find((i: any) => i.Id === id)
        break
      case 'CreditMemo':
        data = creditMemosRawData.find((i: any) => i.Id === id)
        break
      case 'TaxCode':
        data = taxCodesRawData.find((i: any) => i.Id === id)
        break
      case 'Item':
        data = itemsRawData.find((i: any) => i.Id === id)
        break
      case 'Account':
        data = accountsRawData.find((i: any) => i.Id === id)
        break
    }
    setModalData(data)
    setModalType(type)
    setModalOpen(true)
  }

  const hasLopayVendor = useMemo(
    () => vendors.find((v) => v.name === 'Lopay (Supplier)'),
    [vendors],
  )
  const hasLopayCustomer = useMemo(
    () => customers.find((c) => c.name === 'Lopay'),
    [customers],
  )

  useEffect(() => {
    if (!merchant) {
      return
    }

    loadConfig()
    loadVendors()
    loadCustomers()
    loadInvoices()
    loadTransfers()
    loadPayments()
    loadPurchases()
    loadCreditMemos()
    loadTaxCodes()
    loadItems()
    loadAccounts()
    loadCompanyInfo()
  }, [
    loadVendors,
    loadCustomers,
    loadInvoices,
    loadTransfers,
    loadPayments,
    loadPurchases,
    loadCreditMemos,
    loadTaxCodes,
    loadItems,
    loadAccounts,
    loadCompanyInfo,
    loadConfig,
    merchant,
  ])

  if (
    !vendorsLoaded ||
    !customersLoaded ||
    !invoicesLoaded ||
    !transfersLoaded ||
    !paymentsLoaded ||
    !purchasesLoaded ||
    !creditMemosLoaded ||
    !taxCodesLoaded ||
    !itemsLoaded ||
    !accountsLoaded ||
    !companyInfoLoaded ||
    !configLoaded
  ) {
    return (
      <CardBody>
        <AvForm>
          <CardTitle>Quickbooks</CardTitle>

          {configErrored ? (
            <div>
              <p>Config Error</p>
              <p>{config.message}</p>
            </div>
          ) : (
            <div>Loading Data...</div>
          )}
        </AvForm>
      </CardBody>
    )
  } else {
    return (
      <CardBody>
        <AvForm>
          <CardTitle>Quickbooks</CardTitle>
          <div
            style={{
              display: 'flex',
              gap: '1em',
              paddingTop: '0.5em',
              paddingBottom: '1em',
            }}
          >
            <Button type="button" color="primary" onClick={openAllTabs}>
              Open All Tabs
            </Button>
            <Button type="button" color="primary" onClick={closeAllTabs}>
              Close All Tabs
            </Button>
          </div>
          <div className="accordion" id="accordion">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className={classnames('accordion-button', 'fw-medium', {
                    collapsed: !vendorsTabOpen,
                  })}
                  type="button"
                  onClick={toggleVendorsTab}
                  style={{ cursor: 'pointer' }}
                >
                  <span style={{ marginRight: '2px' }}>
                    Vendors ({vendors.length})
                  </span>
                  <Badge
                    className={classnames('me-2', {
                      'bg-success': hasLopayVendor,
                      'bg-danger': !hasLopayVendor,
                    })}
                  >
                    {hasLopayVendor ? 'Has Lopay Vendor' : 'No Lopay Vendor'}
                  </Badge>
                </button>
              </h2>

              <Collapse isOpen={vendorsTabOpen} className="accordion-collapse">
                <div className="accordion-body">
                  <Table responsive striped className="mb-0">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Balance</th>
                        <th>Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(vendors ?? []).map((vendor) => {
                        return (
                          <tr
                            key={vendor.id}
                            onClick={() => onItemClicked('Vendor', vendor.id)}
                          >
                            <td>{vendor.name}</td>
                            <td>{vendor.balance}</td>
                            <td>
                              {moment(vendor.created)
                                .local()
                                .format('DD-MM-YY HH:mm')}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </Collapse>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className={classnames('accordion-button', 'fw-medium', {
                    collapsed: !customersTabOpen,
                  })}
                  type="button"
                  onClick={toggleCustomersTab}
                  style={{ cursor: 'pointer' }}
                >
                  <span style={{ marginRight: '2px' }}>
                    Customers ({customers.length})
                  </span>
                  <Badge
                    className={classnames('me-2', {
                      'bg-success': hasLopayCustomer,
                      'bg-danger': !hasLopayCustomer,
                    })}
                  >
                    {hasLopayCustomer
                      ? 'Has Lopay Customer'
                      : 'No Lopay Customer'}
                  </Badge>
                </button>
              </h2>

              <Collapse
                isOpen={customersTabOpen}
                className="accordion-collapse"
              >
                <div className="accordion-body">
                  <Table responsive striped className="mb-0">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Balance</th>
                        <th>Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(customers ?? []).map((customer) => {
                        return (
                          <tr
                            key={customer.id}
                            onClick={() =>
                              onItemClicked('Customer', customer.id)
                            }
                          >
                            <td>{customer.name}</td>
                            <td>{customer.balance}</td>
                            <td>
                              {moment(customer.created)
                                .local()
                                .format('DD-MM-YY HH:mm')}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </Collapse>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className={classnames('accordion-button', 'fw-medium', {
                    collapsed: !invoicesTabOpen,
                  })}
                  type="button"
                  onClick={toggleInvoicesTab}
                  style={{ cursor: 'pointer' }}
                >
                  <span style={{ marginRight: '2px' }}>
                    Invoices ({invoices.length})
                  </span>
                </button>
              </h2>

              <Collapse isOpen={invoicesTabOpen} className="accordion-collapse">
                <div className="accordion-body">
                  <Table responsive striped className="mb-0">
                    <thead>
                      <tr>
                        <th>Doc Number</th>
                        <th>Balance</th>
                        <th>Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(invoices ?? []).map((invoice) => {
                        return (
                          <tr
                            key={invoice.id}
                            onClick={() => onItemClicked('Invoice', invoice.id)}
                          >
                            <td>{invoice.docNumber}</td>
                            <td>{invoice.balance}</td>
                            <td>
                              {moment(invoice.created)
                                .local()
                                .format('DD-MM-YY HH:mm')}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </Collapse>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className={classnames('accordion-button', 'fw-medium', {
                    collapsed: !transfersTabOpen,
                  })}
                  type="button"
                  onClick={toggleTransfersTab}
                  style={{ cursor: 'pointer' }}
                >
                  <span style={{ marginRight: '2px' }}>
                    Transfers ({transfers.length})
                  </span>
                </button>
              </h2>

              <Collapse
                isOpen={transfersTabOpen}
                className="accordion-collapse"
              >
                <div className="accordion-body">
                  <Table responsive striped className="mb-0">
                    <thead>
                      <tr>
                        <th>From</th>
                        <th>To</th>
                        <th>Amount</th>
                        <th>Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(transfers ?? []).map((transfer) => {
                        return (
                          <tr
                            key={transfer.id}
                            onClick={() =>
                              onItemClicked('Transfer', transfer.id)
                            }
                          >
                            <td>{transfer.from}</td>
                            <td>{transfer.to}</td>
                            <td>{transfer.amount}</td>
                            <td>
                              {moment(transfer.created)
                                .local()
                                .format('DD-MM-YY HH:mm')}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </Collapse>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className={classnames('accordion-button', 'fw-medium', {
                    collapsed: !paymentsTabOpen,
                  })}
                  type="button"
                  onClick={togglePaymentsTab}
                  style={{ cursor: 'pointer' }}
                >
                  <span style={{ marginRight: '2px' }}>
                    Payments ({payments.length})
                  </span>
                </button>
              </h2>

              <Collapse isOpen={paymentsTabOpen} className="accordion-collapse">
                <div className="accordion-body">
                  <Table responsive striped className="mb-0">
                    <thead>
                      <tr>
                        <th>Customer</th>
                        <th>Amount</th>
                        <th>Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(payments ?? []).map((payment) => {
                        return (
                          <tr
                            key={payment.id}
                            onClick={() => onItemClicked('Payment', payment.id)}
                          >
                            <td>{payment.customer}</td>
                            <td>{payment.amount}</td>
                            <td>
                              {moment(payment.created)
                                .local()
                                .format('DD-MM-YY HH:mm')}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </Collapse>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className={classnames('accordion-button', 'fw-medium', {
                    collapsed: !purchasesTabOpen,
                  })}
                  type="button"
                  onClick={togglePurchasesTab}
                  style={{ cursor: 'pointer' }}
                >
                  <span style={{ marginRight: '2px' }}>
                    Purchases ({purchases.length})
                  </span>
                </button>
              </h2>

              <Collapse
                isOpen={purchasesTabOpen}
                className="accordion-collapse"
              >
                <div className="accordion-body">
                  <Table responsive striped className="mb-0">
                    <thead>
                      <tr>
                        <th>Account</th>
                        <th>Amount</th>
                        <th>Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(purchases ?? []).map((purchase) => {
                        return (
                          <tr
                            key={purchase.id}
                            onClick={() =>
                              onItemClicked('Purchase', purchase.id)
                            }
                          >
                            <td>{purchase.account}</td>
                            <td>{purchase.amount}</td>
                            <td>
                              {moment(purchase.created)
                                .local()
                                .format('DD-MM-YY HH:mm')}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </Collapse>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className={classnames('accordion-button', 'fw-medium', {
                    collapsed: !creditMemosTabOpen,
                  })}
                  type="button"
                  onClick={toggleCreditMemosTab}
                  style={{ cursor: 'pointer' }}
                >
                  <span style={{ marginRight: '2px' }}>
                    Credit Memos ({creditMemos.length})
                  </span>
                </button>
              </h2>

              <Collapse
                isOpen={creditMemosTabOpen}
                className="accordion-collapse"
              >
                <div className="accordion-body">
                  <Table responsive striped className="mb-0">
                    <thead>
                      <tr>
                        <th>Doc Number</th>
                        <th>Amount</th>
                        <th>Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(creditMemos ?? []).map((creditMemo) => {
                        return (
                          <tr
                            key={creditMemo.id}
                            onClick={() =>
                              onItemClicked('CreditMemo', creditMemo.id)
                            }
                          >
                            <td>{creditMemo.docNumber}</td>
                            <td>{creditMemo.balance}</td>
                            <td>
                              {moment(creditMemo.created)
                                .local()
                                .format('DD-MM-YY HH:mm')}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </Collapse>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className={classnames('accordion-button', 'fw-medium', {
                    collapsed: !taxCodesTabOpen,
                  })}
                  type="button"
                  onClick={toggleTaxCodesTab}
                  style={{ cursor: 'pointer' }}
                >
                  <span style={{ marginRight: '2px' }}>
                    Tax Codes ({taxCodes.length})
                  </span>
                </button>
              </h2>

              <Collapse isOpen={taxCodesTabOpen} className="accordion-collapse">
                <div className="accordion-body">
                  <Table responsive striped className="mb-0">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(taxCodes ?? []).map((taxCode) => {
                        return (
                          <tr
                            key={taxCode.id}
                            onClick={() => onItemClicked('TaxCode', taxCode.id)}
                          >
                            <td>{taxCode.name}</td>
                            <td>
                              {moment(taxCode.created)
                                .local()
                                .format('DD-MM-YY HH:mm')}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </Collapse>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className={classnames('accordion-button', 'fw-medium', {
                    collapsed: !itemsTabOpen,
                  })}
                  type="button"
                  onClick={toggleItemsTab}
                  style={{ cursor: 'pointer' }}
                >
                  <span style={{ marginRight: '2px' }}>
                    Items ({items.length})
                  </span>
                </button>
              </h2>

              <Collapse isOpen={itemsTabOpen} className="accordion-collapse">
                <div className="accordion-body">
                  <Table responsive striped className="mb-0">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(items ?? []).map((item) => {
                        return (
                          <tr
                            key={item.id}
                            onClick={() => onItemClicked('Item', item.id)}
                          >
                            <td>{item.name}</td>
                            <td>
                              {moment(item.created)
                                .local()
                                .format('DD-MM-YY HH:mm')}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </Collapse>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className={classnames('accordion-button', 'fw-medium', {
                    collapsed: !accountsTabOpen,
                  })}
                  type="button"
                  onClick={toggleAccountsTab}
                  style={{ cursor: 'pointer' }}
                >
                  <span style={{ marginRight: '2px' }}>
                    Accounts ({accounts.length})
                  </span>
                </button>
              </h2>

              <Collapse isOpen={accountsTabOpen} className="accordion-collapse">
                <div className="accordion-body">
                  <Table responsive striped className="mb-0">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Balance</th>
                        <th>Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(accounts ?? []).map((account) => {
                        return (
                          <tr
                            key={account.id}
                            onClick={() => onItemClicked('Account', account.id)}
                          >
                            <td>{account.name}</td>
                            <td>{account.balance}</td>
                            <td>
                              {moment(account.created)
                                .local()
                                .format('DD-MM-YY HH:mm')}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </div>
              </Collapse>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className={classnames('accordion-button', 'fw-medium', {
                    collapsed: !companyInfoTabOpen,
                  })}
                  type="button"
                  onClick={toggleCompanyInfoTab}
                  style={{ cursor: 'pointer' }}
                >
                  <span style={{ marginRight: '2px' }}>Company Info</span>
                </button>
              </h2>

              <Collapse
                isOpen={companyInfoTabOpen}
                className="accordion-collapse"
              >
                <div className="accordion-body">
                  <pre
                    style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
                  >
                    {JSON.stringify(companyInfo, null, 2)}
                  </pre>
                </div>
              </Collapse>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className={classnames('accordion-button', 'fw-medium', {
                    collapsed: !configTabOpen,
                  })}
                  type="button"
                  onClick={toggleConfigTab}
                  style={{ cursor: 'pointer' }}
                >
                  <span style={{ marginRight: '2px' }}>Config</span>
                </button>
              </h2>

              <Collapse isOpen={configTabOpen} className="accordion-collapse">
                <div className="accordion-body">
                  <pre
                    style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}
                  >
                    {JSON.stringify(config, null, 2)}
                  </pre>
                </div>
              </Collapse>
            </div>
          </div>

          {/* <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                    {JSON.stringify(vendors, null, 2)}
                </pre> */}

          <Modal
            isOpen={modalOpen}
            toggle={() => {
              toggleModal()
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                {modalType}:{' '}
                {modalData?.DisplayName ||
                  modalData?.DocNumber ||
                  modalData?.Id}
              </h5>
              <button
                type="button"
                onClick={() => {
                  setModalOpen(false)
                  setModalData(null)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                {JSON.stringify(modalData, null, 2)}
              </pre>
            </div>
          </Modal>
        </AvForm>
      </CardBody>
    )
  }
}

export default SupportMerchantQuickbooks
