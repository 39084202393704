import React from 'react'
import { Spinner } from 'reactstrap'
import styled from 'styled-components'

import { useMerchantAccountSetup } from './support-merchant-account-setup.hook'
import AccountSetupSection from './components/account-setup-section.component'

const StyledAccountSetupContainer = styled.div``

type IComponentProps = {
  merchantId: string
  isActive: boolean
}

const SupportMerchantAccountSetup: React.FunctionComponent<IComponentProps> = ({
  merchantId,
  isActive,
}) => {
  const result = useMerchantAccountSetup({
    merchantId,
    isActiveTab: isActive,
  })

  if (result.isLoading || !result.data) {
    return <Spinner className="ms-2" color="primary" />
  }

  if (result.isError) {
    return (
      <>
        Something went wrong whilst loading onboarding progress. Please refresh
        the page.
      </>
    )
  }

  const { gettingYouStarted, requiredByYou, aboutYou } = result.data

  return (
    <StyledAccountSetupContainer>
      <AccountSetupSection
        title="Required to get paid"
        items={[
          {
            title: 'Complete registration',
            subtitle: 'Congrats on joining Lopay',
            checked: true,
          },
          {
            title: 'Complete identity verification',
            subtitle: requiredByYou.identityVerification.verified
              ? 'Verified'
              : 'Unverified',
            checked: requiredByYou.identityVerification.verified,
          },
          {
            title: 'Add bank account',
            subtitle: requiredByYou.bankAccount.bankName
              ? `Added bank account named ${requiredByYou.bankAccount.bankName}`
              : 'No bank account added',
            checked: !!requiredByYou.bankAccount.bankName,
          },
        ]}
      />
      <AccountSetupSection
        title="Getting started"
        items={[
          {
            title: 'Choose your payment method',
            subtitle: gettingYouStarted.yourJourney?.skipped
              ? 'Skipped'
              : gettingYouStarted.yourJourney.journeys.join(', '),
            checked:
              gettingYouStarted.yourJourney?.skipped ||
              gettingYouStarted.yourJourney.journeys.length > 0,
          },
          {
            title: 'Set up your payment method',
            subtitle: gettingYouStarted.pairWithDevice?.skipped
              ? 'Skipped'
              : '',
            checked:
              gettingYouStarted.pairWithDevice?.skipped ||
              gettingYouStarted.pairWithDevice.paired,
          },
          {
            title: 'Take your first payment',
            subtitle: '',
            checked: gettingYouStarted.takePayment.done,
          },
        ]}
      />
      <AccountSetupSection
        title="Learning about you"
        items={[
          {
            title: 'Your main business',
            subtitle: aboutYou.businessType.name ?? '',
            checked: !!aboutYou.businessType.name,
          },
          {
            title: 'Expected business income',
            subtitle: aboutYou.expectedIncome.isSet
              ? aboutYou.expectedIncome.name!
              : '',
            checked: aboutYou.expectedIncome.isSet,
          },
          {
            title: 'Who have you switched from?',
            subtitle: aboutYou.previousProvider?.skipped
              ? 'Skipped'
              : (aboutYou.previousProvider.name ?? ''),
            checked:
              aboutYou.previousProvider?.skipped ||
              Boolean(aboutYou.previousProvider.name),
          },
        ]}
      />
    </StyledAccountSetupContainer>
  )
}

export default SupportMerchantAccountSetup
