import React, { useState, useEffect, useCallback } from 'react'
import { Badge, Button, Table } from 'reactstrap'
import styled from 'styled-components'

import Currency from '../../../api-dtos/types/currency.type'
import { PayoutStatusType } from '../../../api-dtos/types/payout-status.type'
import { PayoutMechanismType } from '../../../api-dtos/types/payout-mechanism.type'
import { SupportMerchantPayoutDto } from '../../../api-dtos/payout/support-merchant-payout.dto'
import { listPayoutsForMerchant } from '../../../helpers/lopay_api_helper'
import { priceFormat } from '../../../helpers/utils'
import moment from 'moment'
import PayoutDetailsModal from 'src/pages/Merchant/support-merchant-payouts/components/payout-details-modal/payout-details-modal.component'

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`

const Spacer = styled.div`
  padding: 10px 0;
`

interface IComponentProps {
  merchantId: string
  startDate: Date | undefined
  endDate: Date | undefined
  currencyCode: Currency | undefined
  payoutMechanism: PayoutMechanismType | undefined
  status: PayoutStatusType | undefined
  payoutUpdated: () => void
}

const PayoutTable: React.FC<IComponentProps> = ({
  merchantId,
  startDate,
  endDate,
  currencyCode,
  payoutMechanism,
  status,
}) => {
  const [selectedPayoutId, setSelectedPayoutId] = useState<string | undefined>(
    undefined,
  )
  const [loading] = useState<boolean>(false)
  const [showLoadMoreButton, setLoadMoreButton] = useState<boolean>(false)
  const [totalPayoutCount, setTotalPayoutCount] = useState<number>(0)
  const [payouts, setPayouts] = useState<SupportMerchantPayoutDto[]>([])

  const closePayoutModal = useCallback(() => {
    setSelectedPayoutId(undefined)
  }, [setSelectedPayoutId])

  const loadPayouts = useCallback(async () => {
    const { data, count } = await listPayoutsForMerchant({
      merchantId,
      startDate,
      endDate,
      currencyCode,
      payoutMechanism,
      status,
      offsetId: undefined,
    })

    setPayouts(data)
    setTotalPayoutCount(count)

    if (count > 0) {
      setLoadMoreButton(true)
    }
  }, [merchantId, startDate, endDate, currencyCode, payoutMechanism, status])

  const loadMore = async () => {
    if (payouts.length === 0) {
      return
    }

    const lastPayout = payouts[payouts.length - 1]
    const { data, count } = await listPayoutsForMerchant({
      merchantId,
      startDate,
      endDate,
      currencyCode,
      payoutMechanism,
      status,
      offsetId: lastPayout.id,
    })

    setPayouts((previousPayouts) => previousPayouts.concat(data))
    setTotalPayoutCount(count)
  }

  useEffect(() => {
    loadPayouts()
  }, [
    loadPayouts,
    merchantId,
    startDate,
    endDate,
    currencyCode,
    payoutMechanism,
    status,
  ])

  useEffect(() => {
    if (payouts.length === totalPayoutCount) {
      setLoadMoreButton(false)
    }
  }, [payouts, totalPayoutCount])

  return (
    <React.Fragment>
      <div className="table-responsive">
        {payouts.length > 0 ? (
          <Table className="table table-striped mb-0">
            <thead>
              <tr>
                <th>Created at</th>
                <th>Bank details</th>
                <th>Amount</th>
                <th>Incl. Transaction Fees</th>
                <th>Additional payout fee</th>
                <th>Details</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {payouts.map((payout) => {
                const {
                  status,
                  method,
                  payoutMechanism,
                  failureCode,
                  failureMessage,
                  type,
                } = payout

                return (
                  <tr key={payout.id}>
                    <td>
                      {moment(new Date(payout.createdAt)).format(
                        'MMM Do YYYY h:mm:ss a',
                      )}
                    </td>
                    <td>{payout.account.description}</td>
                    <td>{priceFormat(payout.amount)}</td>
                    <td>
                      {payout.transactionFees
                        ? priceFormat(payout.transactionFees)
                        : '---'}
                    </td>
                    <td>
                      {payout.additionalPayoutFee
                        ? priceFormat(payout.additionalPayoutFee)
                        : '---'}
                    </td>
                    <td>
                      {payoutMechanism === 'stripe' && (
                        <Badge className="me-2 bg-info">via Stripe</Badge>
                      )}
                      {payoutMechanism === 'open_banking' && (
                        <Badge className="me-2 bg-info">via VRP</Badge>
                      )}
                      {payoutMechanism === 'true_layer' && (
                        <Badge className="me-2 bg-info">via TrueLayer</Badge>
                      )}
                      {method === 'instant' && (
                        <Badge className="me-2 bg-success">Instant</Badge>
                      )}
                      {method === 'standard' && (
                        <Badge className="me-2 bg-warning">Standard</Badge>
                      )}
                      {type === 'manual' && (
                        <Badge className="me-2 bg-primary">Manual</Badge>
                      )}
                    </td>
                    <td>
                      {(status === 'paid' ||
                        status === 'AcceptedSettlementCompleted' ||
                        status === 'AcceptedCreditSettlementCompleted') && (
                        <Badge className="me-2 bg-success">Paid</Badge>
                      )}
                      {(status === 'pending' ||
                        status === 'in_transit' ||
                        status === 'AcceptedSettlementInProcess' ||
                        status === 'Pending') && (
                        <Badge className="me-2 bg-warning">Pending</Badge>
                      )}
                      {(status === 'failed' || status === 'Rejected') && (
                        <Badge className="me-2 bg-danger">
                          {`Failed - ${failureCode} ${failureMessage?.substring(
                            0,
                            15,
                          )}`.trim()}
                        </Badge>
                      )}
                      {status === 'canceled' && (
                        <Badge className="me-2 bg-danger">Canceled</Badge>
                      )}
                    </td>
                    <td>
                      <Button
                        disabled={loading}
                        className="btn btn-primary"
                        onClick={() => setSelectedPayoutId(payout.id)}
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        ) : (
          <CenterContainer>No results</CenterContainer>
        )}
      </div>
      <Spacer />
      {showLoadMoreButton && (
        <CenterContainer>
          <Button color="secondary" onClick={() => loadMore()}>
            Load more
          </Button>
        </CenterContainer>
      )}
      <PayoutDetailsModal
        merchantId={merchantId}
        payoutId={selectedPayoutId}
        toggle={closePayoutModal}
      />
    </React.Fragment>
  )
}

export default PayoutTable
