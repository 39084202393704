import { GetRequest, PostRequest } from 'src/helpers/lopay_api_helper'
import { PaymentFeesResponseDto } from './support-merchant-payments.type'
import ListTransactionsAPIDto from 'src/api-dtos/transaction/list-transactionss.dto.interface'

export function getPaymentFees({
  paymentId,
}: {
  paymentId: string
}): Promise<PaymentFeesResponseDto> {
  return GetRequest(`support/payments/${paymentId}/fees`)
}

export function getPayments(params: {
  merchantId: string
  payPointId?: string
  startDate?: string
  endDate?: string
  inviteId?: string
  pageSize: number
}) {
  return function ({
    pageParam,
  }: {
    pageParam?: string
  }): Promise<ListTransactionsAPIDto> {
    return GetRequest<ListTransactionsAPIDto>('/support/payments', {
      params: {
        ...params,
        ...(pageParam && { offsetTransactionId: pageParam }),
      },
    })
  }
}

export function refundPayment({
  paymentId,
  amountUnits,
}: {
  paymentId: string
  amountUnits: number
}): Promise<void> {
  return PostRequest<void>(`/support/payments/${paymentId}/refund`, {
    amount: amountUnits,
  })
}
