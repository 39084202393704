import React, { useMemo, useState } from 'react'
import {
  Alert,
  Badge,
  CardBody,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
} from 'reactstrap'
import { AvForm } from 'availity-reactstrap-validation'

import MerchantKYCStatus from '../../../api-dtos/support/merchant/kyc-status-api.dto.interface'
import KYCStatusSelect from './components/KYCStatusSelect/KYCStatusSelect'
import SupportMerchantApiDto from 'src/api-dtos/support/merchant/support-merchant-api.dto.interface'
import UnderReviewToggle from './components/UnderReviewToggle/UnderReviewToggle'
import BlockMaintainedToggle from './components/BlockMaintainedToggle/BlockMaintainedToggle'
import { setBlockMaintained } from './components/BlockMaintainedToggle/set-block-maintained.api'
import {
  supportRequestAdditionalDocs,
  supportToggle3DS2PaymentLimits,
  supportToggle3DS2Payments,
  supportToggleCanOrderCardReader,
  supportToggleOnlinePaymentsEnabled,
  supportUnRequestAdditionalDocs,
  supportUpdateInstantPayoutLimits,
} from 'src/helpers/lopay_api_helper'
import ExternalAccountDto from 'src/api-dtos/external-account/external-account.dto.interface'
import ConfirmationModal from 'src/components/Common/ConfirmationModal/ConfirmationModal'
import {
  useUpdateAdditionalPayoutDelayMutation,
  useUpdateAllowForeignCardPaymentsMutation,
  useUpdateAllowForeignEcommercePaymentsMutation,
  useUpdateAllowTTPPaymentsMutation,
  useUpdateInstantPayoutsEnabledMutation,
} from './support-merchant-kyb.hook'
import MerchantKYCStatusApiDto from '../../../api-dtos/support/merchant/kyc-status-api.dto.interface'

type AdditionalDocumentVerificationType =
  | 'requested_payouts_charges_blocked'
  | 'requested_charges_blocked'
  | 'requested_payouts_blocked'
  | 'requested_no_blocks'
  | 'requested'
  | 'not_requested'

const additionalDocumentVerificationChoices = [
  {
    value: 'not_requested',
    label: 'Not requested',
  },
  {
    value: `requested_no_blocks`,
    label: 'Requested (No blocks)',
  },
  {
    value: `requested_payouts_blocked`,
    label: 'Requested (Payouts blocked)',
  },
  {
    value: `requested_charges_blocked`,
    label: 'Requested (Charges blocked)',
  },
  {
    value: `requested_payouts_charges_blocked`,
    label: 'Requested (Payouts & payments blocked)',
  },
]

type IComponentProps = {
  merchantId: string
  merchant: SupportMerchantApiDto
  externalAccounts: ExternalAccountDto[] | null
  kycStatus: MerchantKYCStatus | undefined
  setKycStatus: (
    value: React.SetStateAction<MerchantKYCStatusApiDto | undefined>,
  ) => void
  setMerchant: (merchant: SupportMerchantApiDto) => void
  syncWithStripe: () => Promise<void>
  isSyncingWithStripe: boolean
}

const SupportMerchantKYB: React.FC<IComponentProps> = ({
  merchantId,
  merchant,
  kycStatus,
  setKycStatus,
  externalAccounts,
  setMerchant,
  syncWithStripe,
  isSyncingWithStripe,
}) => {
  const {
    mutate: updateInstantPayoutsEnabledMutate,
    isLoading: isLoadingUpdateInstantPayoutsEnabled,
  } = useUpdateInstantPayoutsEnabledMutation({
    merchantId,
    setKycStatus,
  })

  const {
    mutate: updateAllowForeignEcommercePaymentsMutate,
    isLoading: isLoadingUpdateAllowForeignEcommercePayments,
  } = useUpdateAllowForeignEcommercePaymentsMutation({
    merchantId,
    setKycStatus,
  })

  const {
    mutate: updateAllowForeignCardPaymentsMutate,
    isLoading: isLoadingUpdateAllowForeignCardPayments,
  } = useUpdateAllowForeignCardPaymentsMutation({
    merchantId,
    setKycStatus,
  })

  const {
    mutate: updateAdditionalPayoutDelayMutate,
    isLoading: isLoadingUpdateAdditionalPayoutDelay,
  } = useUpdateAdditionalPayoutDelayMutation({
    merchantId,
    setKycStatus,
  })

  const {
    mutate: updateAllowTTPPaymentsMutate,
    isLoading: isLoadingUpdateAllowTTPPaymentsMutate,
  } = useUpdateAllowTTPPaymentsMutation({
    merchantId,
    setKycStatus,
  })

  const [isBlockMaintainedModalOpen, setIsBlockMaintainedModalOpen] =
    useState(false)

  const [updatingKycLimits, setUpdatingKycLimits] = useState<boolean>(false)
  const [updatingInstantPayoutLimits, setUpdatingInstantPayoutLimits] =
    useState<boolean>(false)

  // KYC checkbox loading states:
  const [loadingFybEnforce3DS2, setLoadingFybEnforce3DS2] =
    useState<boolean>(false)
  const [loadingOnlinePaymentsEnabled, setLoadingOnlinePaymentsEnabled] =
    useState<boolean>(false)
  const [loadingCanOrderCardReader, setLoadingCanOrderCardReader] =
    useState<boolean>(false)

  const toggleBlockMaintainedModal = () => {
    setIsBlockMaintainedModalOpen((prevState) => !prevState)
  }

  const notReviewed = useMemo(
    () =>
      merchant.kybDisabledCharges === undefined ||
      merchant.kybDisabledCharges === null,
    [merchant],
  )

  const toggleBlockMaintained = async (status: boolean): Promise<void> => {
    if (kycStatus) {
      await setBlockMaintained(merchantId, status)
        .then(() => {
          setKycStatus({
            ...kycStatus,
            blockMaintained: status,
          })
        })
        .catch((error) => {
          alert('Could not update block maintained status: ' + error.message)
        })
    }
  }

  const setMerchantKYCStatus = (status?: boolean) => {
    if (merchant) {
      setMerchant({
        ...merchant,
        kybDisabledCharges: status,
      })

      if (kycStatus) {
        setKycStatus({
          ...kycStatus,
          instantPayoutsEnabled: status === undefined || status === false,
        })
      }

      if (status !== true && kycStatus && kycStatus.blockMaintained) {
        setKycStatus({
          ...kycStatus,
          blockMaintained: false,
        })
      }
    }
  }

  const toggleIsUnderReview = (status: boolean) => {
    if (merchant) {
      setMerchant({
        ...merchant,
        ...(status && {
          kybDisabledCharges: undefined,
        }),
      })
    }
    if (kycStatus) {
      setKycStatus({
        ...kycStatus,
        isUnderReview: status,
        ...(status && {
          blockMaintained: false,
          instantPayoutsEnabled: true,
        }),
      })
    }
  }

  const update3DS2Allowances = async () => {
    if (!kycStatus) {
      return
    }
    setUpdatingKycLimits(true)
    await supportToggle3DS2PaymentLimits(
      merchantId,
      kycStatus.singleTransactionLimitUnits,
      kycStatus.dayTransactionLimitUnits,
      kycStatus.weekTransactionLimitUnits,
    )
    setUpdatingKycLimits(false)
  }

  const updateInstantPayoutLimits = async () => {
    if (!kycStatus) {
      return
    }
    setUpdatingInstantPayoutLimits(true)
    await supportUpdateInstantPayoutLimits(
      merchantId,
      kycStatus.unApprovedDailyInstantPayoutLimit,
      kycStatus.approvedDailyInstantPayoutLimit,
      kycStatus.underReviewInstantPayoutLimit,
    )
    setUpdatingInstantPayoutLimits(false)
  }

  const additionalDocumentVerificationValue =
    (): AdditionalDocumentVerificationType => {
      if (kycStatus?.additionalDocumentsRequired) {
        if (
          kycStatus?.additionalDocumentsRequiredStopPayouts &&
          kycStatus?.additionalDocumentsRequiredStopCharges
        ) {
          return 'requested_payouts_charges_blocked'
        }

        if (kycStatus?.additionalDocumentsRequiredStopCharges) {
          return 'requested_charges_blocked'
        }

        if (kycStatus?.additionalDocumentsRequiredStopPayouts) {
          return 'requested_payouts_blocked'
        }

        return 'requested'
      }

      return 'not_requested'
    }

  return (
    <>
      <CardBody>
        <AvForm>
          <div className="mb-3">
            {!kycStatus?.isUnderReview && !kycStatus?.blockMaintained && (
              <>
                <Label className="form-Label">Internal KYC Status</Label>
                <KYCStatusSelect
                  merchantId={merchantId}
                  disabledCharges={merchant.kybDisabledCharges}
                  setMerchantKYCStatus={setMerchantKYCStatus}
                />
                <div className="form-check mt-2">
                  <UnderReviewToggle
                    merchantId={merchantId}
                    isUnderReview={kycStatus?.isUnderReview}
                    setIsUnderReview={toggleIsUnderReview}
                  />
                </div>
              </>
            )}

            {!kycStatus?.isUnderReview &&
              merchant.kybDisabledCharges &&
              !kycStatus?.blockMaintained && (
                <BlockMaintainedToggle
                  currentBlockMaintainedStatus={kycStatus?.blockMaintained}
                  merchantId={merchantId}
                  setBlockMaintained={async (status: boolean) => {
                    if (status) {
                      toggleBlockMaintainedModal()
                      return
                    }
                    await toggleBlockMaintained(status)
                  }}
                />
              )}

            {kycStatus?.isUnderReview && (
              <Alert color="warning">
                This account is marked as <b>under review</b>. Accounts in
                review cannot be approved/banned. However, they can take
                advantage of 3DS2 allowances and have a higher auto-block
                threshold for large payment links.
                <div className="form-check mt-2">
                  <UnderReviewToggle
                    merchantId={merchantId}
                    isUnderReview={kycStatus?.isUnderReview}
                    setIsUnderReview={toggleIsUnderReview}
                  />
                </div>
              </Alert>
            )}

            {kycStatus?.blockMaintained && (
              <Alert color="danger">
                This account's <b>ban has been maintained</b> after further
                review. This means that the account is a known bad-actor, a
                fraudulent account, or could not verify their identity.
                <BlockMaintainedToggle
                  currentBlockMaintainedStatus={kycStatus?.blockMaintained}
                  merchantId={merchantId}
                  setBlockMaintained={async (status: boolean) => {
                    if (status) {
                      toggleBlockMaintainedModal()
                      return
                    }
                    await toggleBlockMaintained(status)
                  }}
                />
              </Alert>
            )}
          </div>

          <hr />
        </AvForm>

        <CardTitle className="mt-4">3DS2 Chargeback protection</CardTitle>

        {kycStatus ? (
          <AvForm>
            <div className="form-check mt-3">
              <input
                onChange={(e) => {
                  if (kycStatus) {
                    const kybEnforce3DS2 = !kycStatus.kybEnforce3DS2

                    setLoadingFybEnforce3DS2(true)
                    setKycStatus({
                      ...kycStatus,
                      kybEnforce3DS2,
                    })
                    supportToggle3DS2Payments(merchantId, kybEnforce3DS2)
                      .then(() => {
                        setLoadingFybEnforce3DS2(false)
                      })
                      .catch((e) => {
                        setLoadingFybEnforce3DS2(false)
                        alert('Could not update KYC enforcement' + e)
                      })
                  }
                }}
                checked={kycStatus?.kybEnforce3DS2}
                className="form-check-input"
                type="checkbox"
                id="capabilities-3ds2-enforced"
              />
              <label
                className="form-check-label"
                htmlFor="capabilities-3ds2-enforced"
              >
                {loadingFybEnforce3DS2 && <b>[Updating...] </b>}
                Enforce 3DS2, so Lopay is protected against fraudulent
                chargebacks.{' '}
                {kycStatus?.kybEnforce3DS2 === false ? (
                  <Badge className="me-2 bg-danger mt-2">
                    Danger: 3DS2 protection is switched off
                  </Badge>
                ) : (
                  <Badge className="me-2 bg-success mt-2">Switched on</Badge>
                )}
              </label>
            </div>
          </AvForm>
        ) : (
          <>Loading...</>
        )}

        <CardTitle className="mt-3">
          {(kycStatus?.isUnderReview ||
            merchant.kybDisabledCharges === false) && (
            <>Non-3DS2 Allowances &nbsp;</>
          )}

          {(kycStatus?.isUnderReview ||
            merchant.kybDisabledCharges === false) && (
            <Badge className="me-2 bg-success mt-2">Enabled</Badge>
          )}
        </CardTitle>

        {kycStatus && (
          <form className="row gx-3 gy-2 align-items-center">
            {kycStatus?.isUnderReview && (
              <Alert color="success">
                This merchant is under review, so they have a 3DS2 allowance.
              </Alert>
            )}

            {!kycStatus?.isUnderReview &&
              merchant.kybDisabledCharges === false && (
                <Alert color="success">
                  This merchant is approved, so they have a 3DS2 allowance.
                </Alert>
              )}

            {!kycStatus?.isUnderReview &&
              merchant.kybDisabledCharges !== false && (
                <Alert color="warning">
                  This merchant not approved or under review, so they do not
                  have an allowance of non-3DS2 payments. All of their payment
                  have 3DS2 enforced to protected Lopay from fraudulent
                  chargebacks.
                </Alert>
              )}

            {(kycStatus?.isUnderReview ||
              merchant.kybDisabledCharges === false) && (
              <>
                <Col sm={4}>
                  <Label
                    className="visually-hidden"
                    htmlFor="singleTransactionLimitUnits"
                  >
                    Transaction
                  </Label>
                  <div className="input-group">
                    <div className="input-group-text">Per £</div>
                    <Input
                      type="text"
                      className="form-control"
                      value={kycStatus.singleTransactionLimitUnits / 100}
                      id="singleTransactionLimitUnits"
                      placeholder="0.00"
                      onChange={(e) => {
                        setKycStatus({
                          ...kycStatus,
                          singleTransactionLimitUnits:
                            parseFloat(e.target.value) * 100,
                        })
                      }}
                    />
                  </div>
                </Col>
                <Col sm={4}>
                  <Label
                    className="visually-hidden"
                    htmlFor="weekTransactionLimitUnits"
                  >
                    Daily
                  </Label>
                  <div className="input-group">
                    <div className="input-group-text">Daily £</div>
                    <Input
                      type="text"
                      className="form-control"
                      id="weekTransactionLimitUnits"
                      value={kycStatus?.dayTransactionLimitUnits / 100}
                      placeholder="0.00"
                      onChange={(e) => {
                        setKycStatus({
                          ...kycStatus,
                          dayTransactionLimitUnits:
                            parseFloat(e.target.value) * 100,
                        })
                      }}
                    />
                  </div>
                </Col>
                <Col sm={4}>
                  <Label
                    className="visually-hidden"
                    htmlFor="weekTransactionLimitUnits"
                  >
                    Weekly
                  </Label>
                  <div className="input-group">
                    <div className="input-group-text">Weekly £</div>
                    <Input
                      type="text"
                      className="form-control"
                      id="weekTransactionLimitUnits"
                      value={kycStatus.weekTransactionLimitUnits / 100}
                      placeholder="0.00"
                      onChange={(e) => {
                        setKycStatus({
                          ...kycStatus,
                          weekTransactionLimitUnits:
                            parseFloat(e.target.value) * 100,
                        })
                      }}
                    />
                  </div>
                </Col>
              </>
            )}
          </form>
        )}
        {(kycStatus?.isUnderReview ||
          merchant.kybDisabledCharges === false) && (
          <button
            type="button"
            className="btn btn-primary btn-sm waves-effect waves-light mt-2"
            onClick={async () => {
              await update3DS2Allowances()
            }}
          >
            {updatingKycLimits ? <>Updating...</> : <>Update 3DS2 Allowances</>}
          </button>
        )}

        <hr />
        <CardTitle className="mt-4">Instant payout limits</CardTitle>

        {kycStatus?.isUnderReview && (
          <Alert color="warning">
            This merchant is under review, so they will be auto-blocked if they
            attempt an instant which is 50% higher than the below limits.
          </Alert>
        )}

        {kycStatus && (
          <>
            <form className="row gx-3 gy-2 align-items-center">
              <Col sm={4}>
                <Label
                  className="visually-hidden"
                  htmlFor="singleTransactionLimitUnits"
                >
                  Transaction
                </Label>
                <div className="input-group">
                  <div
                    className={
                      notReviewed && !kycStatus.isUnderReview
                        ? 'input-group-text btn-success'
                        : 'input-group-text'
                    }
                  >
                    Unapproved £
                  </div>
                  <Input
                    type="text"
                    className="form-control"
                    value={kycStatus.unApprovedDailyInstantPayoutLimit / 100}
                    id="unApprovedDailyInstantPayoutLimit"
                    placeholder="0.00"
                    onChange={(e) => {
                      setKycStatus({
                        ...kycStatus,
                        unApprovedDailyInstantPayoutLimit:
                          parseFloat(e.target.value) * 100,
                      })
                    }}
                  />
                </div>
              </Col>
              <Col sm={4}>
                <Label
                  className="visually-hidden"
                  htmlFor="underReviewInstantPayoutLimit"
                >
                  Approved
                </Label>
                <div className="input-group">
                  <div
                    className={
                      merchant.kybDisabledCharges === false &&
                      !kycStatus.isUnderReview
                        ? 'input-group-text btn-success'
                        : 'input-group-text'
                    }
                  >
                    Approved £
                  </div>
                  <Input
                    type="text"
                    className="form-control"
                    id="underReviewInstantPayoutLimit"
                    value={kycStatus?.approvedDailyInstantPayoutLimit / 100}
                    placeholder="0.00"
                    onChange={(e) => {
                      setKycStatus({
                        ...kycStatus,
                        approvedDailyInstantPayoutLimit:
                          parseFloat(e.target.value) * 100,
                      })
                    }}
                  />
                </div>
              </Col>
              <Col sm={4}>
                <Label
                  className="visually-hidden"
                  htmlFor="underReviewInstantPayoutLimit"
                >
                  In Review
                </Label>
                <div className="input-group">
                  <div
                    className={
                      kycStatus.isUnderReview
                        ? 'input-group-text btn-success'
                        : 'input-group-text'
                    }
                  >
                    In Review £
                  </div>
                  <Input
                    type="text"
                    className="form-control"
                    id="underReviewInstantPayoutLimit"
                    value={kycStatus.underReviewInstantPayoutLimit / 100}
                    placeholder="0.00"
                    onChange={(e) => {
                      setKycStatus({
                        ...kycStatus,
                        underReviewInstantPayoutLimit:
                          parseFloat(e.target.value) * 100,
                      })
                    }}
                  />
                </div>
              </Col>
            </form>

            <button
              type="button"
              className="btn btn-primary btn-sm waves-effect waves-light mt-2"
              onClick={async () => {
                await updateInstantPayoutLimits()
              }}
            >
              {updatingInstantPayoutLimits ? (
                <>Updating...</>
              ) : (
                <>Update Instant Payout Limits</>
              )}
            </button>
          </>
        )}

        <hr />
        <CardTitle className="mt-4">Capabilities</CardTitle>
        {kycStatus ? (
          <AvForm>
            <div className="form-check">
              <input
                onChange={(e) => {
                  if (kycStatus) {
                    const canOrderCardReader = !kycStatus.canOrderCardReader

                    setKycStatus({
                      ...kycStatus,
                      canOrderCardReader,
                    })

                    setLoadingCanOrderCardReader(true)
                    supportToggleCanOrderCardReader(
                      merchantId,
                      canOrderCardReader,
                    )
                      .then(() => {
                        setLoadingCanOrderCardReader(false)
                      })
                      .catch((e) => {
                        setLoadingCanOrderCardReader(false)
                        alert(
                          'Could not update ability to order a card reader' + e,
                        )
                      })
                  }
                }}
                checked={kycStatus?.canOrderCardReader}
                className="form-check-input"
                type="checkbox"
                id="capabilities-order-reader"
              />
              <label
                className="form-check-label"
                htmlFor="capabilities-order-reader"
              >
                {loadingCanOrderCardReader && <b>[Updating...] </b>}
                Can order a discounted card reader
              </label>
            </div>

            <div className="form-check">
              <input
                onChange={(e) => {
                  if (kycStatus) {
                    const onlinePaymentsEnabled =
                      !kycStatus.onlinePaymentsEnabled
                    setKycStatus({
                      ...kycStatus,
                      onlinePaymentsEnabled,
                    })

                    setLoadingOnlinePaymentsEnabled(true)
                    supportToggleOnlinePaymentsEnabled(
                      merchantId,
                      onlinePaymentsEnabled,
                    )
                      .then(() => {
                        setLoadingOnlinePaymentsEnabled(false)
                      })
                      .catch((e) => {
                        setLoadingOnlinePaymentsEnabled(false)
                        alert(
                          'Could not update online payments enforcement' + e,
                        )
                      })
                  }
                }}
                checked={kycStatus?.onlinePaymentsEnabled}
                className="form-check-input"
                type="checkbox"
                id="capabilities-online-payments"
              />
              <label
                className="form-check-label"
                htmlFor="capabilities-online-payments"
              >
                {loadingOnlinePaymentsEnabled && <b>[Updating...] </b>}
                Online payments are allowed
              </label>
            </div>

            <div className="form-check">
              <input
                onChange={() => {
                  if (!kycStatus) {
                    return
                  }

                  updateInstantPayoutsEnabledMutate(
                    !kycStatus.instantPayoutsEnabled,
                  )
                }}
                checked={kycStatus?.instantPayoutsEnabled}
                className="form-check-input"
                type="checkbox"
                id="capabilities-instant-payouts-enabled"
              />
              <label
                className="form-check-label"
                htmlFor="capabilities-instant-payouts-enabled"
              >
                {isLoadingUpdateInstantPayoutsEnabled && <b>[Updating...] </b>}
                Instant payouts enabled
              </label>
            </div>

            <div className="form-check">
              <input
                onChange={() => {
                  if (!kycStatus) {
                    return
                  }

                  updateAllowForeignEcommercePaymentsMutate(
                    !kycStatus.allowForeignEcommercePayments,
                  )
                }}
                checked={kycStatus?.allowForeignEcommercePayments}
                className="form-check-input"
                type="checkbox"
                id="capabilities-allow-foreign-ecommerce-payments"
              />
              <label
                className="form-check-label"
                htmlFor="capabilities-allow-foreign-ecommerce-payments"
              >
                {isLoadingUpdateAllowForeignEcommercePayments && (
                  <b>[Updating...] </b>
                )}
                Allow foreign cards for payment links
              </label>
            </div>

            <div className="form-check">
              <input
                onChange={() => {
                  if (!kycStatus) {
                    return
                  }

                  updateAllowForeignCardPaymentsMutate(
                    !kycStatus.allowForeignCardPayments,
                  )
                }}
                checked={kycStatus?.allowForeignCardPayments}
                className="form-check-input"
                type="checkbox"
                id="capabilities-allow-foreign-card-payments"
              />
              <label
                className="form-check-label"
                htmlFor="capabilities-allow-foreign-card-payments"
              >
                {isLoadingUpdateAllowForeignCardPayments && (
                  <b>[Updating...] </b>
                )}
                Allow terminal & tap to pay foreign cards payments
              </label>
            </div>

            <div className="form-check">
              <input
                onChange={() => {
                  if (!kycStatus) {
                    return
                  }

                  updateAdditionalPayoutDelayMutate(
                    !kycStatus.additionalPayoutDelayEnabled,
                  )
                }}
                checked={kycStatus?.additionalPayoutDelayEnabled}
                disabled={isLoadingUpdateAdditionalPayoutDelay}
                className="form-check-input"
                type="checkbox"
                id="capabilities-additional-payout-delay-enabled"
              />
              <label
                className="form-check-label"
                htmlFor="capabilities-additional-payout-delay-enabled"
              >
                {isLoadingUpdateAdditionalPayoutDelay && <b>[Updating...] </b>}
                Non-instant payout delay. When enabled, funds will be held for
                31 days before being paid out.
              </label>
            </div>

            <div className="form-check">
              <input
                onChange={() => {
                  if (!kycStatus) {
                    return
                  }

                  updateAllowTTPPaymentsMutate(!kycStatus.allowTTPPayments)
                }}
                checked={kycStatus?.allowTTPPayments}
                disabled={isLoadingUpdateAllowTTPPaymentsMutate}
                className="form-check-input"
                type="checkbox"
                id="capabilities-allow-tpp-payments"
              />
              <label
                className="form-check-label"
                htmlFor="capabilities-allow-tpp-payments"
              >
                {isLoadingUpdateAllowTTPPaymentsMutate && <b>[Updating...] </b>}
                Allow Tap to Pay payments
              </label>
            </div>
          </AvForm>
        ) : (
          <>Loading...</>
        )}
        <hr />
        <div className="mb-3">
          <Label className="form-Label">
            Additional Document Verification &nbsp;
            {kycStatus?.additionalDocumentsRequired && (
              <>
                {kycStatus?.additionalDocumentsStatus === 'verified' ? (
                  <Badge className="me-2 bg-success">
                    Documents have been verified
                  </Badge>
                ) : (
                  <Badge className="me-2 bg-danger">
                    {kycStatus?.additionalDocumentsStatus}
                  </Badge>
                )}
              </>
            )}
          </Label>

          <select
            className="form-select"
            value={additionalDocumentVerificationValue()}
            onChange={async (e) => {
              console.log('e.target:', e.target.value)

              switch (e.target.value as AdditionalDocumentVerificationType) {
                case 'requested':
                  await supportRequestAdditionalDocs(merchant.id, {})
                  break
                case 'requested_charges_blocked':
                  await supportRequestAdditionalDocs(merchant.id, {
                    disableCharges: true,
                  })
                  break
                case 'requested_no_blocks':
                  await supportUnRequestAdditionalDocs(merchant.id)
                  break
                case 'requested_payouts_blocked':
                  await supportRequestAdditionalDocs(merchant.id, {
                    disablePayouts: true,
                  })
                  break
                case 'requested_payouts_charges_blocked':
                  await supportRequestAdditionalDocs(merchant.id, {
                    disablePayouts: true,
                    disableCharges: true,
                  })
                  break
              }

              syncWithStripe()

              /*
                            const kybDisabledCharges = (e.target.value === undefined || e.target.value === null || e.target.value === 'KYB not reviewed') ? null : JSON.parse(e.target.value)
                            merchant.kybDisabledCharges = kybDisabledCharges

                            setMerchant({
                                ...merchant,
                                kybDisabledCharges: kybDisabledCharges,
                            })

                            supportUpdateMerchantPaymentConfigDetails(merchant.id, {
                                kybDisabledCharges: kybDisabledCharges,
                            }).then(() => {
                                setMerchant({
                                    ...merchant,
                                    kybDisabledCharges: kybDisabledCharges,
                                })
                            })
                            */
            }}
          >
            {additionalDocumentVerificationChoices.map((option) => (
              <option value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>

        <CardTitle className="mt-4">Stripe Checks</CardTitle>
        <AvForm>
          <div className="form-check">
            <input
              checked={kycStatus?.chargesEnabled}
              onChange={(e) => {
                console.log('passed-stripe-onboarding value:', e.target.value)
              }}
              className="form-check-input"
              type="checkbox"
              id="passed-stripe-onboarding"
              disabled
            />
            <label
              className="form-check-label"
              htmlFor="passed-stripe-onboarding"
            >
              Passed Stripe onboarding
            </label>
          </div>

          <div className="form-check">
            <input
              checked={kycStatus?.additionalDocumentsStatus === 'verified'}
              className="form-check-input"
              type="checkbox"
              id="passed-stripe-id"
              disabled
            />
            <label
              className="form-check-label"
              htmlFor="passed-stripe-onboarding"
            >
              Passed Stripe Identity Collection{' '}
              {!kycStatus?.requestedAdditionalDocuments && (
                <b>(Not requested)</b>
              )}
            </label>
          </div>

          <div className="form-check">
            <input
              checked={Boolean(externalAccounts?.length)}
              className="form-check-input"
              type="checkbox"
              id="passed-stripe-bank-account"
              disabled
            />
            <label
              className="form-check-label"
              htmlFor="passed-stripe-onboarding"
            >
              {externalAccounts === null ? (
                <>loading...</>
              ) : (
                <>Added a bank account</>
              )}
            </label>
          </div>
        </AvForm>

        {isSyncingWithStripe ? (
          <button
            type="button"
            className="btn btn-light btn-sm waves-effect waves-light"
          >
            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{' '}
            Synchronizing...
          </button>
        ) : (
          <button
            type="button"
            onClick={async () => {
              await syncWithStripe()
            }}
            className="btn btn-light btn-sm waves-effect waves-light m-1"
          >
            <i className="fab fa-stripe font-size-16 align-middle me-2"></i>{' '}
            Sync with Stripe
          </button>
        )}
      </CardBody>
      <ConfirmationModal
        onConfirm={() => toggleBlockMaintained(true)}
        isOpen={isBlockMaintainedModalOpen}
        toggle={toggleBlockMaintainedModal}
      >
        <Row>
          <Col>
            <Alert color="danger">
              You are about to mark a blocked merchant as a{' '}
              <b>known bad-actor</b>.
            </Alert>
            <p>
              This will trigger automated intercom flows which involve emails
              being sent to the account holder, and will have an impact on the
              comms that they are able to send to us. Although this action is
              reversible, the sent out communications are not.
            </p>
            <p>
              <b>
                Please confirm that you intended to do this and that you are
                aware of the consequent actions that will happen.
              </b>
            </p>
          </Col>
        </Row>
      </ConfirmationModal>
    </>
  )
}

export default SupportMerchantKYB
