import React, { useState } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import { useKybThresholds } from './fraud-framework.hook'
import { startCase, isNull } from 'lodash'
import { KYBStatus, KYBThreshold } from './kyb-threshold.type'
import { updateKybThresholds } from './fraud-framework.api'

try {
  TimeAgo.addDefaultLocale(en)
} catch (e) {
  console.error(e)
}
interface PageProps {
  history: any
}

interface KYBStatusThresholdProps {
  kybThreshold: KYBThreshold
}

interface Threshold {
  name: string
  value: number | null
}

const KYBStatusThreshold = ({ kybThreshold }: KYBStatusThresholdProps) => {
  const niceName = startCase(kybThreshold.kybStatus)
  const [thresholds, setThresholds] = useState(
    Object.keys(kybThreshold)
      .filter((k) => k !== 'kybStatus')
      .map((key) => {
        const value = kybThreshold[key as keyof KYBThreshold]
        return {
          name: key,
          value,
        }
      }) as Threshold[],
  )

  const handleThresholdChange = (name: string, value: string | null) => {
    setThresholds(
      thresholds.map((threshold) => {
        if (threshold.name !== name) {
          return threshold
        } else {
          return {
            name,
            value: !isNull(value)
              ? Number(value.replace(/\D/g, '')) * 100
              : null,
          }
        }
      }),
    )
  }

  const submitThresholdChange = async (
    kybStatus: KYBStatus,
    thresholds: Threshold[],
  ) => {
    const changedThresholds = thresholds.filter(
      (threshold) =>
        kybThreshold[threshold.name as keyof KYBThreshold] !== threshold.value,
    )
    if (!changedThresholds.length) return
    const kybThresholdUpdates = changedThresholds.reduce(
      (acc, item) => {
        acc[item.name] = item.value
        return acc
      },
      {} as { [key: string]: any },
    )
    await updateKybThresholds(kybStatus, kybThresholdUpdates)
  }

  return (
    <Col lg={4}>
      <Card>
        <h5 className="card-header bg-transparent border-bottom">
          <p className="card-title-desc">Status:</p>
          {niceName}
        </h5>
        <CardBody>
          {thresholds.map((threshold) => {
            const id = `${kybThreshold.kybStatus}-${threshold.name}`
            return (
              <div key={id}>
                <Label htmlFor={id} className="form-Label">
                  {startCase(threshold.name)}
                </Label>
                <div className="input-group">
                  <div className="input-group-text">£</div>
                  <Input
                    type="text"
                    step="any"
                    className="form-control"
                    value={
                      !isNull(threshold.value)
                        ? (threshold.value as number) / 100
                        : ''
                    }
                    onChange={(e) => {
                      handleThresholdChange(
                        threshold.name,
                        e.target.value ? e.target.value : null,
                      )
                    }}
                    id={id}
                    placeholder="No Threshold"
                  />
                </div>
                <br />
              </div>
            )
          })}
          <button
            onClick={(e) => {
              e.preventDefault()
              // save kyb threshold
              submitThresholdChange(kybThreshold.kybStatus, thresholds)
            }}
            type="button"
            className="btn btn-primary"
          >
            Save
          </button>
        </CardBody>
      </Card>
    </Col>
  )
}

const KYBThresholdsPage = ({ history }: PageProps) => {
  const result = useKybThresholds()

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>KYB Thresholds</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={'Fraud Prevention'}
            breadcrumbItem="KYB Thresholds"
          />
          <Row>
            <Col xs={12}>
              {result.isLoading && (
                <div className="d-flex justify-content-center">
                  <Spinner color="primary" />
                </div>
              )}
              {result.error && (
                <p className="card-text">Could not retrieve fraud rules.</p>
              )}
            </Col>
            {result.data &&
              result.data.map((kybThreshold) => (
                <KYBStatusThreshold
                  key={kybThreshold.kybStatus}
                  kybThreshold={kybThreshold}
                />
              ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default KYBThresholdsPage
