import React from 'react'
import MetaTags from 'react-meta-tags'
import { Alert, Row, Card, CardBody, Container } from 'reactstrap'
import MerchantApiDto from '../../../api-dtos/merchant/merchant.dto.interface'
import AccountTypeSelector from './components/account-type-selector'
import MerchantAuditLog from './components/merchant-audit-log'

const MerchantScheduleComponent = (props: {
  merchant: MerchantApiDto
  scheduleUpdated: () => void
}) => {
  if (!props.merchant) {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Schedule</title>
          </MetaTags>
          <Container fluid>
            <Alert color="danger">
              Sorry there was a problem loading the schedule information for
              this merchant
            </Alert>
          </Container>
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Merchant Schedule and Audit Log</title>
      </MetaTags>
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Row>
          <Card>
            <CardBody>
              <AccountTypeSelector
                merchantId={props.merchant.id}
                currencyCode={props.merchant.currencyCode}
              />
              <MerchantAuditLog merchantId={props.merchant.id} />
            </CardBody>
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default MerchantScheduleComponent
