import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import { isEmpty } from 'lodash'

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap'
import { AvField, AvForm } from 'availity-reactstrap-validation'

/** Import Full Calendar  */
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'
import BootstrapTheme from '@fullcalendar/bootstrap'

//import images
import calendar from '../../assets/images/undraw-calendar.svg'

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb'

import {
  addNewEvent as onAddNewEvent,
  deleteEvent as onDeleteEvent,
  getCategories as onGetCategories,
  getEvents as onGetEvents,
  updateEvent as onUpdateEvent,
} from '../../store/actions'

import DeleteModal from './DeleteModal'

//css
import '@fullcalendar/bootstrap/main.css'

//redux
import { useSelector, useDispatch } from 'react-redux'

interface CalendarProps {
  className: string
}
const Calender = ({ className }: CalendarProps) => {
  const dispatch = useDispatch()

  const { events, categories } = useSelector((state: any) => ({
    events: state.Calendar.events,
    categories: state.Calendar.categories,
  }))

  const [modal, setModal] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [modalcategory, setModalcategory] = useState<boolean>(false)
  const [event, setEvent] = useState<any>({})
  const [selectedDay, setSelectedDay] = useState<any>(0)
  const [isEdit, setIsEdit] = useState<boolean>(false)

  useEffect(() => {
    let external: any = document.getElementById('external-events')
    dispatch(onGetCategories())
    dispatch(onGetEvents())
    new Draggable(external, {
      itemSelector: '.external-event',
    })
  }, [dispatch])

  /**
   * Handling the modal state
   */
  const toggle = () => {
    setModal(!modal)
    if (!modal && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({})
        setIsEdit(false)
      }, 500)
    }
  }

  const toggleCategory = () => {
    setModalcategory(!modalcategory)
  }

  /**
   * Handling date click on calendar
   */
  const handleDateClick = (arg: any) => {
    const date = arg['date']
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    const currectDate = new Date()
    const currentHour = currectDate.getHours()
    const currentMin = currectDate.getMinutes()
    const currentSec = currectDate.getSeconds()
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec,
    )
    const modifiedData = { ...arg, date: modifiedDate }

    setSelectedDay(modifiedData)
    toggle()
  }

  /**
   * Handling click on event on calendar
   */
  const handleEventClick = (arg: any) => {
    const event = arg.event
    setEvent({
      id: event.id,
      title: event.title,
      title_category: event.title_category,
      start: event.start,
      className: event.classNames,
      category: event.classNames[0],
      event_category: event.classNames[0],
    })
    setIsEdit(true)
    toggle()
  }

  /**
   * Handling submit event on event form
   */
  const handleValidEventSubmit = (values: any) => {
    if (isEdit) {
      const updateEvent = {
        id: event.id,
        title: values.title,
        classNames: values.category + ' text-white',
        start: event.start,
      }
      // update event
      dispatch(onUpdateEvent(updateEvent))
    } else {
      const newEvent = {
        id: Math.floor(Math.random() * 100),
        title: values['title'],
        start: selectedDay ? selectedDay.date : new Date(),
        className: values.category + ' text-white',
      }
      dispatch(onAddNewEvent(newEvent))
    }
    setSelectedDay(null)
    toggle()
  }

  const handleValidEventSubmitcategory = (values: any) => {
    const newEvent = {
      id: Math.floor(Math.random() * 100),
      title: values['title_category'],
      start: selectedDay ? selectedDay.date : new Date(),
      className: values.event_category
        ? values.event_category + ' text-white'
        : 'bg-danger text-white',
    }
    // save new event

    dispatch(onAddNewEvent(newEvent))
    toggleCategory()
  }

  /**
   * On delete event
   */
  const handleDeleteEvent = () => {
    dispatch(onDeleteEvent(event))
    setDeleteModal(false)
    toggle()
  }

  /**
   * On category darg event
   */
  const onDrag = (event: any) => {
    event.preventDefault()
  }

  /**
   * On calendar drop event
   */
  const onDrop = (event: any) => {
    const date = event['date']
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    const currectDate = new Date()
    const currentHour = currectDate.getHours()
    const currentMin = currectDate.getMinutes()
    const currentSec = currectDate.getSeconds()
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec,
    )

    const draggedEl = event.draggedEl
    const modifiedData = {
      id: Math.floor(Math.random() * 100),
      title: draggedEl.innerText,
      start: modifiedDate,
      className: draggedEl.className,
    }
    dispatch(onAddNewEvent(modifiedData))
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <MetaTags>
          <title>Calendar | Minia - React Admin & Dashboard Template</title>
        </MetaTags>
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Minia" breadcrumbItem="Calendar" />
          <Row>
            <Col xs={12}>
              <Row>
                <Col xl={3} lg={4}>
                  <Card>
                    <CardBody>
                      <div className="d-grid">
                        <Button
                          color="primary"
                          className="font-16 btn-primary"
                          onClick={toggleCategory}
                        >
                          <i className="mdi mdi-plus-circle-outline me-1" />
                          Create New Event
                        </Button>
                      </div>
                      <div id="external-events" className="mt-2">
                        <br />
                        <p className="text-muted">
                          Drag and drop your event or click in the calendar
                        </p>
                        {categories &&
                          categories.map((category: any, i: any) => (
                            <div
                              className={`${
                                category.type + ' ' + category.text
                              } fc-event external-event`}
                              key={'cat-' + category.id}
                              draggable
                              onDrag={(event) => onDrag(event)}
                            >
                              <i className="mdi mdi-checkbox-blank-circle me-2 vertical-middle" />
                              {category.title}
                            </div>
                          ))}
                      </div>
                      <div className="row justify-content-center mt-5">
                        <div className="col-lg-12 col-sm-6">
                          <img
                            src={calendar}
                            alt=""
                            className="img-fluid d-block"
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl={9} lg={8}>
                  <Card>
                    <CardBody>
                      {/* fullcalendar control */}
                      <FullCalendar
                        plugins={[
                          BootstrapTheme,
                          dayGridPlugin,
                          interactionPlugin,
                        ]}
                        slotDuration={'00:15:00'}
                        handleWindowResize={true}
                        themeSystem="bootstrap"
                        headerToolbar={{
                          left: 'prev,next today',
                          center: 'title',
                          right: 'dayGridMonth,dayGridWeek,dayGridDay',
                        }}
                        events={events}
                        editable={true}
                        droppable={true}
                        selectable={true}
                        dateClick={handleDateClick}
                        eventClick={handleEventClick}
                        drop={onDrop}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <div style={{ clear: 'both' }}></div>
              {/* New/Edit event modal */}
              <Modal isOpen={modal} className={className}>
                <ModalHeader toggle={toggle} tag="h4">
                  {!!isEdit ? 'Edit Event' : 'Add Event'}
                </ModalHeader>
                <ModalBody>
                  <AvForm
                    onValidSubmit={(e: any, values: any) => {
                      handleValidEventSubmit(values)
                    }}
                  >
                    <Row form>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="title"
                          label="Event Name"
                          type="text"
                          errorMessage="Invalid name"
                          validate={{
                            required: { value: true },
                          }}
                          value={event ? event.title : ''}
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          type="select"
                          name="category"
                          label="Select Category"
                          validate={{
                            required: { value: true },
                          }}
                          value={event ? event.category : 'bg-primary'}
                        >
                          <option value="bg-danger">Danger</option>
                          <option value="bg-success">Success</option>
                          <option value="bg-primary">Primary</option>
                          <option value="bg-info">Info</option>
                          <option value="bg-dark">Dark</option>
                          <option value="bg-warning">Warning</option>
                        </AvField>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-end">
                          <button
                            type="button"
                            className="btn btn-light me-2"
                            onClick={toggle}
                          >
                            Close
                          </button>
                          {!!isEdit && (
                            <button
                              type="button"
                              className="btn btn-danger me-2"
                              onClick={() => setDeleteModal(true)}
                            >
                              Delete
                            </button>
                          )}
                          <button
                            type="submit"
                            className="btn btn-success save-event"
                          >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </ModalBody>
              </Modal>

              <Modal
                isOpen={modalcategory}
                toggle={toggleCategory}
                className={className}
              >
                <ModalHeader toggle={toggleCategory} tag="h4">
                  Add a category
                </ModalHeader>
                <ModalBody>
                  <AvForm onValidSubmit={handleValidEventSubmitcategory}>
                    <Row form>
                      <Col className="col-12 mb-3">
                        <AvField
                          name="title_category"
                          label="Category Name"
                          type="text"
                          errorMessage="Invalid name"
                          validate={{
                            required: { value: true },
                          }}
                          value={
                            event.title_category ? event.title_category : ''
                          }
                        />
                      </Col>
                      <Col className="col-12 mb-3">
                        <AvField
                          type="select"
                          name="event_category"
                          label="Choose Category Color"
                          value={event ? event.event_category : 'bg-primary'}
                        >
                          <option value="bg-danger">Danger</option>
                          <option value="bg-success">Success</option>
                          <option value="bg-primary">Primary</option>
                          <option value="bg-info">Info</option>
                          <option value="bg-dark">Dark</option>
                          <option value="bg-warning">Warning</option>
                        </AvField>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="text-end">
                          <button
                            type="button"
                            className="btn btn-light me-2"
                            onClick={toggleCategory}
                          >
                            Close
                          </button>
                          <button
                            type="submit"
                            className="btn btn-success save-event"
                          >
                            Save
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Calender
