/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useQuery } from 'react-query'

import Select from 'react-select'
import { FilePond, registerPlugin } from 'react-filepond'
import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Row,
  Col,
  Card,
  CardBody,
  Label,
  Input,
  CardHeader,
  Table,
  Container,
  Alert,
  Form,
} from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import {
  OfferDenominationDto,
  SupportOfferCategoryDto,
  SupportOfferDto,
} from 'src/api-dtos/offers/offer.dto'
import {
  getLoggedInSupportUser,
  publishOffer,
  publishOfferDenomination,
  supportFetchOffer,
  supportFetchOfferCategories,
  updateOfferCategories,
  updateOfferDescription,
  updateOfferName,
  updateOfferRewardPercentage,
  updateOfferTerms,
} from 'src/helpers/lopay_api_helper'

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import { API_URL } from 'src/helpers/api_helper'
import { priceFormat } from 'src/helpers/utils'
import PriceDto from 'src/api-dtos/types/price.dto'

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

try {
  TimeAgo.addDefaultLocale(en)
} catch (e) {
  console.error(e)
}
interface PageProps {
  history: any
  match: {
    params: {
      offerId: string
    }
  }
}

const OfferPage = (props: PageProps) => {
  const offerId = props.match.params.offerId

  const [published, setPublished] = useState<boolean | undefined>(undefined)
  const [offer, setOffer] = useState<SupportOfferDto | null>(null)
  const [files, setFiles] = useState<any[]>([])

  const [offerName, setOfferName] = useState<string>('')
  const [offerDescription, setOfferDescription] = useState<string>('')
  const [offerTerms, setOfferTerms] = useState<string>('')

  const [isLoadingName, setIsLoadingName] = useState<boolean>(false)
  const [isLoadingDescription, setIsLoadingDescription] =
    useState<boolean>(false)
  const [isLoadingTerms, setIsLoadingTerms] = useState<boolean>(false)
  const [isLoadingCategoryUpdate, setIsLoadingCategoryUpdate] =
    useState<boolean>(false)
  const [isLoadingPublished, setIsLoadingPublished] = useState<boolean>(false)

  const [isLoadingRewardPercentage, setIsLoadingRewardPercentage] =
    useState<boolean>(false)

  const [offerRewardPercentString, setOfferRewardPercentString] =
    useState<string>('')
  const [
    offerRewardPercentStringChangeDetection,
    setOfferRewardPercentStringChangeDetection,
  ] = useState<string>('')

  const [dLoadingStates, setDLoadingStates] = useState<string[]>([])

  const [selectedCategories, setSelectedCategories] = useState<
    { label: string; value: string }[]
  >([
    {
      label: 'Loading...',
      value: 'loading',
    },
  ])
  const [offerCategories, setOfferCategories] = useState<
    { label: string; value: string }[]
  >([
    {
      label: 'Loading...',
      value: 'loading',
    },
  ])

  const { data: offerCategoriesData } = useQuery<
    { categories: SupportOfferCategoryDto[] },
    Error
  >({
    queryKey: [`offer-categories`],
    queryFn: () => supportFetchOfferCategories(),
  })

  const { data: offerData, error } = useQuery<SupportOfferDto, Error>({
    queryKey: [`offer-${offerId}`],
    queryFn: () => supportFetchOffer(offerId),
  })

  useEffect(() => {
    setOfferCategories(
      offerCategoriesData?.categories.map((c) => {
        return {
          label: c.name,
          value: c.id,
        }
      }) || [],
    )
  }, [offerCategoriesData])

  useEffect(() => {
    if (offerData) {
      setPublished(offerData.enabled)
      setOffer(offerData)

      setOfferName(offerData.brand)
      setOfferDescription(offerData.description || '')
      setOfferTerms(offerData.terms)

      setOfferRewardPercentStringChangeDetection(
        `${offerData.reward?.percentage ? offerData.reward?.percentage * 100 : ''}`,
      )

      setOfferRewardPercentString(
        `${offerData.reward?.percentage ? offerData.reward?.percentage * 100 : ''}`,
      )
      setSelectedCategories(
        offerData.categories.map((c) => {
          return {
            label: c,
            value: c,
          }
        }),
      )
    }
  }, [offerData])

  const calculateRewardForDenomination = (
    denomination: OfferDenominationDto,
  ): PriceDto | undefined => {
    if (offer?.reward?.percentage) {
      const percentFraction = offer.reward.percentage

      const cashBackAmountUnits = percentFraction * denomination.amount.units

      const costOfProcessingPercentage = 0.01

      const multiplier = 1 / costOfProcessingPercentage

      const freeProcessingAmountUnits = Math.floor(
        cashBackAmountUnits * multiplier,
      )

      return {
        units: Math.floor(freeProcessingAmountUnits),
        currencyCode: denomination.amount.currencyCode,
      }
    }
    return
  }

  const calculateCostOfReward = (amount: PriceDto) => {
    const costOfProcessingPercentage = 0.01

    return {
      units: Math.floor(amount.units * costOfProcessingPercentage),
      currencyCode: amount.currencyCode,
    }
  }

  const calculateKickbackForDenomination = (
    denomination: OfferDenominationDto,
  ): PriceDto | undefined => {
    if (offer?.kickback?.percentage) {
      const percentFraction = offer.kickback.percentage!

      return {
        units: Math.floor(percentFraction * denomination.amount.units),
        currencyCode: denomination.amount.currencyCode,
      }
    }
    return
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>{offer?.brand}</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={'LoPay Support'}
            breadcrumbItem={offer?.brand || 'Offer'}
          ></Breadcrumbs>

          <Row>
            <Col xs={12}>
              {error ? <Alert color="danger">{error}</Alert> : null}

              <Card>
                <CardHeader>
                  <h4 className="card-title">Public Offer Details</h4>
                  <div className="text-muted font-size-13 mt-0 mb-2">
                    <a
                      href="#"
                      onClick={() => {
                        navigator.clipboard.writeText(offer?.id!).then(
                          function () {
                            alert('Offer ID copied to clipboard')
                          },
                          function (err) {},
                        )
                      }}
                    >
                      {' '}
                      {offer?.id} <i className="bx bx-paste"></i>
                    </a>
                  </div>

                  <div>
                    {published === undefined ? (
                      'Loading...'
                    ) : (
                      <span>
                        <div
                          className="form-check form-switch form-switch-md"
                          dir="ltr"
                        >
                          <input
                            checked={published}
                            type="checkbox"
                            className="form-check-input"
                            id="customSwitchsizemd"
                            onChange={async (e) => {
                              setPublished(e.target.checked)

                              setIsLoadingPublished(true)

                              try {
                                await publishOffer(offer?.id!, e.target.checked)
                              } catch (e) {
                                console.error(e)
                              }

                              setIsLoadingPublished(false)
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customSwitchsizemd"
                          >
                            {isLoadingPublished
                              ? 'Updating...'
                              : published
                                ? 'Published'
                                : 'Unpublished'}
                          </label>
                        </div>
                      </span>
                    )}
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="mb-3">
                      <Label
                        htmlFor="example-text-input"
                        className="form-Label"
                      >
                        Name (usually a brand name)
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        value={offerName}
                        id="text-offer-brand"
                        onChange={(e) => {
                          setOfferName(e.target.value.trim())
                        }}
                      />
                    </div>
                    {Boolean(offer?.brand !== offerName) && (
                      <Alert
                        color="warning"
                        className="alert-label-icon label-arrow"
                      >
                        <i className="mdi mdi-content-save-all label-icon"></i>
                        Offer name updated &nbsp;
                        <button
                          disabled={isLoadingName}
                          onClick={async () => {
                            setIsLoadingName(true)
                            try {
                              await updateOfferName(offer?.id!, offerName)
                              setOffer({
                                ...offer!,
                                brand: offerName,
                              })
                            } catch (e) {
                              console.error(e)
                            }
                            setIsLoadingName(false)
                          }}
                          type="button"
                          className="btn btn-primary btn-sm waves-effect waves-light"
                        >
                          {isLoadingName ? 'Saving...' : 'Save changes'}
                        </button>
                      </Alert>
                    )}
                  </Row>

                  <Row>
                    <div className="mb-3">
                      <Label
                        htmlFor="example-text-input"
                        className="form-Label"
                      >
                        Description
                      </Label>
                      <textarea
                        className="form-control"
                        value={offerDescription}
                        id="text-offer-description"
                        onChange={(e) => {
                          setOfferDescription(e.target.value.trim())
                        }}
                        rows={6}
                      ></textarea>
                    </div>

                    {Boolean(offer?.description !== offerDescription) && (
                      <Alert
                        color="warning"
                        className="alert-label-icon label-arrow"
                      >
                        <i className="mdi mdi-content-save-all label-icon"></i>
                        Offer description updated &nbsp;
                        <button
                          disabled={isLoadingName}
                          onClick={async () => {
                            setIsLoadingDescription(true)
                            try {
                              await updateOfferDescription(
                                offer?.id!,
                                offerDescription,
                              )
                              setOffer({
                                ...offer!,
                                description: offerDescription,
                              })
                            } catch (e) {
                              console.error(e)
                            }
                            setIsLoadingDescription(false)
                          }}
                          type="button"
                          className="btn btn-primary btn-sm waves-effect waves-light"
                        >
                          {isLoadingDescription ? 'Saving...' : 'Save changes'}
                        </button>
                      </Alert>
                    )}
                  </Row>

                  <Row>
                    <div className="mb-3">
                      <label
                        htmlFor="choices-multiple-default"
                        className="form-label font-size-13"
                      >
                        Categories
                      </label>
                      <Select
                        isMulti
                        value={selectedCategories}
                        options={offerCategories}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={async (items: any) => {
                          setSelectedCategories(items)

                          setIsLoadingCategoryUpdate(true)

                          try {
                            await updateOfferCategories(
                              offer!.id!,
                              items.map((i: any) => ({ id: i.value })),
                            )
                          } catch (e) {
                            console.error(e)
                          }

                          setIsLoadingCategoryUpdate(false)
                        }}
                      />
                    </div>
                    {Boolean(isLoadingCategoryUpdate) && <div>Updating...</div>}
                  </Row>

                  <Row>
                    <label className="form-label font-size-13">
                      Thumbnail image
                    </label>

                    <Col lg={4}>
                      <img
                        className="img-thumbnail offer-image-preview"
                        alt={'Thumbnail'}
                        src={offer?.imageUrl}
                        data-holder-rendered="true"
                      />
                    </Col>

                    <Col lg={4}>
                      <div className="App">
                        <FilePond
                          files={files}
                          onupdatefiles={setFiles}
                          imagePreviewHeight={160}
                          onprocessfile={(error, file) => {
                            if (error) {
                              return
                            }
                            const uploadResponse: { url: string } = JSON.parse(
                              file.serverId,
                            )
                            setOffer({
                              ...offer!,
                              imageUrl: uploadResponse.url,
                            })
                          }}
                          credits={false}
                          allowMultiple={false}
                          maxFiles={1}
                          server={{
                            url: `${API_URL}support/offers/${offerId}/thumbnail`,
                            headers: {
                              'x-support-jwt-auth': `${getLoggedInSupportUser()?.auth.accessToken}`,
                              'x-region':
                                localStorage.getItem('REGION') || 'region_eu',
                            },
                          }}
                          name="file"
                          labelIdle='Drag & Drop a file or <span class="filepond--label-action">Browse</span>'
                        />
                      </div>
                    </Col>
                  </Row>

                  {Boolean(offer?.redemptionInstructions) && (
                    <Row>
                      <div className="mb-3 mt-3">
                        <Label
                          htmlFor="example-text-input"
                          className="form-Label"
                        >
                          Redemption Instructions
                        </Label>
                        <textarea
                          className="form-control"
                          value={offer?.redemptionInstructions}
                          id="text-offer-brand"
                          onChange={(e) => {
                            if (offer) {
                              setOffer({
                                ...offer,
                                terms: e.target.value,
                              })
                            }
                          }}
                          rows={6}
                        ></textarea>
                      </div>
                    </Row>
                  )}

                  <Row>
                    <div className="mb-3 mt-3">
                      <Label
                        htmlFor="example-text-input"
                        className="form-Label"
                      >
                        Terms &amp; Conditions
                      </Label>
                      <textarea
                        className="form-control"
                        value={offerTerms}
                        id="text-offer-brand"
                        onChange={(e) => {
                          setOfferTerms(e.target.value.trim())
                        }}
                        rows={6}
                      ></textarea>
                    </div>
                    {Boolean(offer?.terms !== offerTerms) && (
                      <Alert
                        color="warning"
                        className="alert-label-icon label-arrow"
                      >
                        <i className="mdi mdi-content-save-all label-icon"></i>
                        Offer terms &amp; conditions updated &nbsp;
                        <button
                          disabled={isLoadingName}
                          onClick={async () => {
                            setIsLoadingTerms(true)
                            try {
                              await updateOfferTerms(offer?.id!, offerTerms)
                            } catch (e) {
                              console.error(e)
                            }
                            setOffer({
                              ...offer!,
                              terms: offerTerms,
                            })
                            setIsLoadingTerms(false)
                          }}
                          type="button"
                          className="btn btn-primary btn-sm waves-effect waves-light"
                        >
                          {isLoadingTerms ? 'Saving...' : 'Save changes'}
                        </button>
                      </Alert>
                    )}
                  </Row>

                  <Row>
                    <Col lg={6}>
                      <Card>
                        <CardHeader>
                          <h4 className="card-title">Kickback</h4>
                          <p className="card-title-desc">
                            How much Lopay earns from the partner. This is
                            internal &amp; confidential
                          </p>
                        </CardHeader>
                        <CardBody>
                          <Form>
                            <div className="mb-4">
                              <Label
                                className="form-label"
                                htmlFor="default-input"
                              >
                                Percentage (%)
                              </Label>
                              <Input
                                className="form-control"
                                type="text"
                                id="default-input"
                                placeholder="NA"
                                value={
                                  offer?.kickback?.percentage
                                    ? `${offer.kickback.percentage * 100}`
                                    : ''
                                }
                                disabled
                              />
                            </div>
                            <div className="mb-4">
                              <Label
                                className="form-label"
                                htmlFor="form-sm-input"
                              >
                                Fixed
                              </Label>
                              <Input
                                className="form-control"
                                type="text"
                                id="form-sm-input"
                                value={
                                  offer?.kickback?.fixed
                                    ? priceFormat(offer.kickback.fixed)
                                    : ''
                                }
                                placeholder="NA"
                                disabled
                              />
                            </div>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col lg={6}>
                      <Card>
                        <CardHeader>
                          <h4 className="card-title">Reward</h4>
                          <p className="card-title-desc">
                            The fee-processing amount given to the user.
                            (Visible in app)
                          </p>
                        </CardHeader>
                        <CardBody>
                          <Form>
                            <div className="mb-4">
                              <Label
                                className="form-label"
                                htmlFor="default-input"
                              >
                                Percentage (%)
                              </Label>
                              <Input
                                className="form-control"
                                type="text"
                                id="default-input"
                                placeholder="NA"
                                value={offerRewardPercentString}
                                onChange={(e) => {
                                  setOfferRewardPercentString(e.target.value)

                                  const endsInDot = e.target.value.endsWith('.')
                                  const hasValue =
                                    e.target.value.trim().length > 0

                                  if (hasValue && !endsInDot) {
                                    setOffer({
                                      ...offer!,
                                      reward: {
                                        ...offer!.reward,
                                        percentage:
                                          parseFloat(e.target.value) / 100,
                                      },
                                    })
                                  }
                                }}
                              />
                            </div>
                            <div className="mb-4">
                              <Label
                                className="form-label"
                                htmlFor="form-sm-input"
                              >
                                Fixed
                              </Label>
                              <Input
                                className="form-control"
                                type="text"
                                id="form-sm-input"
                                placeholder="NA"
                                value={
                                  offer?.reward?.fixed
                                    ? priceFormat(offer.reward.fixed)
                                    : ''
                                }
                                onChange={() => {}}
                              />
                            </div>

                            {Boolean(
                              offerRewardPercentString !==
                                offerRewardPercentStringChangeDetection,
                            ) && (
                              <Alert
                                color="warning"
                                className="alert-label-icon label-arrow"
                              >
                                <i className="mdi mdi-content-save-all label-icon"></i>
                                Users reward updated &nbsp;
                                <button
                                  disabled={isLoadingRewardPercentage}
                                  onClick={async () => {
                                    setIsLoadingRewardPercentage(true)
                                    try {
                                      await updateOfferRewardPercentage(
                                        offer?.id!,
                                        offer!.reward?.percentage!,
                                      )

                                      setOfferRewardPercentStringChangeDetection(
                                        `${offer!.reward?.percentage ? offer!.reward?.percentage * 100 : ''}`,
                                      )
                                    } catch (e) {
                                      console.error(e)
                                    }
                                    setIsLoadingRewardPercentage(false)
                                  }}
                                  type="button"
                                  className="btn btn-primary btn-sm waves-effect waves-light"
                                >
                                  {isLoadingRewardPercentage
                                    ? 'Saving...'
                                    : 'Save changes'}
                                </button>
                              </Alert>
                            )}
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>

                  {Boolean(offer?.providerUrl) && (
                    <Row>
                      <div className="mb-3">
                        <Label
                          htmlFor="example-text-input"
                          className="form-Label"
                        >
                          Redemption URL
                        </Label>

                        <p className="card-title-desc mb-1">
                          The URL to visit to redeem the offer. This should end
                          in a "-".
                        </p>

                        <Input
                          className="form-control"
                          type="text"
                          value={offer?.providerUrl}
                          id="text-offer-providerUrl"
                          onChange={(e) => {
                            if (offer) {
                              setOffer({
                                ...offer,
                                providerUrl: e.target.value,
                              })
                            }
                          }}
                        />
                      </div>
                    </Row>
                  )}

                  {Boolean(offer?.denominations?.length) && (
                    <Row>
                      <div className="mb-3">
                        <Label
                          htmlFor="example-text-input"
                          className="form-Label"
                        >
                          Purchase Denominations
                        </Label>
                        <p className="card-title-desc mb-1">
                          Denominations that can be purchased. These only apply
                          to in-app gift card purchases. Note, the denomination
                          reward values are calculated from the offer.
                        </p>
                      </div>

                      <div>
                        <div className="table-responsive">
                          <Table className="table table-striped mb-0">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Purchase Price</th>
                                <th>Partner kickback</th>
                                <th>User Reward</th>
                                <th>Published</th>
                              </tr>
                            </thead>
                            <tbody>
                              {offer?.denominations?.map((d, i) => {
                                return (
                                  <tr key={i}>
                                    <th scope="row">{i + 1}</th>
                                    <td>
                                      {d.amount
                                        ? priceFormat(d.amount)
                                        : 'None'}
                                    </td>
                                    <td>
                                      {calculateKickbackForDenomination(d)
                                        ?.units
                                        ? priceFormat(
                                            calculateKickbackForDenomination(
                                              d,
                                            )!,
                                          )
                                        : 'No kickback'}
                                    </td>
                                    <td>
                                      {calculateRewardForDenomination(d)
                                        ?.units ? (
                                        <span>
                                          <div>
                                            {priceFormat(
                                              calculateRewardForDenomination(
                                                d!,
                                              )!,
                                            )}{' '}
                                            in rewards
                                          </div>
                                          <div className="text-muted font-size-10">
                                            (
                                            {priceFormat(
                                              calculateCostOfReward(
                                                calculateRewardForDenomination(
                                                  d!,
                                                )!,
                                              )!,
                                            )}{' '}
                                            est. cost)
                                          </div>
                                        </span>
                                      ) : (
                                        'None'
                                      )}
                                    </td>
                                    <td>
                                      {dLoadingStates.includes(d.id!)
                                        ? 'Updating...'
                                        : d.enabled}
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck2"
                                        checked={d.enabled}
                                        onChange={async (e) => {
                                          const enabled = e.target.checked
                                            ? true
                                            : false

                                          setOffer({
                                            ...offer,
                                            denominations:
                                              offer.denominations?.map(
                                                (denom, index) => {
                                                  if (denom.id === d.id) {
                                                    return {
                                                      ...denom,
                                                      enabled,
                                                    }
                                                  }
                                                  return denom
                                                },
                                              ),
                                          })

                                          setDLoadingStates((prev) => {
                                            if (prev.includes(d.id!)) {
                                              return prev
                                            } else {
                                              return [...prev, d.id!]
                                            }
                                          })

                                          try {
                                            await publishOfferDenomination(
                                              offer.id!,
                                              d.id,
                                              enabled,
                                            )
                                          } catch (e) {
                                            console.error(e)
                                          }

                                          setDLoadingStates((prev) => {
                                            if (prev.includes(d.id!)) {
                                              return [
                                                ...prev.filter(
                                                  (id) => id !== d.id!,
                                                ),
                                              ]
                                            } else {
                                              return prev
                                            }
                                          })
                                        }}
                                      />
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default OfferPage
