import { GetRequest } from 'src/helpers/lopay_api_helper'
import { SupportPayoutTransactionsDto } from './support-merchant-payouts.type'

export function getPayoutTransactions({
  merchantId,
  payoutId,
  pageSize,
}: {
  merchantId: string
  payoutId: string
  pageSize?: number
}) {
  return function ({
    pageParam,
  }: {
    pageParam?: string
  }): Promise<SupportPayoutTransactionsDto> {
    return GetRequest(
      `support/merchant/${merchantId}/payouts/${payoutId}/transactions`,
      {
        params: {
          ...(pageParam && { offsetId: pageParam }),
          ...(pageSize && { pageSize }),
        },
      },
    )
  }
}
