import React, { useEffect, useMemo, useState } from 'react'
import './card-details.styles.scss'
import Metatags from 'react-meta-tags'
import { loadStripe } from '@stripe/stripe-js'
import { useQueryParams } from '../../hooks/use-query-params.hook'
import {
  requestOtp,
  useCardDetailsToGetMerchantConfig,
  verifyOtp,
} from './card-details.hook'
import { Elements } from '@stripe/react-stripe-js'
import CardDetails from './components/card-details.component'
import { Col, Container, Row } from 'reactstrap'
import CardDetailsVerification from './components/card-details-verification.component'

const CardDetailsPage = () => {
  const [isIOS, setIsIOS] = useState(false)
  const [cardDetailsComponent, setCardDetailsComponent] = useState(() => <></>)
  const [hasVerifiedEmail, setHasVerifiedEmail] = useState(false)
  const [otpRequested, setOtpRequested] = useState(false)
  const [loadedData, setLoadedData] = useState(false)
  const [otp, setOtp] = useState('')
  const [otpError, setOtpError] = useState('')

  const query = useQueryParams()
  const merchantId = useMemo(() => query.get('merchantId'), [query])
  const cardId = useMemo(() => query.get('cardId'), [query])
  const key = useMemo(() => query.get('key'), [query])
  const { data, isLoading, isError } = useCardDetailsToGetMerchantConfig({
    merchantId,
    key,
  })
  const title = 'Lopay Card Details'

  const updateHasVerifiedEmail = (value: boolean) => {
    setHasVerifiedEmail(value)
  }

  const onSubmitOtp = async () => {
    if (otp && merchantId && key) {
      try {
        await verifyOtp(otp, merchantId, key)
        updateHasVerifiedEmail(true)
      } catch (error) {
        setOtpError('Incorrect code')
      }
    }
  }

  const updateOtp = (value: string) => {
    setOtp(value)
  }

  const handleClose = () => {
    window.location.href = `${process.env.REACT_APP_BRANCH_BASE_URL}/card_issuing`
  }

  useEffect(() => {
    const userAgent = window.navigator.userAgent
    setIsIOS(/iPhone|iPad|iPod/.test(userAgent))
  }, [])

  useEffect(() => {
    if (isError) {
      setCardDetailsComponent(
        <p>Something went wrong. Please contact Lopay support for help.</p>,
      )
    } else if (isLoading) {
      setCardDetailsComponent(<p>Loading...</p>)
    } else if (data) {
      if (merchantId && cardId && key) {
        setLoadedData(true)
        if (!hasVerifiedEmail) {
          if (!otpRequested) {
            requestOtp(merchantId, key)
            setOtpRequested(true)
          }
          setCardDetailsComponent(
            <CardDetailsVerification
              updateOtp={updateOtp}
              otpError={otpError}
            ></CardDetailsVerification>,
          )
        } else {
          const stripePromise = loadStripe(data.stripePublicKey, {
            stripeAccount: data.stripeAccountId,
          })
          setCardDetailsComponent(
            <Elements stripe={stripePromise}>
              <CardDetails
                merchantId={merchantId}
                cardId={cardId}
                apiKey={key}
              />
            </Elements>,
          )
        }
      }
    }
  }, [
    hasVerifiedEmail,
    isError,
    isLoading,
    data,
    cardId,
    merchantId,
    key,
    setCardDetailsComponent,
    otpRequested,
    otpError,
  ])

  return (
    <React.Fragment>
      <Metatags>{title}</Metatags>
      <div className="card-details">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={6} md={6} className="col-xxl-3">
              {!isIOS && (
                <div
                  className="card-details-close-button"
                  onClick={handleClose}
                >
                  <i className="fas fa-times fa-lg"></i>
                </div>
              )}
              <div
                className="w-100 card-details-wrapper"
                style={!isIOS ? { padding: '1.5rem 1rem' } : {}}
              >
                <div>
                  <h1 className="card-details-title">
                    {hasVerifiedEmail ? 'Card information' : 'Verify'}
                  </h1>
                  {cardDetailsComponent}
                </div>
                {loadedData && cardDetailsComponent && !hasVerifiedEmail && (
                  <div className="card-details-button-wrapper">
                    <button
                      className="card-details-button"
                      disabled={!otp.length}
                      onClick={onSubmitOtp}
                    >
                      Continue
                    </button>
                    {!isIOS && <span onClick={handleClose}>Cancel</span>}
                  </div>
                )}
                {!isIOS &&
                  loadedData &&
                  cardDetailsComponent &&
                  hasVerifiedEmail && (
                    <button
                      className="card-details-button"
                      onClick={handleClose}
                    >
                      Done
                    </button>
                  )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CardDetailsPage
