import brand from './brand'

/** Font variables */
const fonts = {
  family: '"Montserrat", arial, helvetica, sans-serif',
  sizes: {
    base: '10px',
    standard: '1.4rem',
    small: '1.2rem',
    med: '1.5rem',
    large: '1.8rem',
    larger: '2rem',
    h1: '2.6rem',
    h2: '2.0rem',
    get h3() {
      return this.large
    },
    h4: '1.6rem',
    get h5() {
      return this.standard
    },
    get h6() {
      return this.standard
    },
  },
  weights: {
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    get default() {
      return this.light
    },
  },
  headings: {
    get family() {
      return fonts.family
    },
    get weight() {
      return fonts.weights.regular
    },
    color: brand.headings,
    lineHeight: 1.4,
  },
  lineHeight: {
    base: 1.5,
    med: 1.7,
    large: 2,
  },
}

export default fonts
