import { Input, Label, Modal } from 'reactstrap'
import React, { useState } from 'react'
import { supportCreateMarketingDeepLink } from '../../../helpers/lopay_api_helper'
import MarketingCampaignDeepLinkDto from '../../../api-dtos/marketing/marketing-deep-link.dto'
import { stripEmptyStringsValuesShallow } from '../../../helpers/utils'

type CreateMarketingDeepLinksModalProps = {
  open: boolean
  toggleOpen: (newState: boolean) => void
  onCreate: (deeplink: MarketingCampaignDeepLinkDto) => void
}

type NewMarketingLink = {
  name: string
  urlSuffix: string
  desktopFallback?: string
  utmSource?: string
  utmMedium?: string
  utmCampaign?: string
  adCreativeId?: string
}

const CreateMarketingDeepLinksModal = (
  props: CreateMarketingDeepLinksModalProps,
) => {
  const { open, toggleOpen, onCreate } = props

  const [newDeepLink, setNewDeepLink] = useState<NewMarketingLink>({
    name: '',
    urlSuffix: '',
  })

  const createMarketingLink = async () => {
    if (!newDeepLink) {
      return
    }

    if (!newDeepLink.name.trim().length) {
      alert('Please enter a name')
      return
    }

    if (!newDeepLink.urlSuffix.trim().length) {
      alert('Please enter a URL format')
      return
    }

    if (
      newDeepLink &&
      (newDeepLink.desktopFallback?.length ?? 0) > 0 &&
      !newDeepLink.desktopFallback?.trim().startsWith('http')
    ) {
      alert(
        'Please enter a desktop fallback in a full URL format (EG with https://www.google.com)',
      )
      return
    }

    const deepLinkResponse = await supportCreateMarketingDeepLink({
      name: newDeepLink.name,
      urlSuffix: newDeepLink.urlSuffix,
      desktopFallback: newDeepLink.desktopFallback,
      marketingTokens: stripEmptyStringsValuesShallow({
        utmSource: newDeepLink.utmSource,
        utmMedium: newDeepLink.utmMedium,
        utmCampaign: newDeepLink.utmCampaign,
        adCreativeId: newDeepLink.adCreativeId,
      }),
    })

    onCreate(deepLinkResponse.deepLink)
    setNewDeepLink({
      name: '',
      urlSuffix: '',
    })
    toggleOpen(false)
  }

  return (
    <Modal isOpen={open} toggle={() => toggleOpen(!open)}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Create new deep link
        </h5>
        <button
          type="button"
          onClick={() => {
            setNewDeepLink({
              name: '',
              urlSuffix: '',
            })
            toggleOpen(false)
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="mb-3">
          <Label htmlFor="example-text-input" className="form-Label">
            Name
          </Label>
          <Input
            className="form-control"
            type="text"
            placeholder="Unique name for the link, such as the campaign name"
            onChange={(e) => {
              if (newDeepLink) {
                setNewDeepLink({
                  ...newDeepLink,
                  name: e.target.value,
                })
              }
            }}
            defaultValue=""
            value={newDeepLink.name}
            id="example-text-input"
          />
        </div>

        <div className="mb-3">
          <Label htmlFor="example-text-input" className="form-Label">
            URL Suffix
          </Label>
          <Input
            className="form-control"
            type="text"
            placeholder="Suffix of the URL, EG: link.lopay.com/your-suffix"
            onChange={(e) => {
              if (newDeepLink) {
                setNewDeepLink({
                  ...newDeepLink,
                  urlSuffix: e.target.value,
                })
              }
            }}
            defaultValue=""
            value={newDeepLink.urlSuffix}
            id="example-text-input"
          />
        </div>

        <div className="mb-3">
          <Label htmlFor="example-text-input" className="form-Label">
            Desktop fallback URL (Optional)
          </Label>
          <Input
            className="form-control"
            type="text"
            placeholder="Desktop fallback URL"
            onChange={(e) => {
              if (newDeepLink) {
                setNewDeepLink({
                  ...newDeepLink,
                  desktopFallback: e.target.value,
                })
              }
            }}
            defaultValue=""
            value={newDeepLink.desktopFallback}
            id="example-text-input"
          />
        </div>

        <div className="mb-3">
          <Label htmlFor="utm-source-input" className="form-Label">
            UTM Source (Optional)
          </Label>
          <Input
            className="form-control"
            type="text"
            placeholder="UTM Source"
            onChange={(e) => {
              if (newDeepLink) {
                setNewDeepLink({
                  ...newDeepLink,
                  utmSource: e.target.value,
                })
              }
            }}
            defaultValue=""
            value={newDeepLink.utmSource}
            id="utm-source-input"
          />
        </div>

        <div className="mb-3">
          <Label htmlFor="utm-medium-input" className="form-Label">
            UTM Medium (Optional)
          </Label>
          <Input
            className="form-control"
            type="text"
            placeholder="UTM Medium"
            onChange={(e) => {
              if (newDeepLink) {
                setNewDeepLink({
                  ...newDeepLink,
                  utmMedium: e.target.value,
                })
              }
            }}
            defaultValue=""
            value={newDeepLink.utmMedium}
            id="utm-medium-input"
          />
        </div>

        <div className="mb-3">
          <Label htmlFor="utm-campaign-input" className="form-Label">
            UTM Campaign (Optional)
          </Label>
          <Input
            className="form-control"
            type="text"
            placeholder="UTM Campaign"
            onChange={(e) => {
              if (newDeepLink) {
                setNewDeepLink({
                  ...newDeepLink,
                  utmCampaign: e.target.value,
                })
              }
            }}
            defaultValue=""
            value={newDeepLink.utmCampaign}
            id="utm-campaign-input"
          />
        </div>

        <div className="mb-3">
          <Label htmlFor="ad-creative-id-input" className="form-Label">
            Ad Creative ID (Optional)
          </Label>
          <Input
            className="form-control"
            type="text"
            placeholder="Ad Creative ID"
            onChange={(e) => {
              if (newDeepLink) {
                setNewDeepLink({
                  ...newDeepLink,
                  adCreativeId: e.target.value,
                })
              }
            }}
            defaultValue=""
            value={newDeepLink.adCreativeId}
            id="ad-creative-id-input"
          />
        </div>
      </div>
      <div className="modal-footer">
        <button
          type="button"
          onClick={() => {
            setNewDeepLink({
              name: '',
              urlSuffix: '',
            })
            toggleOpen(false)
          }}
          className="btn btn-secondary "
          data-dismiss="modal"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            createMarketingLink()
          }}
          type="button"
          className="btn btn-primary"
        >
          Create marketing link
        </button>
      </div>
    </Modal>
  )
}

export default CreateMarketingDeepLinksModal
