import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'

import { MerchantRiskScore } from 'src/api-dtos/support/merchant/support-merchant-api.dto.interface'

const StyledMerchantRiskScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StyledMerchantRiskScoreTitle = styled.h5`
  text-align: center;
`

const StyledMerchantRiskScore = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
`

const StyledMerchantRiskScoreLastUpdated = styled.div`
  text-align: center;
`

type IComponentProps = {
  riskScores: MerchantRiskScore[] | undefined
}

const MerchantRiskScoreComponent: React.FC<IComponentProps> = ({
  riskScores,
}) => {
  const latestRiskScore: MerchantRiskScore | undefined = React.useMemo(
    () => (riskScores ?? [])[0],
    [riskScores],
  )

  const latestRiskScoreNumberFormatted = React.useMemo(
    () => latestRiskScore?.fraudLikelihoodScore ?? '---',
    [latestRiskScore],
  )

  const latestRiskScoreLastUpdated = React.useMemo(
    () =>
      latestRiskScore && latestRiskScore.createdAt
        ? moment(latestRiskScore.createdAt).fromNow()
        : undefined,
    [latestRiskScore],
  )

  const riskScoreClasses = React.useMemo(() => {
    const hasScore =
      latestRiskScore?.fraudLikelihoodScore !== undefined &&
      latestRiskScore?.fraudLikelihoodScore !== null

    const score = latestRiskScore?.fraudLikelihoodScore ?? 0

    return classNames(
      'font-weight-semibold',
      'display-6',
      'p-2',
      'rounded-2',
      'text-center',
      {
        'text-white': hasScore,
        'bg-success': hasScore && score < 70,
        'bg-warning': score >= 70 && score < 90,
        'bg-danger': score >= 90,
      },
    )
  }, [latestRiskScore])

  return (
    <StyledMerchantRiskScoreContainer>
      <StyledMerchantRiskScoreTitle>Risk Score</StyledMerchantRiskScoreTitle>
      <StyledMerchantRiskScore>
        <div style={{ minWidth: '64px' }} className={riskScoreClasses}>
          {latestRiskScoreNumberFormatted}
        </div>
      </StyledMerchantRiskScore>
      {latestRiskScoreLastUpdated && (
        <StyledMerchantRiskScoreLastUpdated>
          <span className="text-muted font-size-11">
            Last updated {latestRiskScoreLastUpdated}
          </span>
        </StyledMerchantRiskScoreLastUpdated>
      )}
    </StyledMerchantRiskScoreContainer>
  )
}

export default MerchantRiskScoreComponent
