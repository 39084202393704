import React from 'react'
import { Button, Spinner, Table } from 'reactstrap'
import styled from 'styled-components'

import { useRewardActivity } from './support-merchant-reward-activity.hook'
import moment from 'moment'
import { priceFormat } from 'src/helpers/utils'

const StyledCenterContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`

const StyledSpacer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`

type IComponentProps = {
  merchantId: string
  isActive: boolean
}

const SupportMerchantRewardActivity: React.FC<IComponentProps> = ({
  merchantId,
  isActive,
}) => {
  const results = useRewardActivity({
    isActive,
    merchantId,
    pageSize: 20,
  })

  const data = React.useMemo(
    () => (results?.data?.pages ?? []).flatMap((page) => page.items),
    [results.data],
  )

  if (results.isLoading && data.length === 0) {
    return <Spinner className="ms-2" color="primary" />
  }

  if (results.isError) {
    return (
      <div>
        Something went wrong whilst loading reward activity. Please try again.
      </div>
    )
  }

  if (data.length === 0) {
    return (
      <React.Fragment>
        <div
          className="table-responsive"
          style={{
            paddingBottom: 550,
            marginBottom: -550,
            msOverflowStyle: 'none',
          }}
        >
          <StyledCenterContainer>
            The merchant has no reward activity.
          </StyledCenterContainer>
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Table responsive striped className="mb-0">
        <thead>
          <tr>
            <th>Date</th>
            <th>Type</th>
            <th>Title</th>
            <th>Activity amount</th>
            <th>Reward amount</th>
            <th>Reward from</th>
          </tr>
        </thead>
        <tbody>
          {(data ?? []).map((activity) => {
            return (
              <tr key={activity.id}>
                <td>
                  {moment(activity.createdAt).local().format('DD-MM-YY hh:mm')}
                </td>
                <td>{activity.type}</td>
                <td>{activity.title}</td>
                <td>{priceFormat(activity.activityValue)}</td>
                <td>{priceFormat(activity.rewardValue)}</td>
                <td>{activity.rewardFrom}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <StyledSpacer />
      {results.hasNextPage && (
        <Button
          type="button"
          color="primary"
          disabled={results.isLoading}
          onClick={() => results.fetchNextPage()}
        >
          Load more
        </Button>
      )}
    </React.Fragment>
  )
}

export default SupportMerchantRewardActivity
