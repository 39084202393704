import { HardwarePriceDto } from '../../hardware.type'
import { PostRequest } from '../../../../helpers/lopay_api_helper'

export async function addHardwarePrices(
  hardwareId: string,
  prices: Omit<HardwarePriceDto, 'id'>[],
): Promise<HardwarePriceDto> {
  return PostRequest<HardwarePriceDto>(
    `support/hardware/${hardwareId}/prices`,
    {
      prices,
    },
  )
}

export async function deleteHardwarePrices(priceIds: string[]): Promise<void> {
  await PostRequest(`support/hardware/delete-prices`, {
    priceIds,
  })
}
