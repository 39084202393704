import { GetRequest } from 'src/helpers/lopay_api_helper'
import { SupportMerchantAccountSetupResponseDto } from './support-merchant-account-setup.type'

export function getMerchantAccountSetup({
  merchantId,
}: {
  merchantId: string
}) {
  return GetRequest<SupportMerchantAccountSetupResponseDto>(
    `support/merchant/${merchantId}/account-setup`,
  )
}
