import styled from 'styled-components'

const TimelineItemContent = styled.p`
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 0;
  color: rgb(107, 114, 128);
`

export default TimelineItemContent
