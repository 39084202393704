import { Stripe } from '@stripe/stripe-js'
import { useQuery } from 'react-query'
import { GetRequest, PostRequest } from 'src/helpers/lopay_api_helper'

export async function requestOtp(merchantId: string, key: string) {
  await PostRequest<any>(`/merchant/${merchantId}/request-owner-otp`, null, {
    headers: {
      Authorization: `Bearer ${key}`,
    },
  })
}

export async function verifyOtp(otp: string, merchantId: string, key: string) {
  await PostRequest<any>(
    `/merchant/${merchantId}/verify-owner-otp`,
    {
      code: otp,
    },
    {
      headers: {
        Authorization: `Bearer ${key}`,
      },
    },
  )
}

export function hideAndShowCopySuccess(
  copyElementId: string,
  copySuccessIconElementId: string,
) {
  const copyEl = document.getElementById(copyElementId)
  if (copyEl) {
    copyEl.style.display = 'none'
    const copySuccessEl = document.getElementById(copySuccessIconElementId)
    if (copySuccessEl) {
      copySuccessEl.style.display = 'block'
      timeout(2000).then(() => {
        copySuccessEl.style.display = 'none'
        copyEl.style.display = 'block'
      })
    }
  }
}

export function useCardDetailsToGetMerchantConfig({
  merchantId,
  key,
}: {
  merchantId: string | null
  key: string | null
}) {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['card-details-merchant-config', merchantId, key],
    queryFn: () =>
      merchantId && key
        ? GetRequest<any>(`/merchant/${merchantId}/config`, {
            headers: {
              Authorization: `Bearer ${key}`,
            },
          })
        : Promise.resolve(undefined),
  })
}

export function useCardDetailsToCreateEphemeralKey({
  merchantId,
  cardId,
  key,
  stripe,
}: {
  merchantId: string | null
  cardId: string | null
  key: string | null
  stripe: Stripe | null
}) {
  return useQuery({
    refetchOnWindowFocus: false,
    queryKey: ['card-details-status', merchantId, cardId, key, Boolean(stripe)],
    queryFn: () =>
      merchantId && cardId && key && stripe
        ? createEphemeralKey({ merchantId, cardId, key, stripe })
        : Promise.resolve(undefined),
  })
}

async function createEphemeralKey({
  merchantId,
  cardId,
  key,
  stripe,
}: {
  merchantId: string
  cardId: string
  key: string
  stripe: Stripe
}) {
  const getCardResult = await GetRequest<any>(
    `/merchant/${merchantId}/card-issuing/cards/${cardId}`,
    {
      headers: {
        Authorization: `Bearer ${key}`,
      },
    },
  )

  const stripeCardId = getCardResult.stripeId
  const nonceResult = await stripe.createEphemeralKeyNonce({
    issuingCard: stripeCardId,
  })
  const nonce = nonceResult.nonce

  const ephemeralkey = await GetRequest<any>(
    `/merchant/${merchantId}/card-issuing/cards/${cardId}/ephemeral-key`,
    {
      params: {
        nonce,
      },
      headers: {
        Authorization: `Bearer ${key}`,
      },
    },
  )

  return {
    secret: ephemeralkey.secret,
    providerId: ephemeralkey.providerId,
    chipAndPinRequired: getCardResult.chipAndPinRequired,
    nonce,
  }
}

function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
