import React, { useMemo } from 'react'
import Metatags from 'react-meta-tags'
import { Row, Col, Container } from 'reactstrap'

import { ReactComponent as LopayQuickbooksLogo } from '../../assets/images/quickbooks/lopay_quickbooks.svg'
import { useQueryParams } from 'src/hooks/use-query-params.hook'
import { useQuickBooksStatus } from './quickbooks.hook'
import QuickbooksConfig from './components/quickbooks-config/quickbooks-config.component'
import QuickbooksStartConnection from './components/quickbooks-start-connection/quickbooks-start-connection.component'
import QuickBooksError, {
  ErrorCodes,
} from './components/quickbooks-error/quickbooks-error.component'

type IComponentProps = {}

const QuickbooksPage: React.FC<IComponentProps> = () => {
  const query = useQueryParams()
  const merchantId = useMemo(() => query.get('merchantId'), [query])
  const key = useMemo(() => query.get('key'), [query])
  const errorCode = useMemo(() => query.get('errorCode'), [query])

  const { data, isLoading, isError } = useQuickBooksStatus({ merchantId, key })
  const title = data?.connected
    ? 'Configure QuickBooks | Lopay'
    : 'Connect QuickBooks | Lopay'

  const component = () => {
    if (errorCode) {
      return <QuickBooksError errorCode={errorCode as ErrorCodes} />
    }

    if (isError) {
      return <QuickBooksError errorCode="server_error" />
    }

    if (isLoading) {
      return <div>Loading...</div>
    }

    if (merchantId && key) {
      if (data?.connected) {
        return (
          <QuickbooksConfig
            merchantId={merchantId}
            apiKey={key}
            companyName={data.companyName}
            status={data.status}
            lastSyncDate={data.lastSyncDate}
          />
        )
      } else {
        return (
          <QuickbooksStartConnection merchantId={merchantId} apiKey={key} />
        )
      }
    }

    return <QuickBooksError errorCode="missing_parameters" />
  }

  return (
    <React.Fragment>
      <Metatags>{title}</Metatags>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={6} md={6} className="col-xxl-3">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <LopayQuickbooksLogo height={50} width={200} />
                    </div>
                    <div className="auth-content my-auto">{component()}</div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Lopay . Crafted with{' '}
                        <i className="mdi mdi-heart text-danger"></i> by Lopay
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default QuickbooksPage
