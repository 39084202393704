import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from 'reactstrap'
import Breadcrumbs from '../../components/Common/Breadcrumb'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import { useFraudRules } from './fraud-framework.hook'
import { groupBy } from 'lodash'
import FraudRule from './components/fraud-rule.component'
import SupportUserApiDto from '../../api-dtos/support/user/support-user-api.dto.interface'
import { getLoggedInSupportUser } from '../../helpers/lopay_api_helper'

try {
  TimeAgo.addDefaultLocale(en)
} catch (e) {
  console.error(e)
}
interface PageProps {
  history: any
}

const FraudRulesPage = ({ history }: PageProps) => {
  const result = useFraudRules()

  const groupByAreaRules = result.data
    ? groupBy(result.data.rules, (r) => r.area)
    : []

  const [supportUser, setSupportUser] = useState<SupportUserApiDto | null>(null)

  useEffect(() => {
    const loggedInUser = getLoggedInSupportUser()
    setSupportUser((loggedInUser?.user as SupportUserApiDto) ?? null)
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Fraud Rules</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title={'Fraud Prevention'}
            breadcrumbItem="Fraud Rules"
          />
          <Row>
            <Col xs={12}>
              {result.isLoading && (
                <div className="d-flex justify-content-center">
                  <Spinner color="primary" />
                </div>
              )}
              {result.error && (
                <p className="card-text">Could not retrieve fraud rules.</p>
              )}
              {Object.entries(groupByAreaRules).map(([area, rules]) => (
                <Card>
                  <CardHeader>
                    <h5 className="card-title">{area}</h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      {rules.map((r) => (
                        <FraudRule
                          refetchFn={result.refetch}
                          isSuperUser={
                            supportUser?.accessRights === 'SUPER_USER'
                          }
                          fraudRule={r}
                        />
                      ))}
                    </Row>
                  </CardBody>
                </Card>
              ))}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FraudRulesPage
