import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import ReactTimeAgo from 'react-time-ago'
import { Badge, Card, CardBody, Input, Label, Modal, Table } from 'reactstrap'
import styled from 'styled-components'
import Select from 'react-select'

import { HardwareDto, HardwarePriceDto } from '../../hardware.type'
import {
  convertToUnits,
  currencyFormat,
  percentageFormat,
} from 'src/helpers/utils'
import { fetchCountries } from 'src/helpers/lopay_api_helper'
import CountryAPIResponse from 'src/api-dtos/country/countries-response.dto.interface'
import { updateHardware } from '../../hardware.api'
import {
  formatNiceName,
  formatNotNiceName,
  formatValueBasedOnKey,
  getDifferencesForUpdatedHardware,
  getNiceNameOfField,
} from '../../hardware.utils'
import HardwareSubscriptionComponent from '../hardware-subscription/hardware-subscription.component'
import getSymbolFromCurrency from 'currency-symbol-map'
import {
  addHardwarePrices,
  deleteHardwarePrices,
} from '../hardware-subscription/hardware-price.api'

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`

type IComponentProps = {
  data: HardwareDto[] | undefined
  isLoading: boolean
  isError: boolean
  onDeleteHardware: (hardware: HardwareDto) => void
  deletingHardware: boolean
}

const HardwareTable: React.FC<IComponentProps> = ({
  data,
  isLoading,
  isError,
  onDeleteHardware,
  deletingHardware,
}) => {
  const history = useHistory()
  const [selectedHardware, setSelectedHardware] = useState<HardwareDto>()
  const [newHardwareCostDecimal, setNewHardwareCostDecimal] = useState('')
  const [newPostageCostDecimal, setNewPostageCostDecimal] = useState('')
  const [newFirstOrderDiscountDecimal, setNewFirstOrderDiscountDecimal] =
    useState('')
  const [copyHardwareIdText, setCopyHardwareIdText] = useState('Copy')
  const [countriesOptions, setCountriesOptions] = useState<
    { label: string; value: string }[]
  >([])
  const [selectedHardwareHasParent, setSelectedHardwareHasParent] =
    useState(false)
  const [selectedHardwareParentOption, setSelectedHardwareParentOption] =
    useState<{ label: string; value: string } | null>()
  const [countriesData, setCountriesData] = useState<CountryAPIResponse[]>()
  const [countriesLoading, setCountriesLoading] = useState(true)
  const [requestedHardwareUpdateBody, setRequestedHardwareUpdateBody] =
    useState<Partial<HardwareDto> | undefined>()
  const [
    requestedHardwareUpdateDifferences,
    setRequestedHardwareUpdateDifferences,
  ] = useState<Record<string, { oldValue: any; newValue: any }> | undefined>()
  const [updateForAllMerchants, setUpdateForAllMerchants] = useState(false)
  const [overwriteDefaultTerminals, setOverwriteDefaultTerminals] =
    useState(false)
  const [
    countriesWithExistingDefaultTerminal,
    setCountriesWithExistingDefaultTerminal,
  ] = useState<string[]>([])

  const [pricesToDelete, setPricesToDelete] = useState<HardwarePriceDto[]>([])
  const [pricesToAdd, setPricesToAdd] = useState<
    Omit<HardwarePriceDto, 'id'>[]
  >([])

  const [confirmChanges, setConfirmChanges] = useState<boolean>(false)

  const handleAddHardwarePrices = (prices: Omit<HardwarePriceDto, 'id'>[]) => {
    setPricesToAdd(prices)
  }

  const handleDeleteHardwarePrices = (prices: HardwarePriceDto[]) => {
    setPricesToDelete(prices)
  }

  const copyHardwareId = (id: string) => {
    navigator.clipboard.writeText(id).then(
      () => {
        setCopyHardwareIdText('Copied 🎉')
      },
      () => {
        setCopyHardwareIdText('Could not copy ID 😭')
      },
    )

    setTimeout(function () {
      setCopyHardwareIdText('Copy')
    }, 2500)
  }

  const onSelectHardware = (hardware: HardwareDto) => {
    console.log(hardware)
    if (deletingHardware) return
    setNewHardwareCostDecimal(currencyFormat(hardware.hardwareCostUnits))
    setNewPostageCostDecimal(currencyFormat(hardware.postageCostUnits))
    setNewFirstOrderDiscountDecimal(
      currencyFormat(hardware.firstOrderDiscountUnits),
    )
    const parentHardware = data!
      .filter((h) => h.id !== hardware?.id)
      .find((h) => h.id === hardware?.hardwareParentId)
    setSelectedHardwareHasParent(!!parentHardware)
    setSelectedHardwareParentOption(
      parentHardware
        ? {
            label: formatNiceName(parentHardware.name),
            value: parentHardware.id!,
          }
        : null,
    )
    setSelectedHardware(hardware)
  }

  const handleFetchCountries = async () => {
    const countries = await fetchCountries()
    setCountriesData(countries)
    setCountriesOptions(
      countries.map((country) => ({
        label: `${country.flag} ${country.name}`,
        value: country.code,
      })),
    )
    setCountriesLoading(false)
  }

  const updateCountriesWithExistingTerminal = (selectedCountries: string[]) => {
    if (!data) return
    const countries = []
    console.log(selectedCountries)
    for (const countryCode of selectedCountries) {
      const defaultTerminalHardwareForCountry = data.find(
        (h) =>
          h.isDefaultTerminalForCountry &&
          h.countriesAvailableIn.includes(countryCode),
      )
      if (defaultTerminalHardwareForCountry) {
        const country = countriesOptions.find(
          (countryOption) => countryOption.value === countryCode,
        )
        countries.push(country!.label)
      }
    }
    setCountriesWithExistingDefaultTerminal(countries)
  }

  const onUpdateHardware = async () => {
    if (!selectedHardware) return
    const originalHardware = data?.find((h) => h.id === selectedHardware.id)
    const differencesForHardware = getDifferencesForUpdatedHardware(
      originalHardware!,
      selectedHardware,
    )
    console.log(differencesForHardware)
    console.log({
      pricesToAdd,
      pricesToDelete,
    })
    if (
      !Object.keys(differencesForHardware).length &&
      !pricesToAdd.length &&
      !pricesToDelete.length
    ) {
      setSelectedHardware(undefined)
    }

    if (Object.keys(differencesForHardware).length) {
      const updateBody: Partial<HardwareDto> = {}
      for (const key in differencesForHardware) {
        const value = differencesForHardware[key]
        updateBody[key as keyof HardwareDto] = value.newValue
      }
      setRequestedHardwareUpdateBody(updateBody)
      setRequestedHardwareUpdateDifferences(differencesForHardware)
    }

    if (
      Object.keys(differencesForHardware).length ||
      pricesToAdd.length ||
      pricesToDelete.length
    ) {
      setConfirmChanges(true)
    }
  }

  const onConfirmUpdateHardware = async () => {
    try {
      if (requestedHardwareUpdateBody) {
        await updateHardware(
          selectedHardware!.id!,
          requestedHardwareUpdateBody!,
          updateForAllMerchants,
          overwriteDefaultTerminals,
        )
      }
      if (pricesToAdd.length) {
        await addHardwarePrices(selectedHardware!.id!, pricesToAdd)
      }
      if (pricesToDelete.length) {
        await deleteHardwarePrices(pricesToDelete.map((p) => p.id))
      }
    } catch (error) {
      console.log(error)
    } finally {
      history.go(0)
    }
  }

  useEffect(() => {
    handleFetchCountries()
  }, [])

  if (isLoading || countriesLoading) {
    return <div>Loading ...</div>
  }

  if (isError) {
    return <div>Error loading hardware. Please try again.</div>
  }

  if (!data || !data.length) {
    return (
      <React.Fragment>
        <div
          className="table-responsive"
          style={{
            paddingBottom: 550,
            marginBottom: -550,
            msOverflowStyle: 'none',
          }}
        >
          <CenterContainer>No hardware found</CenterContainer>
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div
        className="table-responsive"
        style={{
          paddingBottom: 550,
          marginBottom: -550,
          msOverflowStyle: 'none',
        }}
      >
        <Table className="table table-striped mb-0">
          <thead>
            <tr>
              <th>Name</th>
              <th>Cost</th>
              <th>Postage Cost</th>
              <th>VAT</th>
              <th>Mintsoft Stock Level</th>
              <th>Available In</th>
              <th>Details</th>
              <th>Last Updated</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((hardware) => {
              const parentName = hardware.hardwareParentId
                ? data.find((h) => h.id === hardware.hardwareParentId)?.name
                : ''
              const mintsoftStockNotRequired =
                !hardware.countriesAvailableIn.includes('GB')
              return (
                <tr
                  key={hardware.id}
                  onClick={() => onSelectHardware(hardware)}
                >
                  <td>
                    <b>{formatNiceName(hardware.name)}</b>
                    <br />
                    <small>{hardware.name}</small>
                  </td>
                  <td>£{currencyFormat(hardware.hardwareCostUnits)}</td>
                  <td>£{currencyFormat(hardware.postageCostUnits)}</td>
                  <td>{percentageFormat(hardware.vatPercent)}</td>
                  <td>
                    {mintsoftStockNotRequired
                      ? 'N/A'
                      : hardware.mintsoftStockLevel}
                  </td>
                  <td>
                    {hardware.countriesAvailableIn.map((countryCode) => {
                      const country = countriesData!.find(
                        (c) => c.code === countryCode,
                      )
                      if (!country) return null
                      return (
                        <Badge className="me-2 bg-light">
                          {country?.flag} {country?.name}
                        </Badge>
                      )
                    })}
                  </td>
                  <td>
                    {hardware.isDefaultTerminalForCountry ? (
                      <Badge className="me-2 bg-primary">
                        Default Card Reader For{' '}
                        {hardware.countriesAvailableIn.length > 1
                          ? 'Countries'
                          : 'Country'}
                      </Badge>
                    ) : null}
                    {hardware.isTerminal ? (
                      <Badge className="me-2 bg-info">Terminal</Badge>
                    ) : null}
                    {hardware.hardwareParentId ? (
                      parentName ? (
                        <Badge className="me-2 bg-info">
                          Accessory of {formatNiceName(parentName)}
                        </Badge>
                      ) : (
                        <Badge className="me-2 bg-danger">
                          Accessory of missing parent:{' '}
                          {hardware.hardwareParentId}
                        </Badge>
                      )
                    ) : null}
                    {hardware.fallbackToStripe && !hardware.stripeSku ? (
                      <Badge className="me-2 bg-danger">SKU required</Badge>
                    ) : null}
                    {!hardware.fallbackToStripe && !hardware.mintsoftSku ? (
                      <Badge className="me-2 bg-danger">SKU required</Badge>
                    ) : null}
                    {hardware.firstOrderDiscountUnits > 0 ? (
                      <Badge className="me-2 bg-success">
                        £{currencyFormat(hardware.firstOrderDiscountUnits)}{' '}
                        First Order Discount
                      </Badge>
                    ) : null}
                    {!hardware.fallbackToStripe ? (
                      <Badge className="me-2 bg-warning">Mintsoft Only</Badge>
                    ) : null}
                  </td>
                  <td>
                    <ReactTimeAgo date={hardware.updatedAt!} />
                  </td>
                  <td>
                    <Link
                      onClick={() => onSelectHardware(hardware)}
                      to="#"
                      className="btn btn-primary"
                    >
                      Edit
                    </Link>
                  </td>
                  <td>
                    <Link
                      onClick={() => {
                        onDeleteHardware(hardware)
                      }}
                      to="#"
                      className="btn btn-danger"
                    >
                      Delete
                    </Link>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>

        <Modal
          size="lg"
          isOpen={!!selectedHardware}
          toggle={() => {
            setSelectedHardware(undefined)
            setPricesToAdd([])
            setPricesToDelete([])
          }}
          scrollable={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">
              {formatNiceName(selectedHardware?.name!)}
              <br />
              <p className="card-title-desc mb-2">{selectedHardware?.name}</p>
            </h5>
            <button
              type="button"
              onClick={() => {
                setSelectedHardware(undefined)
                setPricesToAdd([])
                setPricesToDelete([])
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div>
              <div className="mb-4">
                <h5 className="font-size-14 mb-1">Hardware Details</h5>
                <p className="card-title-desc mb-2">
                  ID: {selectedHardware?.id}
                  <b>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="#"
                      onClick={() => copyHardwareId(selectedHardware?.id!)}
                    >
                      {` [${copyHardwareIdText}]`}
                    </a>
                  </b>
                </p>
                <p className="card-title-desc mb-2">
                  Mintsoft Stock Level:{' '}
                  {!selectedHardware?.countriesAvailableIn.includes('GB')
                    ? 'N/A'
                    : selectedHardware.mintsoftStockLevel}
                </p>

                <div>
                  <div className="mb-3">
                    <Label htmlFor="hardware-name" className="form-Label">
                      Name
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="Name of Hardware"
                      onChange={(e) => {
                        setSelectedHardware({
                          ...selectedHardware!,
                          name: formatNotNiceName(e.target.value),
                        })
                      }}
                      defaultValue={formatNiceName(selectedHardware?.name!)}
                      id="hardware-name"
                    />
                    <p className="card-title-desc mb-2">
                      {selectedHardware?.name}
                    </p>
                  </div>

                  <div className="mb-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="is-terminal"
                        defaultChecked={selectedHardware?.isTerminal}
                        onChange={() =>
                          setSelectedHardware({
                            ...selectedHardware!,
                            isTerminal: !selectedHardware?.isTerminal,
                          })
                        }
                      />
                      <label className="form-check-label" htmlFor="is-terminal">
                        Is a Terminal
                      </label>
                    </div>
                  </div>

                  {selectedHardware?.isTerminal ? (
                    <div className="mb-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="is-default-terminal"
                          defaultChecked={
                            selectedHardware!.isDefaultTerminalForCountry
                          }
                          onChange={() => {
                            setSelectedHardware({
                              ...selectedHardware!,
                              isDefaultTerminalForCountry:
                                !selectedHardware!.isDefaultTerminalForCountry,
                            })
                            updateCountriesWithExistingTerminal(
                              selectedHardware!.countriesAvailableIn!,
                            )
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="is-default-terminal"
                        >
                          Is Default Terminal for country/countries
                        </label>
                        {selectedHardware!.isDefaultTerminalForCountry &&
                        countriesWithExistingDefaultTerminal.length ? (
                          <>
                            <br />
                            <strong className="mb-0 text-danger">
                              The following countries already have default
                              terminals :
                            </strong>
                            {countriesWithExistingDefaultTerminal.map(
                              (country) => (
                                <p className="mb-0 text-danger">{country}</p>
                              ),
                            )}
                            <p className="mb-0 text-danger">
                              (This can be ignored if this is already the
                              default terminal for the chosen countries)
                            </p>
                          </>
                        ) : null}
                      </div>
                    </div>
                  ) : null}

                  {selectedHardware?.isTerminal &&
                  selectedHardware?.isDefaultTerminalForCountry &&
                  countriesWithExistingDefaultTerminal.length ? (
                    <div className="mb-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="overwrite-default-terminal"
                          defaultChecked={overwriteDefaultTerminals}
                          onChange={() => {
                            setOverwriteDefaultTerminals(
                              !overwriteDefaultTerminals,
                            )
                          }}
                        />
                        <label
                          className="form-check-label text-danger"
                          htmlFor="overwrite-default-terminal"
                        >
                          Overwrite{' '}
                          <strong>
                            {selectedHardware!.name
                              ? formatNiceName(selectedHardware!.name)
                              : 'this terminal'}
                          </strong>{' '}
                          as the new default for the above countries
                        </label>
                      </div>
                    </div>
                  ) : null}

                  <div className="mb-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="is-accessory"
                        defaultChecked={selectedHardwareHasParent}
                        onChange={() => {
                          setSelectedHardwareHasParent(
                            !selectedHardwareHasParent,
                          )
                          if (selectedHardwareHasParent) {
                            setSelectedHardware({
                              ...selectedHardware!,
                              hardwareParentId: null,
                            })
                          } else if (selectedHardwareParentOption) {
                            setSelectedHardware({
                              ...selectedHardware!,
                              hardwareParentId:
                                selectedHardwareParentOption?.value,
                            })
                          }
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="is-accessory"
                      >
                        Is an accessory to another hardware item
                      </label>
                    </div>
                  </div>

                  {selectedHardwareHasParent ? (
                    <div className="mb-3">
                      <Label
                        htmlFor="choices-multiple-default"
                        className="form-label"
                      >
                        Parent Hardware
                      </Label>
                      <Select
                        value={selectedHardwareParentOption}
                        onChange={(selection: {
                          label: string
                          value: string
                        }) => {
                          setSelectedHardware({
                            ...selectedHardware!,
                            hardwareParentId: selection.value,
                          })
                          setSelectedHardwareParentOption(selection)
                        }}
                        options={data
                          .filter((h) => h.id !== selectedHardware?.id)
                          .map((h) => {
                            return {
                              label: formatNiceName(h.name),
                              value: h.id,
                            }
                          })}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  ) : null}

                  <div className="mb-3">
                    <Label
                      htmlFor="choices-multiple-default"
                      className="form-label"
                    >
                      Countries Available In
                    </Label>
                    <Select
                      defaultValue={selectedHardware?.countriesAvailableIn.map(
                        (selectedCountryCode) => {
                          const country = countriesOptions.find(
                            (co) => co.value === selectedCountryCode,
                          )
                          return country
                        },
                      )}
                      onChange={(list: { label: string; value: string }[]) => {
                        setSelectedHardware({
                          ...selectedHardware!,
                          countriesAvailableIn: list.map((i) => i.value),
                        })
                      }}
                      isMulti
                      options={countriesOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </div>

                  <hr />

                  <div className="mb-3">
                    <Label htmlFor="hardware-cost" className="form-Label">
                      Hardware Cost
                    </Label>

                    <div className="input-group">
                      <div className="input-group-text">£</div>
                      <Input
                        type="number"
                        min="1"
                        step="any"
                        className="form-control"
                        value={newHardwareCostDecimal}
                        onChange={(e) => {
                          setNewHardwareCostDecimal(e.target.value)
                          setSelectedHardware({
                            ...selectedHardware!,
                            hardwareCostUnits: convertToUnits(e.target.value),
                          })
                        }}
                        id="hardware-cost"
                        placeholder="0.00"
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <Label htmlFor="postage-cost" className="form-Label">
                      Postage Cost
                    </Label>

                    <div className="input-group">
                      <div className="input-group-text">£</div>
                      <Input
                        type="number"
                        min="1"
                        step="any"
                        className="form-control"
                        value={newPostageCostDecimal}
                        onChange={(e) => {
                          setNewPostageCostDecimal(e.target.value)
                          setSelectedHardware({
                            ...selectedHardware!,
                            postageCostUnits: convertToUnits(e.target.value),
                          })
                        }}
                        id="postage-cost"
                        placeholder="0.00"
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <Label htmlFor="discount-amount" className="form-Label">
                      First Order Discount Amount
                    </Label>

                    <div className="input-group">
                      <div className="input-group-text">£</div>
                      <Input
                        type="number"
                        min="1"
                        step="any"
                        className="form-control"
                        value={newFirstOrderDiscountDecimal}
                        onChange={(e) => {
                          setNewFirstOrderDiscountDecimal(e.target.value)
                          setSelectedHardware({
                            ...selectedHardware!,
                            firstOrderDiscountUnits: convertToUnits(
                              e.target.value,
                            ),
                          })
                        }}
                        id="discount-amount"
                        placeholder="0.00"
                      />
                    </div>
                  </div>

                  <div className="mb-3">
                    <Label htmlFor="vat-percent" className="form-Label">
                      VAT
                    </Label>

                    <div className="input-group">
                      <Input
                        type="number"
                        min="0"
                        max="100"
                        step="any"
                        className="form-control"
                        value={(selectedHardware?.vatPercent! * 100).toString()}
                        onChange={(e) =>
                          setSelectedHardware({
                            ...selectedHardware!,
                            vatPercent: Number(e.target.value) / 100,
                          })
                        }
                        id="discount-amount"
                        placeholder="0"
                      />
                      <div className="input-group-text">%</div>
                    </div>
                  </div>

                  {selectedHardware?.isTerminal && (
                    <>
                      <hr />
                      <HardwareSubscriptionComponent
                        hardware={selectedHardware}
                        addHardwarePrices={handleAddHardwarePrices}
                        deleteHardwarePrices={handleDeleteHardwarePrices}
                      />
                    </>
                  )}

                  <hr />

                  <div className="mb-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="fallback-to-stripe"
                        defaultChecked={!selectedHardware?.fallbackToStripe}
                        onChange={() =>
                          setSelectedHardware({
                            ...selectedHardware!,
                            fallbackToStripe:
                              !selectedHardware?.fallbackToStripe,
                          })
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="fallback-to-stripe"
                      >
                        Provided by Mintsoft Only
                      </label>
                    </div>
                  </div>

                  {selectedHardware?.fallbackToStripe ? (
                    <div className="mb-3">
                      <Label
                        htmlFor="hardware-stripe-sku"
                        className="form-Label"
                      >
                        Stripe SKU
                      </Label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="thsku_abcXYZ123"
                        onChange={(e) => {
                          setSelectedHardware({
                            ...selectedHardware!,
                            stripeSku: e.target.value,
                          })
                        }}
                        defaultValue={selectedHardware?.stripeSku!}
                        id="hardware-stripe-sku"
                      />
                    </div>
                  ) : null}

                  <div className="mb-3">
                    <Label
                      htmlFor="hardware-mintsoft-sku"
                      className="form-Label"
                    >
                      Mintsoft SKU
                    </Label>
                    <Input
                      className="form-control"
                      type="text"
                      placeholder="ABC-123"
                      onChange={(e) => {
                        setSelectedHardware({
                          ...selectedHardware!,
                          mintsoftSku: e.target.value,
                        })
                      }}
                      defaultValue={selectedHardware?.mintsoftSku!}
                      id="hardware-mintsoft-sku"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button
              onClick={(e) => {
                e.preventDefault()
                onUpdateHardware()
              }}
              type="button"
              className="btn btn-primary"
            >
              Save Hardware Changes
            </button>
          </div>
        </Modal>

        {confirmChanges ? (
          <Modal
            isOpen={confirmChanges}
            toggle={() => {
              setConfirmChanges(false)
            }}
            scrollable={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">
                Are you sure you want to update this hardware?
              </h5>
              <button
                type="button"
                onClick={() => {
                  setConfirmChanges(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Card
                className={`border ${updateForAllMerchants ? 'border-danger' : 'border-warning'}`}
              >
                <div
                  className={`card-header bg-transparent ${updateForAllMerchants ? 'border-danger' : 'border-warning'}`}
                >
                  <h5
                    className={`my-0 ${updateForAllMerchants ? 'text-danger' : 'text-warning'}`}
                  >
                    <i className="mdi mdi-block-helper me-3"></i>Information
                    about these changes
                  </h5>
                </div>
                <CardBody>
                  {updateForAllMerchants ? (
                    <p className="card-text">
                      This change means that{' '}
                      <u>
                        any merchant that has merchant hardware the same as the
                        current hardware
                      </u>{' '}
                      will be updated to receive these changes. Any merchant
                      that has a custom hardware compared to the existing base
                      hardware will not be affected.
                    </p>
                  ) : (
                    <>
                      <p className="card-text">
                        This change will mean all <u>new merchants</u> will be
                        affected by these changes once submitted. Please ensure
                        the below changes are correct.
                      </p>
                      <p className="card-text">
                        <b>NOTE:</b> Subscription changes are currently applied
                        to all merchants, regardless of how the other changes
                        are applied.
                      </p>
                    </>
                  )}
                </CardBody>
              </Card>

              <div className="mb-3">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="update-all-merchants"
                    defaultChecked={updateForAllMerchants}
                    onChange={() =>
                      setUpdateForAllMerchants(!updateForAllMerchants)
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="update-all-merchants"
                  >
                    Update <u>all existing merchant's</u> hardware with these
                    changes?
                  </label>
                </div>
              </div>

              {requestedHardwareUpdateDifferences && (
                <div>
                  <div className="mb-4">
                    <h5 className="font-size-14 mb-1">Proposed Changes:</h5>

                    {Object.entries(requestedHardwareUpdateDifferences!).map(
                      ([key, { oldValue, newValue }]) => {
                        const nameOfField = getNiceNameOfField(key)
                        const formattedOldValue = formatValueBasedOnKey(
                          data,
                          countriesData!,
                          key,
                          oldValue,
                        )
                        const formattedNewValue = formatValueBasedOnKey(
                          data,
                          countriesData!,
                          key,
                          newValue,
                        )
                        return (
                          <div className="m-3">
                            <Card>
                              <CardBody>
                                <h5 className="card-title">{nameOfField}</h5>
                                <br />
                                <p className="card-text">
                                  <strong className="bg-soft-danger">
                                    <s>{formattedOldValue}</s>
                                  </strong>
                                  <br />
                                  <strong className="bg-soft-success">
                                    {formattedNewValue}
                                  </strong>
                                </p>
                              </CardBody>
                            </Card>
                          </div>
                        )
                      },
                    )}
                  </div>
                </div>
              )}

              {(pricesToAdd.length || pricesToDelete.length) && (
                <div>
                  {Boolean(pricesToAdd.length) && (
                    <>
                      <h5 className="font-size-14 mb-1">
                        Subscriptions to add:
                      </h5>
                      <Table className="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th style={{ width: 80 }}>Count</th>
                            <th style={{ width: 120 }}>Interval</th>
                            <th style={{ width: 80 }}>x</th>
                            <th style={{ width: 80 }}>Cur.</th>
                            <th style={{ width: 120 }}>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pricesToAdd.map((p) => (
                            <tr>
                              <td>{p.intervalCount}</td>
                              <td>{p.interval}</td>
                              <td>{p.totalIntervals || '∞'}</td>
                              <td>{getSymbolFromCurrency(p.currencyCode)}</td>
                              <td>{currencyFormat(p.units)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <br />
                    </>
                  )}
                  {Boolean(pricesToDelete.length) && (
                    <>
                      <h5 className="font-size-14 mb-1">
                        Subscriptions to soft* delete:
                      </h5>
                      <Table className="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th style={{ width: 80 }}>Count</th>
                            <th style={{ width: 120 }}>Interval</th>
                            <th style={{ width: 80 }}>x</th>
                            <th style={{ width: 80 }}>Cur.</th>
                            <th style={{ width: 120 }}>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pricesToDelete.map((p) => (
                            <tr>
                              <td>{p.intervalCount}</td>
                              <td>{p.interval}</td>
                              <td>{p.totalIntervals || '∞'}</td>
                              <td>{getSymbolFromCurrency(p.currencyCode)}</td>
                              <td>{currencyFormat(p.units)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <p className="font-size-10 mt-1">
                        * Merchants already paying these subscriptions will
                        continue using them
                      </p>
                    </>
                  )}
                </div>
              )}
            </div>

            <div className="modal-footer">
              <button
                onClick={(e) => {
                  e.preventDefault()
                  onConfirmUpdateHardware()
                }}
                type="button"
                className="btn btn-primary"
              >
                Yes, Save these changes
              </button>
            </div>
          </Modal>
        ) : null}
      </div>
    </React.Fragment>
  )
}

export default HardwareTable
