import UserProfileResponse from '../../../api-dtos/profile/user-profile-response.dto.interface'
import { ProfileTypes } from './actionTypes'

// Fetch profile
export const fetchProfile = () => {
  return {
    type: ProfileTypes.FETCH_PROFILE,
    payload: {},
  }
}

export const fetchProfileSuccess = (
  userProfile: Promise<UserProfileResponse>,
) => {
  return {
    type: ProfileTypes.FETCH_PROFILE_SUCCESS,
    payload: userProfile,
  }
}

// Update profile
export const editProfile = (user: any) => {
  return {
    type: ProfileTypes.UPDATE_EDIT_PROFILE,
    payload: { user },
  }
}

export const profileSuccess = (msg: any) => {
  return {
    type: ProfileTypes.UPDATE_PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = (error: any) => {
  return {
    type: ProfileTypes.UPDATE_PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = () => {
  return {
    type: ProfileTypes.UPDATE_RESET_PROFILE_FLAG,
  }
}
