import moment from 'moment'
import React, { useMemo } from 'react'
import { InfiniteData } from 'react-query'
import { Table } from 'reactstrap'
import styled from 'styled-components'

import { SupportPayoutTransactionsDto } from '../../support-merchant-payouts.type'
import { priceFormat } from 'src/helpers/utils'

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`

const StyledTitle = styled.h6`
  margin-bottom: 1rem;
`

type IComponentProps = {
  data: InfiniteData<SupportPayoutTransactionsDto> | undefined
  isLoading: boolean
  isError: boolean
}

const PayoutPaymentsTable: React.FC<IComponentProps> = ({
  data,
  isLoading,
  isError,
}) => {
  const dataArray = useMemo(
    () => (data?.pages ?? []).flatMap((page) => page.transactions.transactions),
    [data],
  )

  if (isLoading && dataArray.length === 0) {
    return <div>Loading ...</div>
  }

  if (isError) {
    return <div>Error loading payments. Please try again.</div>
  }

  if (dataArray?.length === 0) {
    return (
      <React.Fragment>
        <StyledTitle>Payments included in payout</StyledTitle>
        <div
          className="table-responsive"
          style={{
            paddingBottom: 550,
            marginBottom: -550,
            msOverflowStyle: 'none',
          }}
        >
          <CenterContainer>No payments associated with payout.</CenterContainer>
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <StyledTitle>Payments included in payout</StyledTitle>
      <Table responsive striped className="mb-0">
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>Card</th>
            <th>Fees</th>
            <th>Tip</th>
            <th>Fee Discount</th>
            <th>Net</th>
            <th>Schedule</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {(dataArray ?? []).map((payment) => {
            return (
              <tr key={payment.id}>
                <td>
                  {moment(payment.createdAt).local().format('DD-MM-YY HH:mm')}
                </td>
                <td>{priceFormat(payment.total)}</td>
                <td>
                  {payment.card
                    ? `${payment.card?.type} ${payment.card?.country} ${payment.card?.lastFour}`
                    : payment.type}
                </td>
                <td>{priceFormat(payment.fee)}</td>
                <td>{priceFormat(payment.tip)}</td>
                <td>
                  {payment.processingFeeDiscounts?.length
                    ? priceFormat(
                        payment.processingFeeDiscounts[0].feeDiscounted,
                      )
                    : '--'}
                </td>
                <td>{priceFormat(payment.net)}</td>
                <td>{payment.schedule}</td>
                <td>{payment.description}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </React.Fragment>
  )
}

export default PayoutPaymentsTable
