import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormFeedback, FormGroup, Input } from 'reactstrap'

import {
  useQuickBooksSyncConfig,
  useUpdateQuickBooksSyncConfigMutation,
} from '../../quickbooks.hook'
import {
  UpdateQuickBooksSyncingConfig,
  UpdateQuickBooksSyncingConfigSchema,
} from '../../quickbooks.type'
import moment from 'moment'

interface IComponentProps {
  merchantId: string
  apiKey: string
  isRefreshingAccountList: boolean
}

const QuickBooksSyncConfig: React.FC<IComponentProps> = ({
  merchantId,
  apiKey,
  isRefreshingAccountList,
}) => {
  const { isLoading, data } = useQuickBooksSyncConfig({
    merchantId,
    key: apiKey,
  })

  const {
    mutate,
    isLoading: isUpdatingSyncConfig,
    isError: isUpdateSyncConfigError,
  } = useUpdateQuickBooksSyncConfigMutation({
    merchantId,
    key: apiKey,
  })

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
  } = useForm<UpdateQuickBooksSyncingConfig>({
    resolver: zodResolver(UpdateQuickBooksSyncingConfigSchema),
  })

  // Keep an eye on which sections are enabled/disabled so
  // we can show/hide any sections as required
  const enabledSections = watch(
    (data?.sections ?? []).map(
      (_, index) => `sections.${index}.enabled` as const,
    ),
  )

  const onSubmit = React.useCallback(
    (data: UpdateQuickBooksSyncingConfig) =>
      mutate({
        ...data,
        importFromDate: moment(data.importFromDate, 'YYYY-MM-DD').toISOString(),
      }),
    [mutate],
  )

  // Set the form values from the API, if
  // there are any
  React.useEffect(() => {
    if (!data) {
      return
    }

    const { importFromDate, ...values } = data
    const date = importFromDate ? moment(importFromDate) : undefined

    reset({
      ...values,
      importFromDate: date?.format('YYYY-MM-DD'),
    })
  }, [reset, data])

  React.useEffect(() => {
    if (Object.keys(errors).length > 0) {
      console.error(errors)
    }
  }, [errors])

  // Show loading indicator when we are loading data
  // from the API
  if (isLoading || isRefreshingAccountList || !data) {
    return <div>Loading...</div>
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Iterate through all the top-level configuration sections */}
      {data.sections.map((section, sectionIndex) => (
        <div className="mt-5" key={section.type}>
          <input
            type="hidden"
            value={section.type}
            {...register(`sections.${sectionIndex}.type` as const)}
          />
          <h5>{section.title}</h5>
          {section.enabled !== undefined && (
            <div className="form-check form-switch form-switch-lg" dir="ltr">
              <input
                type="checkbox"
                className="form-check-input"
                defaultChecked
                {...register(`sections.${sectionIndex}.enabled` as const)}
              />
              <label className="form-check-label" htmlFor="customSwitchsizelg">
                Enabled
              </label>
            </div>
          )}
          {section.description && (
            <div className="text-muted">{section.description}</div>
          )}

          {/* Iterate through all the subsections */}
          {(enabledSections[sectionIndex] === true ||
            enabledSections[sectionIndex] === undefined) &&
            section.subsections.map((subsection, subsectionIndex) => {
              const error = ((errors.sections ?? [])[sectionIndex]
                ?.subsections ?? [])[subsectionIndex]?.accountId

              const hasError = Boolean(error)

              return (
                <FormGroup className="mt-4" key={subsection.type}>
                  <input
                    type="hidden"
                    value={subsection.type}
                    {...register(
                      `sections.${sectionIndex}.subsections.${subsectionIndex}.type` as const,
                    )}
                  />
                  <h6>{subsection.title}</h6>
                  {subsection.description && (
                    <div className="text-muted">{subsection.description}</div>
                  )}
                  <select
                    className={`form-select mt-2 ${
                      hasError ? 'is-invalid' : ''
                    }`}
                    {...register(
                      `sections.${sectionIndex}.subsections.${subsectionIndex}.accountId` as const,
                      {
                        setValueAs: (val) => (val === '' ? null : val),
                      },
                    )}
                  >
                    <React.Fragment>
                      <option value="">Select account</option>
                      {subsection.availableAccounts?.map((account) => (
                        <option key={account.id} value={account.id}>
                          {account.name}
                        </option>
                      ))}
                    </React.Fragment>
                  </select>
                  {hasError && <FormFeedback>{error?.message}</FormFeedback>}
                </FormGroup>
              )
            })}
        </div>
      ))}
      <div className="mt-5">
        <h5>Import schedule</h5>
        <FormGroup className="mt-4">
          <h6>Start importing from</h6>
          <div className="text-muted">
            Select from when you want to start importing your data into
            QuickBooks Online. You can only set this once. Note that if you have
            previously booked your Lopay sales in QuickBooks Online, they could
            get booked twice.
          </div>
          <Controller
            name="importFromDate"
            control={control}
            render={({ field }) => (
              <Input
                className="form-control mt-2"
                type="date"
                disabled={Boolean(data.importFromDate)}
                invalid={Boolean(errors.importFromDate)}
                {...field}
              />
            )}
          />
          {errors.importFromDate && (
            <FormFeedback>{errors?.importFromDate?.message}</FormFeedback>
          )}
        </FormGroup>
      </div>

      <button
        type="submit"
        className="btn btn-primary w-100 mt-5"
        disabled={isUpdatingSyncConfig}
      >
        Save
      </button>

      {isUpdateSyncConfigError && (
        <div className="mt-3">
          An error has occurred whilst updating your configuration. Please try
          again.
        </div>
      )}
    </form>
  )
}

export default QuickBooksSyncConfig
